import React, { createContext, useState, useEffect, useContext } from 'react';
import { getTokens } from './loadData';

const TokensContext = createContext();

export const TokensProvider = ({ children }) => {
  const [tokens, setTokens] = useState([]);
  const [tokensV1, setTokensV1] = useState([]); // Separate list for v1 tokens
  const [tokensV2, setTokensV2] = useState([]); // Separate list for v2 tokens
  const [tokenAddressMap, setTokenAddressMap] = useState({});
  const [tokenAddressMapV1, setTokenAddressMapV1] = useState({});
  const [tokenAddressMapV2, setTokenAddressMapV2] = useState({});
  const [slugMap, setSlugMap] = useState({});
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const dataList = await getTokens();
        setTokens(dataList);

        const tokenAddrMap = {};
        const tokenAddrMapV1 = {};
        const tokenAddrMapV2 = {};
        const slgMap = {};
        const v1List = [];
        const v2List = [];

        dataList.forEach(item => {
          // Populate token address map
          tokenAddrMap[item.token_address] = item;

          // Populate slug map with renamed logo key
          const { logo, ...rest } = item;
          const newItem = { ...rest, img: logo };

          slgMap[item.slug] = newItem;

          // Split tokens into v1 and v2 based on version
          if (item.version === '1') {
            tokenAddrMapV1[item.token_address] = item;
            v1List.push(item);
          } else if (item.version === '2') {
            v2List.push(item);
            tokenAddrMapV2[item.token_address] = item;
          }
        });

        setTokenAddressMap(tokenAddrMap);
        setTokenAddressMapV1(tokenAddrMapV1);
        setTokenAddressMapV2(tokenAddrMapV2);

        setSlugMap(slgMap);
        setTokensV1(v1List);
        setTokensV2(v2List);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  return (
    <TokensContext.Provider value={{ tokens, tokensV1, tokensV2, tokenAddressMap, tokenAddressMapV1, tokenAddressMapV2, slugMap, loading, error }}>
      {children}
    </TokensContext.Provider>
  );
};

export const useTokens = () => {
  return useContext(TokensContext);
};