import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {enUS as en} from 'date-fns/locale';
import {titleLabel, titleTooltip, scalePrice} from './utils.js'

const removeEmptyStringsPlugin = {
  id: 'removeEmptyStringsPlugin',
  beforeDatasetDraw: (chart) => {
    // console.log(`ЗДГПШТ ШТ ЦЩКЛ`);
    // Перебираем все данные в графике и заменяем пустые строки на null
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      // Проверяем каждое значение данных
      dataset.data.forEach((value, dataIndex) => {
        if (value.y === "") {
          // Логируем замену пустых строк на null
          // console.log(`Dataset ${datasetIndex}, DataIndex ${dataIndex}: Empty string replaced with null`);
          // console.log(value, value.y, dataIndex);
          dataset.data[dataIndex].y = undefined; // Заменяем пустую строку на null
        }
      });
    });
  }
};

  
ChartJS.register(
    // removeEmptyStringsPlugin,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

const getNull = (ctx) => {
  if ((ctx.p0.raw.y === '') || (ctx.p1.raw.y === '')) {
    return [6, 6];
  } else {
    return []; 
  }
}


const getChangeColor = (ctx) => {
  if (ctx.parsed && typeof ctx.parsed.y !== 'undefined') {
    let yValue = ctx.parsed.y;

    if (yValue > 0) {
      return 'rgba(47, 235, 184, 1)'; // Яркий холодный зеленый с opacity 0.8
    }
    else {
      return 'rgba(255, 128, 128, 1)'; // Мягкий красный с opacity 0.8
    }
  }
  return 'rgba(47, 235, 184, 0.9)'; // Яркий холодный зеленый с opacity 0.8
};

const setInitialMinScaleDataX = (ctx) => {
  const xValues = ctx.chart.data.datasets[2].data.map(point => point.x);
  if (xValues.length >= 365) {
    // Берем 365-й элемент с конца
    const dataMin = xValues[365];
    return dataMin;
  } else {
    // Если длина меньше 365, возвращаем дефолтное значение
    return xValues[xValues.length - 1];
  }
};

const setInitialMaxScaleDataY2 = (ctx) => {
  const yValues = ctx.chart.data.datasets[2].data.map(point => point.y);
  if (yValues.length >= 365) {
    const last12YValues = yValues.slice(0, 365); // Берем последние 12 значений
    const dataMax = Math.max(...last12YValues);
    return dataMax * 2;
  } else {
    const dataMax = Math.max(...yValues);
    return dataMax * 2;
  }
  
};

const setInitialMinScaleDataY2 = (ctx) => {
  const yValues = ctx.chart.data.datasets[2].data.map(point => point.y);
  if (yValues.length >= 365) {
    const last12YValues = yValues.slice(0, 365); // Берем последние 12 значений
    const dataMin = Math.min(...last12YValues);
    return dataMin * 0.5;
  } else {
    const dataMin = Math.min(...yValues);
    return dataMin * 0.5;
  }
  
};


export const data = {
    labels: [],
    datasets: [
        {
          label: 'Price',
          type: 'line',
          data: [],
          fill: false,
          borderColor: 'rgb(255, 255, 255)',
          tension: 0,

          pointRadius: 0.0,
          pointHoverRadius: 5, // Радиус точек при наведении

          borderWidth: 1,
          backgroundColor: 'rgb(255, 255, 255)',
          yAxisID: 'y1',
          order: 1,
        },
        {
          label: 'Number of Addresses',
          type: 'line',
          data: [],
          fill: false,
          borderColor:  'rgba(255, 255, 180, 0.9)',
          backgroundColor: 'rgba(255, 255, 180, 0.9)',

          pointStyle: 'circle', // Тип точки (круг)
          pointRadius: 0, // Радиус точек
          pointHoverRadius: 5, // Радиус точек при наведении
          borderWidth: 2, // Ши

          tension: 0,
          yAxisID: 'y',
          order: 2,
          spanGaps: true,
        },
        {
          label: 'Change (untill this date for selected period)',
          type: 'bar',
          // barPercentage: 0.9,
          barThickness: 'flex',
          data: [],
          fill: false,
          borderColor: (ctx) => getChangeColor(ctx),
          backgroundColor: (ctx) => getChangeColor(ctx),
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          yAxisID: 'y2',
          order: 2,
          spanGaps: true,
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'xy',
    },
    plugins: {
        // custom: removeEmptyStringsPlugin, // Добавляем плагин в конфигурацию
        zoom: {
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.04,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
              overScaleMode: 'y',
            },
            pan: {
              enabled: true,
              overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              // y: {min: 0, max:5},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
            labels: {
              font: {
                family: 'Arial', // Шрифт
                size: 14,        // Размер шрифта
                // weight: 'simibold',  // Жирный шрифт
              },
              color: 'rgba(255, 255, 255, 1)', // Цвет текста
              boxWidth: 20, // Размер значков в легенде
              padding: 15, 
              generateLabels: (chart) => {
                // Создаем пользовательские метки для легенды
                return [
                {
                  text: 'Holders Growth 🚀',  // Описание для зеленого цвета
                  fillStyle: 'rgba(47, 235, 184, 0.8)', // Зеленый цвет
                  strokeStyle: 'rgba(47, 235, 184, 0.8)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'Holders Decline 😒',  // Описание для красного цвета
                  fillStyle: 'rgba(255, 68, 102, 0.8)', // Красный цвет
                  strokeStyle: 'rgba(255, 68, 102, 0.8)', // Красный цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'Price',  // Описание для зеленого цвета
                  fillStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  strokeStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'Number of Holders',  // Описание для желтого цвета
                  fillStyle: 'rgba(255, 255, 180, 0.8)', // Желтый цвет
                  strokeStyle: 'rgba(255, 255, 180, 0.8)', // Желтый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
              ];
              },
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: titleLabel,
              title:
               (context) => {
                var date = context[0].raw.x.split(" ")[0]
                return date
            },
          },
        },
    },
    scales: {
        x: {
          // categoryPercentage: 0.8,
          //   barPercentage: 0.9,
            // categoryPercentage: 1,
            min: setInitialMinScaleDataX,
            type: 'time',
            time: {
              displayFormats: {
                'millisecond': 'yyyy-MM-dd HH:mm',
                'second': 'yyyy-MM-dd HH:mm',
                'minute': 'yyyy-MM-dd HH:mm',
                'hour': 'yyyy-MM-dd HH:mm',
                'day': 'yyyy-MM-dd',
                'week': 'yyyy-MM-dd',
                'month': 'yyyy-MM',
                'quarter': 'yyyy-MM',
                'year': 'yyyy',
             }
            },
            adapters: {
              date: {
                  locale: en
              }
            },
            grid: {
                display: false
            },
            ticks: {
                font: {
                  size: 12, 
                  family: 'SFProLight',
                },
                color: '#9AA2AA',
                maxTicksLimit: 7,
                minTicksLimit: 3,
                maxRotation: 0,
                minRotation: 0,
            }
        },        
        y: {
          // min: 0,
          // max: 15,
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
          stacked: true,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
      y2:{
        max: setInitialMaxScaleDataY2,
        min: setInitialMinScaleDataY2,
        position: 'left',
        grid: {
            display: false,
        },
        ticks: {
          beginAtZero: true,
        },
        type: 'linear',
        stacked: true,
        color: '#9AA2AA',
        font: {
          size: 12,
          family: 'SFProLight',
        },
      },
    },
    maintainAspectRatio: false,
  };