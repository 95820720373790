import { DataGrid } from '@mui/x-data-grid';
import { NewTokenTableField, ShowAbsChange, ShowPercentChange } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';


const columnNames = [
    'Token', 
    'Price', 
    '1d %', 
    '7d %', 
    'Realised Cap', 
    '7d %', 
    '30d %',
    'Market Cap',
    '7d %',
    '30d %',
    'Number of addresses',
    '30d %'
]

export const columns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 200,
        cellClassName: 'datagrid-token',
        renderCell: (props) => { return (<NewTokenTableField value={props.value}/>) },
        sortable: false,
    },
    // {
    //     field: '',
    //     headerName: 'Chain',
    //     width: 40,
    //     // description: 'MVRV=(Market Cap)/(Realised Cap)',
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<ETHLogoTableField/>) },
    // },
    // {
    //     field: 'address',
    //     headerName: 'Contract Address',
    //     width: 140,
    //     // headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<EtherscanAddress value={props.value}/>) },
    // },
    {
        field: 'price_0d',
        headerName: 'Price',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'price_percent_7d',
        headerName: '7d %',
        width: 70,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'price_percent_30d',
        headerName: '30d %',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    
    {
        field: 'num_addresses_1d',
        headerName: '# Holders',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{addCommas(props.value)}</p>)},
    },
    {
        field: 'num_addresses_change_7d',
        headerName: '7d Change',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (< ShowAbsChange value={props.value} />)},
    },
    {
        field: 'num_addresses_percent_7d',
        headerName: '7d %',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'num_addresses_change_30d',
        headerName: '30d Change',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (< ShowAbsChange value={props.value} />)},
    },
    {
        field: 'num_addresses_percent_30d',
        headerName: '30d %',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    
]

export const initialState = {
    sorting: {
      sortModel: [{ field: 'mvrv_1d', sort: 'desc' }],
    },
}