import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {titleLabel, titleTooltip, scaleX, scalePrice} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;


const getColor = (ctx) => {
  // const yValue = ctx.p1.parsed.y; // Получаем значение на оси Y для текущего сегмента
  const direction = ctx.p1.parsed.y > ctx.p0.parsed.y ? ctx.p1 : ctx.p0; // Определяем направление: вверх или вниз
  const yValue = direction.parsed.y; // Берем значение из выбранной точки

  // Проверяем диапазон p1.parsed.y и возвращаем нужный цвет с opacity
  if (yValue > 3) {
    return 'rgba(255, 68, 102, 0.9)'; // Холодный красный (розовый) с opacity 0.8
  } else if (yValue >= 1 && yValue <= 3) {
    return 'rgba(255, 128, 128, 0.9)'; // Мягкий красный с opacity 0.8
  // } else if (yValue >= 1 && yValue <= 1) {
  //   return 'rgba(255, 184, 184, 0.9)'; // Светлый розовый с opacity 0.8
  } else if (yValue >= 0.5 && yValue <= 1) {
    return 'rgba(255, 215, 215, 0.9)'; // Пастельный розовый с opacity 0.8
  } else if (yValue >= -0.5 && yValue <= 0.5) {
    return 'rgba(255, 255, 180, 0.9)'; // Светло-желтый с opacity 0.8
  } else if (yValue >= -1 && yValue <= -0.5) {
    return 'rgba(158, 255, 192, 0.9)'; // Легкий холодный зеленый с opacity 0.8
  } else if (yValue >= -2 && yValue <= -1) {
    return 'rgba(77, 255, 179, 0.9)'; // Холодный зеленый с opacity 0.8
  } else if (yValue < -2) {
    return 'rgba(47, 235, 184, 0.9)'; // Яркий холодный зеленый с opacity 0.8
  }
};





export const data = {
    labels: [],
    datasets: [
        {
          label: 'Price',
          type: 'line',
          data: [],
          fill: false,
          borderColor: 'rgb(255, 255, 255)',
          tension: 0.1,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: 'rgb(255, 255, 255)',
          yAxisID: 'y1',
          order: 1,
        },
        {
          label: 'MVRV Z-Score',
          type: 'line',
          data: [],
          fill: false,
          borderColor:  'rgba(255, 255, 180, 0.9)',
          backgroundColor: 'rgba(255, 255, 180, 0.9)',
          segment: {
            // Применяем цвет для каждой части линии
            borderColor: (ctx) => getColor(ctx),
          },
          // segment: {
          //   borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
          //   backgroundColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
          //   // borderDash: ctx => skipped(ctx, [6, 6]),
          // },
          tension: 0.1,
          pointRadius: 0.0,
          borderWidth: 1,
          yAxisID: 'y',
          order: 2,
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.04,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
              overScaleMode: 'y',
            },
            pan: {
              enabled: true,
              overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              // y: {min: 0, max:5},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
            labels: {
              font: {
                family: 'Arial', // Шрифт
                size: 14,        // Размер шрифта
                // weight: 'simibold',  // Жирный шрифт
              },
              color: 'rgba(255, 255, 255, 1)', // Цвет текста
              boxWidth: 20, // Размер значков в легенде
              padding: 15, 
              generateLabels: (chart) => {
                // Создаем пользовательские метки для легенды
                return [
                {
                  text: 'MVRV Z-Score >> 0, Overheated Market 🥵',  // Описание для красного цвета
                  fillStyle: 'rgba(255, 68, 102, 0.8)', // Красный цвет
                  strokeStyle: 'rgba(255, 68, 102, 0.8)', // Красный цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                }, 
                {
                  text: 'MVRV Z-Score ~ 0, Balanced Market',  // Описание для желтого цвета
                  fillStyle: 'rgba(255, 255, 180, 0.8)', // Желтый цвет
                  strokeStyle: 'rgba(255, 255, 180, 0.8)', // Желтый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'MVRV Z-Score << 0, Undervalued Market 😴',  // Описание для зеленого цвета
                  fillStyle: 'rgba(47, 235, 184, 0.8)', // Зеленый цвет
                  strokeStyle: 'rgba(47, 235, 184, 0.8)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'Price',  // Описание для зеленого цвета
                  fillStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  strokeStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },];
              },
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: titleLabel,
              title: titleTooltip,
            },
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 0,
              yMax: 0,
              borderColor: 'rgba(255, 255, 180, 0.2)',
              borderWidth: 1,
              order: 1,
            }
          }
        }
    },
    scales: {
        x: scaleX,
        y: {
          min: -5,
          max: 15,
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
          stacked: true,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
    maintainAspectRatio: false,
  };