import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale
  } from 'chart.js';
//import watermarkPlugin from 'chartjs-plugin-watermark';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {parseBigValues, titleLabel, titleTooltip, scaleX, scalePrice, parseValueForTooltip, parseIntValueForTooltip} from './utils.js'

// import imagesrc from  "../../assets/watermark.png"
// const image = new Image();
// //image.src = 'https://www.chartjs.org/img/chartjs-logo.svg';
// image.src = imagesrc  
//   //   image.width = "10px"
//   //   image.height = "10px" 

// const plugin = {
//   id: 'custom_canvas_background_image',
//   beforeDraw: (chart) => {
//     const excludedChartIds = ['netWorthChart']; // Add chart IDs you want to exclude here
//     if (excludedChartIds.includes(chart.canvas.id)) {
//       return;
//     }

//     if (!chart.canvas.id.startsWith('sparkline') && image.complete) {
//       image.width = 531
//       image.height = 76
//       // image.width = 451
//       // image.height = 176
//       console.log("img", image.width, image.height)
//       const ctx = chart.ctx;
//       const {top, left, width, height} = chart.chartArea;
//       const x = left + width / 2 - image.width / 2;
//       const y = top + height / 2 - image.height / 2;
//       ctx.globalAlpha = 0.25;
//       // ctx.drawImage(image, x, y, 451, 176);
//       ctx.drawImage(image, x, y, 531, 76);
//       ctx.globalAlpha = 1.0;
//     } else {
//       image.onload = () => chart.draw();
//     }
//   }
// };

import imagesrc from "../../assets/watermark.png";

const image = new Image();
image.src = imagesrc;

// const plugin = {
//   id: 'custom_canvas_background_image',
//   beforeDraw: (chart) => {
//     const excludedChartIds = ['netWorthChart']; // Add chart IDs you want to exclude here
//     if (excludedChartIds.includes(chart.canvas.id)) {
//       return;
//     }

//     if (!chart.canvas.id.startsWith('sparkline') && image.complete) {
//       // Сохраняем пропорции картинки
//       const aspectRatio = image.width / image.height;
//       const newWidth = 400; // Новая ширина изображения (меньше, чем раньше)
//       const newHeight = newWidth / aspectRatio; // Рассчитываем новую высоту с сохранением пропорций

//       const ctx = chart.ctx;
//       const { top, left, width, height } = chart.chartArea;

//       const x = left + width / 2 - newWidth / 2;
//       const y = top + height / 2 - newHeight / 2;

//       ctx.globalAlpha = 0.25;
//       ctx.drawImage(image, x, y, newWidth, newHeight); // Рисуем картинку с новым размером
//       ctx.globalAlpha = 1.0;
//     } else {
//       image.onload = () => chart.draw();
//     }
//   }
// };

const plugin = {
  id: 'custom_canvas_background_image',
  beforeDraw: (chart) => {
    const excludedChartIds = ['netWorthChart']; // Add chart IDs you want to exclude here
    if (excludedChartIds.includes(chart.canvas.id)) {
      return;
    }

    if (!chart.canvas.id.startsWith('sparkline') && image.complete) {
      const aspectRatio = image.width / image.height;

      const ctx = chart.ctx;
      const { top, left, width, height } = chart.chartArea;

      // Определяем, большой ли экран или маленький
      const isSmallScreen = window.innerWidth <= 768; // Маленькие экраны (мобильные устройства)

      // Размер изображения пропорционален размеру графика
      const newWidth = isSmallScreen ? width * 0.5 : width * 0.3; // 50% для маленьких экранов, 30% для больших
      const newHeight = newWidth / aspectRatio; // Рассчитываем высоту с сохранением пропорций
     
      const x = left + width / 2 - newWidth / 2;
      const y = top + height / 2 - newHeight / 2;

      ctx.globalAlpha = 0.25;
      ctx.drawImage(image, x, y, newWidth, newHeight); // Рисуем картинку с пропорциональным размером
      ctx.globalAlpha = 1.0;
    } else {
      image.onload = () => chart.draw();
    }
  }
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  LineController,
  BarController,
  Filler,
  TimeScale,
  plugin,
  // watermarkPlugin,
);

// function labelTooltipMPLCohorts(context) {
//   let label = context.dataset.label || '';
//   if (label) {
//     let value = parseValueForTooltip(context.parsed.y)
    
//     if (value[0] == "-") {
//       label += ': -$' + value.slice(1,)
//     }
//     else {
//       label += ': $' + value
//     }
//   }
//   return label;
// }

function labelTooltipMPLCohorts(context) {
  let label = context.dataset.label || '';
  if (label) {
    let value = parseValueForTooltip(context.parsed.y)
    label += ': ' + value
  }
  return label;
}

function labelTooltipWithDollar(context) {
  let label = context.dataset.label || '';
  if (label) {
    let value = parseValueForTooltip(context.parsed.y)
    if (value[0] == "-") {
      label += ': -$' + value.slice(1)
    }
    else {
      label += ': $' + value
    }
  }
  return label;
}

const color_cex = 'rgb(85,61,91)'  // best
const color_sc = 'rgb(59,41,64)'

export const chartName2Settings = {
  "Realised Price - Cohorts Distribution": {
    "options": getOptions(
      true, "right", 'linear', '#.', false, {x: {minRange: 5},}, labelTooltipWithDollar, false
    ),
  },
  "Realised Price - Age Distribution": {
    "data": getDataset(
      "line",
      ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y"],
      [
        'rgb(197,39,116)',
        'rgba(228,56,142,255)',
        'rgba(202,96,164,255)',
        'rgba(180,126,179,255)',
        'rgba(156,152,194,255)',
        'rgba(128,178,209,255)',
        'rgba(107,194,220,255)',
        'rgba(90,215,232,255)',
        'rgba(115,230,240,255)',
        'rgba(140,245,250,255)',
      ],
      false,
      'y1',
    ),
  },
  "Realised Price - Balance Distribution": {
    "data": getDataset(
      "line",
      ["balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
      "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",],
      ['rgba(236,27,122,255)', 
      'rgba(238,96,144,255)', 
      'rgba(243,137,166,255)', 
      'rgba(233,174,194,255)',
      'rgba(253,211,222,255)', 
      'rgba(223,240,216,255)', 
      'rgb(195,227,181)', 
      'rgb(163,213,148)', 
      'rgba(144,200,121,255)', 
      'rgba(112,192,86,255)'],
      false,
      'y1',
    ),
  },
  "Realised Price - Balance Dollar Distribution": {
    "data": getDataset(
      "line",
      ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
      ['rgba(236,27,122,255)', 
      'rgba(238,96,144,255)', 
      'rgba(243,137,166,255)', 
      // 'rgba(233,174,194,255)',
      'rgba(253,211,222,255)', 
      'rgba(223,240,216,255)', 
      'rgb(195,227,181)', 
      'rgb(163,213,148)', 
      'rgba(144,200,121,255)', 
      'rgba(112,192,86,255)'],
      false,
      'y1',
    ),
  },
  "Number of Addresses": {
    "options": getOptions(
      true, "left", 'linear', '#.', false, {x: {minRange: 5},}, 
      labelTooltipMPLCohorts, 
      false
    ),
    "data": getDataset(
      "line",
      ["Number of Addresses"],
      ['rgb(243, 39, 136)', ],
      false
    )
  },

  "Realised Profit / Loss Ratio": {
    "data": getDataset(
      "line",
      ["P/L Ratio"],
      ['rgb(40,226,238)'],
      false
    ),
    "options": getOptions(false, "left", 'linear', '#.', true, {x: {minRange: 5}, }, titleLabel, false),
  },

  "TVL": {
    "options": getOptions(
      true, "left", 'linear', '#.', false, {x: {minRange: 5},}, 
      labelTooltipMPLCohorts, 
      false
    ),
    "data": getDataset(
      "line",
      ["TVL"],
      ['rgb(243, 39, 136)', ],
      false
    )
  },
  "MPL": {
    "options": getOptions(true, "left", 'linear', '#.', false, {x: {minRange: 5},}, labelTooltipWithDollar, false),
    "data": getDataset(
      "line",
      ["Losses", "Profits"],
      ['rgb(243, 39, 136)', 'rgb(40,226,238)'],
      false
    )
  },
  "MPLVolume": {
    "options": getOptions(true, "left", 'linear', '#.', false, {x: {minRange: 5},}, labelTooltipMPLCohorts, false),
    "data": getDataset(
      "line",
      ["Losses", "Profits"],
      ['rgb(243, 39, 136)', 'rgb(40,226,238)'],
      false
    )
  },
  "NMPL": {
    "options": getOptions(false, "left", 'linear', '#.', false, {x: {minRange: 5},}, labelTooltipWithDollar, false,),
    "data": getDataset(
      "line",
      ['NMPL'],
      ['rgb(40,226,238)'],
      {
        target: {value: 0},
        above: 'rgb(40,226,238)',   // Area will be red above the origin
        below: 'rgb(243, 39, 136)'    // And blue below the origin
      },
    )
  },
  "MPLBalanceDollarCohorts": {
    "data": getDataset(
      "line",
      ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
      ['rgba(236,27,122,255)', 
      'rgba(238,96,144,255)', 
      'rgba(243,137,166,255)', 
      // 'rgba(233,174,194,255)',
      'rgba(253,211,222,255)', 
      'rgba(223,240,216,255)', 
      'rgb(195,227,181)', 
      'rgb(163,213,148)', 
      'rgba(144,200,121,255)', 
      'rgba(112,192,86,255)'],
      { target: {value: 0}},
    ),
    "options": getOptions(true, "left", 'linear', '#.', false, {x: {minRange: 5} }, labelTooltipMPLCohorts, true),
  },
  "MPLBalanceCohorts": {
    "data": getDataset(
      "line",
      ["balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
      "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",],
      ['rgba(236,27,122,255)', 
      'rgba(238,96,144,255)', 
      'rgba(243,137,166,255)', 
      'rgba(233,174,194,255)',
      'rgba(253,211,222,255)', 
      'rgba(223,240,216,255)', 
      'rgb(195,227,181)', 
      'rgb(163,213,148)', 
      'rgba(144,200,121,255)', 
      'rgba(112,192,86,255)'],
      { target: {value: 0}},
    ),
    "options": getOptions(true, "left", 'linear', '#.', false, {x: {minRange: 5} }, labelTooltipMPLCohorts, true),
  },
  "MPLAgeCohorts": {
    "data": getDataset(
      "line",
      ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y"],
      [
        'rgb(197,39,116)',
        'rgba(228,56,142,255)',
        'rgba(202,96,164,255)',
        'rgba(180,126,179,255)',
        'rgba(156,152,194,255)',
        'rgba(128,178,209,255)',
        'rgba(107,194,220,255)',
        'rgba(90,215,232,255)',
        'rgba(115,230,240,255)',
        'rgba(140,245,250,255)',
      ],
      { target: {value: 0}},
    ),
    "options": getOptions(true, "left", 'linear', '#.', false, {x: {minRange: 5} }, labelTooltipMPLCohorts, true),
  },
  "SOPRPL": {
    "data": getDataset(
      "line",
      ["SOPR in Losses", "SOPR in Profits"],
      ['rgb(243, 39, 136)', 'rgb(40,226,238)'],
      false
    ),
    "options": getOptions(false, "left", 'linear', '#.', true, {x: {minRange: 5},}, titleLabel, false, true),
  },
  "SOPR": {
    "data": getDataset(
      "line",
      ["SOPR"],
      ['rgb(40,226,238)'],
      false,
      // true
    ),
    "options": getOptions(false, "left", 'linear', '#.', true, {x: {minRange: 5}, }, titleLabel, false, true),
  },
  "Realised Capitalization": {
    "data": getDataset(
      "line",
      ["Market cap", "Realised cap" ],
      ['rgb(40,226,238)', 'rgb(243, 39, 136)'],
      false
    ),
    "options": getOptions(true, "left", 'linear', '$', false, {x: {minRange: 5}, }, labelTooltipWithDollar, false),
  },
  "Total Transaction Volume": {
    "data": getDataset(
        "bar",
        ["Total volume"],
        ['rgb(40,226,238)'],
        false
    ),
    "options": getOptions(
        false, "left", 'linear', '#.', false, 
        {
          x: {minRange: 5},
          y: {min: 0, },
          y1: {enabled: false}
        }, 
        titleLabel,
        false
    ),
  },
  "Average Transaction Volume": {
      "data": getDataset(
          "bar",
          ["Average volume"],
          ['rgb(40,226,238)'],
          false
      ),
      "options": getOptions(
          false, "left", 'linear', '#.', false, 
          {
            x: {minRange: 5},
            y: {min: 0, },
            y1: {enabled: false}
          },
          titleLabel,
          false
      ),
  },
  "Number of Transactions": {
    "data": getDataset(
      "bar",
      ["Number of transactions"],
      ['rgb(40,226,238)'],
      false
    ),
    "options": getOptions(
      false, "left", 'linear', '#', false, 
      {
        x: {minRange: 5},
        y: {min: 0, },
        y1: {enabled: false}
      },
      titleLabel,
      false
    ),
  },
  "Number of Active Addresses": {
    "data": getDataset(
      "bar",
      ["Number of addresses"],
      ['rgb(40,226,238)'],
      false
    ),
    "options": getOptions(
      false, "left", 'linear', '#', false, 
      {
        x: {minRange: 5},
        y: {min: 0, },
        y1: {enabled: false}
      },
      titleLabel,
      false
    ),
  },
  "UPL": {
    "options": getOptions(true, "left", 'linear', '$', false, {x: {minRange: 5},}, titleLabel, false),
    "data": getDataset(
      "line",
      ["Losses", "Profits"],
      ['rgb(243, 39, 136)', 'rgb(40,226,238)'],
      false
    )
  },
  "Supply P&L": {
    "options": getOptions(true, "left", 'linear', '#', false, {x: {minRange: 5},}, titleLabel, false),
    "data": getDataset(
      "line",
      ["Supply in loss", "Supply in profit"],
      ['rgb(243, 39, 136)', 'rgb(40,226,238)'],
      false
    )
  },
  "Number of addresses P&L": {
    "options": getOptions(true, "left", 'linear', '#', false, {x: {minRange: 5},}, titleLabel, false),
    "data": getDataset(
      "line",
      ["Number of addresses in loss", "Number of addresses in profit"],
      ['rgb(243, 39, 136)', 'rgb(40,226,238)'],
      false
    )
  },
  "BalanceCohortWaves": {
    "data": getDataset(
      "line",
      ["balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
      "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
      "Exchanges", 'Smart Contracts'],
      ['rgba(236,27,122,255)', 
      'rgba(238,96,144,255)', 
      'rgba(243,137,166,255)', 
      'rgba(233,174,194,255)',
      'rgba(253,211,222,255)', 
      'rgba(223,240,216,255)', 
      'rgb(195,227,181)', 
      'rgb(163,213,148)', 
      // 'rgba(144,200,121,255)', 
      // 'rgba(112,192,86,255)',
      color_cex, color_sc],
      { target: {value: 0}},
    ),
    "options": getOptions(true, "left", 'linear', '#', false, 
                          {x: {minRange: 5}, y: {min: 0, max: 100}, y1: {enabled: false}}, 
                          labelTooltipMPLCohorts, true),
  },
  "BalanceDollarCohortWaves": {
    "data": getDataset(
      "line",
      ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+",
      "Exchanges", 'Smart Contracts'],
      ['rgba(236,27,122,255)', 
      'rgba(238,96,144,255)', 
      'rgba(243,137,166,255)', 
      // 'rgba(233,174,194,255)',
      'rgba(253,211,222,255)', 
      'rgba(223,240,216,255)', 
      'rgb(195,227,181)', 
      'rgb(163,213,148)', 
      // 'rgba(144,200,121,255)', 
      // 'rgba(112,192,86,255)',
      color_cex, color_sc],
      { target: {value: 0}},
    ),
    "options": getOptions(true, "left", 'linear', '#', false, 
                          {x: {minRange: 5}, y: {min: 0, max: 100}, y1: {enabled: false}}, 
                          labelTooltipMPLCohorts, true),
  },
}

// chartName2Settings["Realised Capitalization"]["data"][0]["hidden"] = true



function getDataset(type, labels, colors, fill, y="y") {
  var datasets = [
    {
      label: 'Price',
      data: [],
      fill: false,
      type: 'line',
      borderColor: 'rgb(255, 255, 255)',
      tension: 0,
      pointRadius: 0.0,
      borderWidth: 1,
      backgroundColor: 'rgb(255, 255, 255)',
      yAxisID: 'y1',
    }
  ]
  for (var i = 0; i < labels.length; i++) { 

    var item = {
      label: labels[i],
      data: [],
      // spanGaps: fillGaps,
      type: type,
      fill: fill,
      tension: 0,
      pointRadius: 0.0,
      borderWidth: 1,
      yAxisID: y,
      backgroundColor: colors[i]
    }
    if (typeof fill == "boolean") {
      item["borderColor"] = colors[i]
    } 
    // if ((labels[i] == '$10M+') || (labels[i] == '$1M-10M')) {
    //   item["hidden"] = true
    // }
    datasets.push(item);
  }
  const data = {
    labels: [],
    datasets: datasets
  }

  return data
}

     
function getZoomSettings(limits) {
  return {
    zoom: {
        wheel: {
            enabled: true,
            speed: 0.1
        },
        pinch: {
            enabled: true
        },
        mode: 'xy',
        overScaleMode: 'y',
    },
    pan: {
        enabled: true,
        overScaleMode: 'y',
    },
    limits: limits,
  }
}

function getOptions(legend, y, y_scale, y_eq, horizontal_line, limits, labelTooltip, stacked, sopr=false) {
  var scales = {
    x: scaleX,
    y1: scalePrice,
  }
  if (y == 'left') {
    scales["y"] = {
      position: 'left',
      grid: {
          display: false,
      },
      type: y_scale,
      stacked: stacked,
      ticks: {
        callback: function(val) {
          if (y_eq == "#") {
            return parseIntValueForTooltip(val) 
          }
          else {
            let value = parseBigValues(val) 
            if (y_eq == "$") {
              if (value[0] == "-") {
                  return '-$' + value.slice(1,)
              }
              else {
                  return '$' + value
              } 
            }
            else if (y_eq == "#.") {
              return value
            }
          }
        },
        font: {
          size: 12,
          family: 'SFProLight',
        },
        color: '#9AA2AA',
      },
      // type: 'logarithmic',
    }
  }
  if ("y" in limits) {
    if ("max" in limits["y"]) {
      if (limits["y"]["max"] == 100) {
        scales["y"]["max"] = 100
      }
    }
  }

  var options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
      zoom: getZoomSettings(limits),
      legend: {
          display: legend,
          position: 'top',
      },
      title: {
          display: false,
      },
      tooltip: {
          // mode: 'index',
          callbacks: {
            label: labelTooltip,
            title: titleTooltip,
          },
      },
    },
    scales: scales,
    plugin,
  };

  if (horizontal_line == true) {
    options["plugins"]["annotation"] = {
      annotations: {
        line1: {
          type: 'line',
          yMin: 1,
          yMax: 1,
          borderColor: 'rgb(255, 255, 255)',
          borderWidth: 1,
          order: 1,
          yScaleID: 'y'
        }
      }
    } 
  }
  if (sopr == true) {
    scales["y"]["max"] = 2
    scales["y"]["min"] = 0.5
  }
  return options 

}