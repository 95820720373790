import { React, useState, useEffect, useRef} from "react";
import { MenuLeaderBoard } from "./Menu";

import { Table } from "./Table";
import { getRealisedCap } from "./loadData";

import { Auth } from "@supabase/auth-ui-react";
import { ButtonLogin, ButtonSignUp } from "./Navigation";

import hideButton from  "../assets/hideButton.svg"
import { initialState } from "./TableSettings/MacroTable";

import { shortNames, shortNames2Index, tableIndexes, indexNames } from "./dataTokenTracker";

import { useNavigate, createSearchParams, useSearchParams, generatePath, useMatch, useLocation } from "react-router-dom";
import DescriptionBlock from "./Description";

export function TokenTracker(props) {
    // const [selectedToken, setToken] = useState("ldo")
    const { user } = Auth.useUser();

    const [selectedIndex, setIndex] = useState(0)

    const [dataGridTable, setDataGridTable] = useState([])
    const [loadingData, setLoading] = useState(false)

    const [toggleDropDown, setDropDown] = useState(false)

    const tableRef = useRef()

    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const urlParams = location.pathname.split('/')
        const index = urlParams[2]

        const newIndex = index===undefined ? selectedIndex: (shortNames2Index[index]===undefined ? selectedIndex: shortNames2Index[index])
        setIndex(newIndex)

        setNewRoute(newIndex)

        updateTable(newIndex)
    }, []);

    function setNewRoute(indexSel) {
        console.log("names", shortNames)
        console.log("index", indexSel)
        let searchParams = {
            index: shortNames[indexSel],
        }
        // navigate(`?${createSearchParams(searchParams)}`)
        const obtainedPath = generatePath("/token-tracker/:index", searchParams)
        const navString = `${obtainedPath}`
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }

    function setNewIndex(index) {
        if(tableIndexes[indexNames[index]].redirect) {
            console.log("REDIRECT", tableIndexes[indexNames[index]].redirect)
            // navigate(tableIndexes[indexNames[index]].redirect)
            window.open(tableIndexes[indexNames[index]].redirect, '_blank')
            // window.open(`/${props.value.toLowerCase()}`, '_blank')}}  
            console.log("REDIRECT", tableIndexes[indexNames[index]].redirect)
        } else {
            setNewRoute(index)
            setDataGridTable([])
            setIndex(index)
            updateTable(index)
            console.log("TABLE", tableIndexes[indexNames[selectedIndex]])
        }
    }

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    function updateTable(index) {
        setLoading(true)
        getRealisedCap(tableIndexes[indexNames[index]].apiPath).then((data) => {
            console.log("OBTAIN TABLE DATA", data)
            setDataGridTable(data)
            setLoading(false)
        })
    }

    // useEffect(() => {
    //     updateTable(selectedIndex)
    // }, []);

    return (
        <main className="main">
            { props.isMobile && toggleDropDown && <div className="blackout"></div>}
            { !props.isMobile && <MenuLeaderBoard 
                // selectedToken={selectedToken} 
                selectedIndex={selectedIndex} 
                setNewIndex={setNewIndex}
                isMobile={props.isMobile}
                // setNewToken={setNewToken} 
            /> }
            { props.isMobile && toggleDropDown && <MenuLeaderBoard 
                // selectedToken={selectedToken} 
                selectedIndex={selectedIndex} 
                setNewIndex={setNewIndex}
                isMobile={props.isMobile}
                toggleMobile={toggleMenu}
                // setNewToken={setNewToken} 
            />}
            <div class="chart2">
                <div class="tableContainer">
                { !user && <div className="locker" style={{position: "absolute", 
                  width: '100%',
                  height: 'calc(100% - 130px)', 
                //  background: 'red',
                  zIndex: 1, 
                  marginTop: 130,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                //   backdropFilter: 'blur(5px)',
                  }}>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'}}> To see full dashboard </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'    }}> you can try to hack this page </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px'}}> or just sign up!  😉🍒 </h3>
                    {/* <ButtonSignUp withoutMargin={true} /> */}
                    <div style={{display:'flex', flexDirection: 'row', marginTop: '30px'}}>
                        <ButtonLogin blackTheme={true} />
                        <ButtonSignUp blackTheme={true} withoutMargin={true}/>
                    </div>
                </div> }
                    <div style={{width: '100%', height: '93%'}}>
                        <div className="tableHeader" style={{backgroundColor: 'rgba(238, 40, 138, 0.0)', 
                                                     height: '62.5px',
                                                     alignItems: 'center',
                                                     display: 'flex',
                                                    //  borderBottom: '0.3px solid #EE288A',
                                                     borderBottom: '2px solid rgb(29, 26, 45)',
                                                     }}> 
                            <p style={{fontFamily: 'SFProMedium', 
                                fontSize: '20px', 
                                color: "white",
                                marginLeft: '24px',
                                }}> {indexNames[selectedIndex]}</p>   
                            <div style={{display: 'flex', marginLeft:'auto',}}>
                                {props.isMobile && !toggleDropDown && 
                                <button class="menu-button-charts indexes-menu-button" id="indexesMenuButton" style={{marginRight:'25px'}} 
                                onClick={() => toggleMenu()}>
                                    <svg viewBox="0 0 24 24">
                                        <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"/>
                                    </svg>
                                </button>
                                // <img src={ hideButton } style={{ marginRight:'25px', zIndex: 2}} alt="text" onClick={() => toggleMenu()} /> }
                                }
                                {props.isMobile && toggleDropDown && <button
                                        className="menu-button-charts"
                                        style={{marginRight:'25px'}}
                                        onClick={{}}
                                    >
                                <svg viewBox="0 0 24 24">
                                <path d="M18.36 6.64a1 1 0 0 0-1.41 0L12 11.59 7.05 6.64a1 1 0 1 0-1.41 1.41L10.59 13l-4.95 4.95a1 1 0 0 0 1.41 1.41L12 14.41l4.95 4.95a1 1 0 0 0 1.41-1.41L13.41 13l4.95-4.95a1 1 0 0 0 0-1.41z" />
                                </svg>
                            </button>}
                            </div>                                
                        </div>
                        {/* <DescriptionBlock header={false} texts={['You have read the index description in full, so now you can improve your investment and trading decisions using it. 😎 🤑']} marginB={'10px'}/> */}
                            
                        <Table 
                            initialState={tableIndexes[indexNames[selectedIndex]].initialState} 
                            tableData={dataGridTable} 
                            tableLoading={loadingData}
                            columnType={tableIndexes[indexNames[selectedIndex]].columnType}
                            tableRef={tableRef}
                        /> 
                    </div>
                </div>
            </div>

            {/* <div style={{background: 'purple', width: '100%'}}></div> */}
            {/* <Table tableData={dataGridTable} tableLoading={loadingData}/> */}
        </main>
    )
}