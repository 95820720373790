import { React, useState, useEffect, useRef} from "react";
import arrow from "../assets/arrow.svg";
import './DropdownMenu.css'; 

import checkbox from "../assets/checkbox.svg"
import checkin from "../assets/checkin.svg"

import criteriaIcon from "../assets/icons/icon_criteria.svg"

function checkStarting(item, search) {
  return (item.name.toLowerCase().startsWith(search.toLowerCase())) || (!(item.shortName === undefined) ? item.shortName.toLowerCase().startsWith(search.toLowerCase()): false)
}

function checkIncluding(item, search) {
  return (item.name.toLowerCase().includes(search.toLowerCase())) || (!(item.shortName === undefined) ? item.shortName.toLowerCase().includes(search.toLowerCase()): false)
}

export function TokenStyle(props) {
  return (
    <div className={`${props.header? "" :"with-hover"} token-item`}>
      <img style={props.menuButton ? {marginLeft: '33px'}:{}} className="logo" src={props.item.img  == '' ? 'https://etherscan.io/images/main/empty-token.png' : props.item.img } />
      <div className="menu-selected" style={{marginLeft: 0}}>{props.item.name}</div>
    </div>
  )
}

function CheckStyle(props) {
  return (
    <div className="with-hover token-item">
      <img style={{marginLeft: '16px'}} src={props.selected ? checkin : checkbox} />
      <div className="menu-selected" style={{marginLeft: 0, padding: '6px 6px', fontSize: '14px'}}> {props.item.name} </div>
    </div>
  )
}


export const DropdownMenuSingleSelect = ({ showToast, token, menuButton, getItemFunc, el, setEl, items, size, renderStyle, startsWith=false, isReadOnly=false, checkIncludingFunc=checkIncluding, checkStartingFunc=checkStarting, placeholder='Search...'}) => {
    // console.log(renderStyle)

    const [isOpen, setIsOpen] = useState(false);
    // const [selectedIndex, setIndex] = useState(getIndexByToken(selectedToken));
    // const [selectedIndex, setIndex] = useState(getItemFunc(items, selectedElement));

    const [searchInput, setSearchInput] = useState("");

    const itemHeight = 40;
    const listMaxHeight = size * itemHeight;

    const menuStyle = menuButton ? {
                                      borderRadius: 0, 
                                      backgroundColor: 'transparent',
                                      border: 'none',
                                      borderBottom: '2px solid rgb(29, 26, 45)',
                                      } : {}


    const menuListStyle = menuButton ? {
                                          // backgroundColor: 'transparent',
                                          boxShadow: 'none',
                                          border: 'none',
                                          borderBottom: '2px solid rgb(29, 26, 45)',
                                          borderRadius: 'none',
                                          maxHeight: `${listMaxHeight}px`
                                        } : {maxHeight: `${listMaxHeight}px`}
    const dropdownRef = useRef(null);
    useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);   
  
    return (
      <div 
        ref={dropdownRef}
        className="dropdown-menu" 
        onClick={() => {isReadOnly ? showToast(`📖 read-only filters are not editable. To customize a filter, please click the 'Copy' button located in the top right corner.`, 'warning'): setIsOpen(!isOpen)}}
        // style={{ cursor: isReadOnly ? 'auto' : 'pointer' }} 
      >
        {/* <button className="menu-button" style={menuButton? {display: 'flex', backgroundColor: 'transparent', border: 'none', borderBottom: '2px solid rgb(29, 26, 45)', borderRadius: '0'}: {}}> */}
        <button className={`menu-button ${isReadOnly ? 'read-only':''}`} style={menuStyle} >
          {!isOpen ? 
              (renderStyle ? renderStyle(getItemFunc(items, el), true) : <div className="menu-selected">{getItemFunc(items, el).name}</div>)
                  : 
              <input 
                className="search-input"
                autoFocus
                placeholder={placeholder}
                type="text"
                value={searchInput}
                onChange={(e) => {console.log('Typed token', e.target.value);setSearchInput(e.target.value)}}
            />}
             <img 
                className="tokenField-arrow" 
                style={ { 
                  transform: isOpen ? 'rotate(180deg)': '', 
                  width: '12px', 
                  height: '6px', 
                  marginRight: '16px',
                  visibility: isReadOnly ? 'hidden' : 'visible',
                }} 
                src={ arrow } />
        </button>
        {isOpen && (
          <ul className="menu-list" style={menuListStyle}>
            {token && <li className="after-text">Check out <a href="https://app.cherry-pick.xyz/token-tracker/supported-assets" target="_blank" style={{color: 'rgb(219, 62, 136)'}}>the list of supported assets.</a> </li>}
            {items.map((item, index) => (
                (startsWith? checkStartingFunc(item, searchInput) : checkIncludingFunc(item, searchInput)) && (renderStyle ? <li onClick={() => {setEl(item)}}>{renderStyle(item, false, {key: index, menuButton: menuButton})}</li> : <li key={index} 
                                                         className="menu-item"
                                                         onClick={() => {setEl(item)}}>{item.name}</li>)
                // checkIncluding(item, searchInput) && <li><TokenStyle key={index} item={item} /></li>                           
            ))}
            {token && <li className="after-text" style={{paddingTop: 0}}>Didn't find the token you were looking for?</li>}
            {token && <li className="after-text" style={{paddingTop: 0}}>Write us in <a href="https://discord.gg/AWkjXPCkCY" target="_blank" style={{color: 'rgb(219, 62, 136)'}}>discord</a> or <a href="https://t.me/chairryman" target="_blank" style={{color: 'rgb(219, 62, 136)'}}>telegram.</a> </li>}
            {/* <li className="after-text" style={{paddingTop: 0, paddingBottom: 0}}>However, even now you can find incredible insights.</li> */}
            {/* <li className="after-text" style={{ paddingBottom: 0}}>We will soon add everything.</li> */}
            
          </ul>
        )}
      </div>
    );
  };

  export const DropdownMenuSingleSelectSmall = ({ items, size, renderStyle, handleItemChange, field, params, isReadOnly=false }) => {
    // console.log(renderStyle)
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setIndex] = useState(0);

    const [searchInput, setSearchInput] = useState("");

    const itemHeight = 40;
    const listMaxHeight = size * itemHeight;

    const dropdownRef = useRef(null);
    useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

    const chooseNameById = (index) => {
      // console.log("SINGLE SELECT", index, items)
      let name = ''
      items.forEach(item => {
        if(item.id === index) {
          name = item.name
          return
        }
      })
      return name
    }
  
    return (
      <div 
        ref={dropdownRef}
        className="dropdown-menu" 
        onClick={() => !isReadOnly && setIsOpen(!isOpen)}
        // style={{ opacity: isReadOnly ? 0.5 : 1 }} 
      >
        <button className={`menu-button ${isReadOnly ? 'read-only':'with-default-color'}`}>
          {!isOpen ? 
              (renderStyle ? renderStyle(items[selectedIndex], true) : <div className="menu-selected">{chooseNameById(params)}</div>)
                  : 
              <input 
                className="search-input"
                autoFocus
                placeholder='Search...'
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />}
             <img className="tokenField-arrow" style={ { transform: isOpen ? 'rotate(180deg)': '', width: '12px', height: '6px', marginRight: '16px', visibility: isReadOnly ? 'hidden' : 'visible',}} src={ arrow } />
        </button>
        {isOpen && (
          <ul className="menu-list" style={{ maxHeight: `${listMaxHeight}px`}}>
            {items.map((item, index) => (
                checkIncluding(item, searchInput) && (renderStyle ? <li onClick={() => handleItemChange(field, item.id)}><TokenStyle key={index} item={item}/></li> : <li key={index} 
                                                         className="menu-item"
                                                         onClick={() => handleItemChange(field, item.id)}>{item.name}</li>)
                // checkIncluding(item, searchInput) && <li><TokenStyle key={index} item={item} /></li>                           
            ))}
          </ul>
        )}
      </div>
    );
  };

export const DropDownMultiSelect = ({ small, items, params, handleItemChange, size, renderStyle, widthEl, triggerClick, handleCriteriaButtonClick, specialStyle=false, isReadOnly=false }) => {
  // console.log("DROPDOWN LIST", params)
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setIndex] = useState(0);

  const displayedGroups = new Set();

  const [searchInput, setSearchInput] = useState("");

  const itemHeight = 40;
  const listMaxHeight = size * itemHeight;

  // console.log("params checking", params, items)

  useEffect(() => {
    if(triggerClick) {
        !isReadOnly && setIsOpen(!isOpen)
        handleCriteriaButtonClick()
    }
  }, [triggerClick])
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChange = (params, id) => {
    console.log("ID", id)
    if(params && params.has(id)) {
      params.delete(id)
    } else {
      params.add(id)
    }
    return params
  }

  function getSelectedItems() {
      let name = ''
      let size = 0
      items.forEach(item => {
          if(params && params.has(item.id)) {
            // console.log("RESULTs", item.id, params, item.name)
            name = item.name
            size += 1
          }
      })

      return [name, size-1]
  }


function visualizeItems() {
    const [name, size] = getSelectedItems()
    return (
      // <p style={{fontFamily: 'SFPro', marginLeft: '20px', marginRight: '20px'}}>Criteries {name===''?'':':'} <span style={{color: '#ACD1FB'}}>{name} {size <= 0 ? '':'+ ' + size + ' others'}</span> </p>
      <p style={{fontFamily: 'SFPro', marginLeft: '6px', marginRight: '0px', marginTop: '5.5px', marginBottom: '5.5px'}}>Select Criteria </p>
    )
}

  return (
    <div 
      ref={dropdownRef} 
      className="dropdown-menu" 
      // style={{width: `${width}px`}}
      style={{width: widthEl ? widthEl : `auto`, minWidth: '300px'}}
    >
      <button className={`criteria-button ${isReadOnly ? 'read-only without-color':''}`} onClick={() => !isReadOnly && setIsOpen(!isOpen)} style={specialStyle ? {} : {border: '0'}}>
        {/* {!isOpen ? 
            (renderStyle ? renderStyle(items[selectedIndex], true) : <div className="menu-selected">{items[selectedIndex].name}</div>)
                : 
            <input 
              className="search-input"
              autoFocus
              placeholder='Search...'
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
          />
        } */}
        <div className="criteria-icon">
          <img src={criteriaIcon} />
        </div>
        {visualizeItems()}
        {/* <p style={{fontFamily: 'SFPro', marginLeft: '20px', marginRight: '20px'}}>Criteries: {items[0].name}   + 8 others</p> */}
        {/* <img className="tokenField-arrow" style={ { transform: isOpen ? 'rotate(180deg)': '', width: '12px', height: '6px', marginRight: '16px'}} src={ arrow } /> */}
      </button>
      {isOpen && (
        // <div style={{position: 'absolute', width: '100%', bottom: getSelectedItems()[1] > 4 ? '100%' : ''}} >
        <div style={{position: 'absolute', width: '100%', marginTop:'6px' ,bottom: '', zIndex: 10, boxShadow: '3px 16px 16px 3px rgba(0,0,0,0.9)'}} >
          {!small && <div className="menu-list-header">
            <h1 className="desc"> Select parameters for filtering</h1>
            <div className="search"> 
              <input 
                className="search-input"
                autoFocus
                placeholder='Search...'
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <div className="options"> 
              <a onClick={() => handleItemChange(new Set())} style={{ cursor:'pointer'}}>Clear</a>
              {/* <a onClick={() => handleItemChange(new Set(items.map(item => item.id)))} style={{marginLeft: 'auto', cursor:'pointer'}}>Select all</a> */}
            </div>

          </div>}
          <ul className="menu-list without-top-border" style={{ maxHeight: `${listMaxHeight}px`, margin: 0, padding: 0, position: 'relative'}}>
          {items.map((item, index) => {
              // Check if the item matches the search criteria
              if (!checkIncluding(item, searchInput)) {
                return null;
              }

              // Determine if this group should be displayed
              const shouldDisplayGroup = item.group ? !displayedGroups.has(item.group) : false;
              if (shouldDisplayGroup) {
                displayedGroups.add(item.group);
              }

              return (
                <div key={index}>
                  {shouldDisplayGroup && item.group && <p style={{
                            fontFamily: 'SFProBold',
                            fontSize: '14px',
                            color: '#9CA8B6', 
                            margin:0, 
                            paddingTop: '6px', 
                            paddingBottom: '6px',
                            marginLeft: '16px'}}>{item.group.toUpperCase()}</p>}
                  {renderStyle 
                  ? <li 
                      key={item.id} 
                      onClick={() => handleItemChange(handleChange(params, item.id))}>
                      {renderStyle(item, item.id, params.has(item.id))}
                    </li> 
                  : <li 
                      key={item.id} 
                      className="menu-item" 
                      onClick={() => handleItemChange(handleChange(params, item.id))}>
                      {item.name}
                    </li>
                  }
                </div>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export const DropDownMultiSelectSmall = ({field, params, small, items, handleItemChange, size, renderStyle, width, isReadOnly=false }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setIndex] = useState(0);

  const [searchInput, setSearchInput] = useState("");

  const itemHeight = 40;
  const listMaxHeight = size * itemHeight;

  const dropdownRef = useRef(null);
  useEffect(() => {
  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  // Attach the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    // Remove the event listener on cleanup
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [dropdownRef]);

  const handleChange = (params, id) => {
      if(params.has(id)) {
        params.delete(id)
      } else {
        params.add(id)
      }
      return params
  }

  function getSelectedItems() {
      let name = ''
      let size = 0
      items.forEach(item => {
          if(params.has(item.id)) {
            // console.log("RESULTs", item.id, params, item.name)
            name = item.name
            size += 1
          }
      })

      return [name, size-1]
  }

  function visualizeItems() {
      const [name, size] = getSelectedItems()
      return (
        <p style={{fontFamily: 'SFPro', marginLeft: '20px', marginRight: '20px'}}> {name} <span style={{color: '#ACD1FB'}}>{size === 0 ? '':'+ ' + size + ' others'}</span> </p>
      )
  }

  return (
    <div 
      ref={dropdownRef}
      className="dropdown-menu" 
      // style={{width: `${width}px`}}
      // style={{width: `auto`, opacity: isReadOnly ? 0.5 : 1}}
      onClick={() => !isReadOnly && setIsOpen(!isOpen)}
    >
      <button className={`menu-button ${isReadOnly ? 'read-only':'with-default-color'}`} >
        {/* {!isOpen ? 
            (renderStyle ? renderStyle(items[selectedIndex], true) : <div className="menu-selected">{items[selectedIndex].name}</div>)
                : 
            <input 
              className="search-input"
              autoFocus
              placeholder='Search...'
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
          />
        } */}
        {visualizeItems()}
        {/* <p style={{fontFamily: 'SFPro', marginLeft: '20px', marginRight: '20px'}}>Criteries: {items[0].name}   + 8 others</p> */}
        <img className="tokenField-arrow" style={ { transform: isOpen ? 'rotate(180deg)': '', width: '12px', height: '6px', marginRight: '16px', visibility: isReadOnly ? 'hidden' : 'visible',}} src={ arrow } />
      </button>
      {isOpen && (
        <div  >
          {!small && <div className="menu-list-header">
            <h1 className="desc"> Select parameters for filtering</h1>
            <div className="search"> 
              <input 
                className="search-input"
                autoFocus
                placeholder='Search...'
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <div className="options"> 
              <a onClick={() => handleItemChange(0, 'clear')} style={{ cursor:'pointer'}}>Clear</a>
              <a onClick={() => handleItemChange(0, 'all')} style={{marginLeft: 'auto', cursor:'pointer'}}>Select all</a>
            </div>

          </div>}
          <ul className="menu-list without-top-border" style={{ maxHeight: `${listMaxHeight}px`, margin: 0, padding: 0}}>
            {items.map(item => (
                checkIncluding(item, searchInput) && (renderStyle ? <li onClick={() => handleItemChange(field, handleChange(params, item.id))}> {renderStyle(item, item.id, params.has(item.id))}</li> : <li key={item.id} 
                                                        className="menu-item"
                                                        onClick={() => handleItemChange(field, handleChange(params, item.id))}>{item.name}</li>)
                // checkIncluding(item, searchInput) && <li><TokenStyle key={index} item={item} /></li>                           
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export const DropDownParameterFiltering = ({ items, params, handleItemChange, size, width, isReadOnly=false, triggerClick, handleCriteriaButtonClick }) => {
  // console.log('items before sort')
  // console.log(items)
  let sortedItems = [...items].sort((a, b) => a.group.localeCompare(b.group));
  // console.log('items after sort')
  // console.log(sortedItems)
  return (<DropDownMultiSelect isReadOnly={isReadOnly} triggerClick={triggerClick}  items={sortedItems} params={params} handleCriteriaButtonClick={handleCriteriaButtonClick} handleItemChange={handleItemChange} size={7} renderStyle={(input, key, selected) => {return (<CheckStyle item={input} key={key} selected={selected}/>)}} widthEl={width ? `${width}px`:'auto'} specialStyle={{border: '1px solid rgb(172, 209, 251)'}}/>)
  // specialStyle={{border: '1px solid rgb(172, 209, 251)'}} />)
}

export const DropDownSmallMultiSelect = ({ field, items, params, handleItemChange, size, width, isReadOnly=false  }) => {
  return (<DropDownMultiSelectSmall isReadOnly={isReadOnly} field={field} params={params} small={true} items={items} handleItemChange={handleItemChange} size={size} renderStyle={(input, key, selected) => {return (<CheckStyle item={input} key={key} selected={selected}/>)}} width={width} />)
}

function getIndexByToken(items, token) {
  let index = 0

  items.forEach((item, id) => {
    if(item.shortName === token) {
      index = id
    }
  })
  return items[index]
}

export const DropDownTokens = ({showToast, 
                          menuButton, 
                          selectedToken, 
                          setNewToken, 
                          items, 
                          size, 
                          isReadOnly=false,

                          setElement=(item) => setNewToken(item.shortName),
                          renderStyle = (input, header, additionalProps = {}) => <TokenStyle item={input} header={header} {...additionalProps} />,
                          getItemFunc = getIndexByToken,
                          checkIncludingFunc = checkIncluding,
                          checkStartingFunc = checkStarting,
                          placeholder='Search...'
                        }) => {
  // console.log(items)
  console.log('show warning', showToast)
  console.log(items)
  return (<DropdownMenuSingleSelect
              token={true}
              isReadOnly={isReadOnly}
              getItemFunc={getItemFunc} 
              menuButton={menuButton} 
              setEl={setElement} 
              el={selectedToken}  
              items={items} 
              size={size} 
              startsWith={true}
              showToast={showToast}
              renderStyle={renderStyle}
              checkIncludingFunc={checkIncludingFunc}
              checkStartingFunc={checkStartingFunc}
              placeholder={placeholder} />)
}


function getOrderById(items, id) {
  for (const item of items) {
      if(item.id === id) {
         return item
      }
  }
  return items[0]
}

export const DropDownSelectOrder = ({index, setNewIndex, items, size, isReadOnly=false }) => {
  return (<DropdownMenuSingleSelect getItemFunc={getOrderById} setEl={(item) => setNewIndex(item.id)} el={index} size={5} items={items} size={size} isReadOnly={isReadOnly}/>)
}

export const DropdownFilter = ({filter, handleCopy, handleDelete}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(prevIsOpen => !prevIsOpen);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button 
        className="ellipsis-btn" 
        onClick={toggleDropdown}
      >
        <span></span>
      </button>
      <div 
        className={`dropdown-menu2 ${isOpen ? 'open' : ''}`}
      >
        <button className="dropdown-item radius-top" onClick={() => {setIsOpen(false); handleCopy(filter)}}>
          <p>Copy filter</p>
        </button>
        <button className="dropdown-item radius-bottom destructive-action" onClick={() => {setIsOpen(false); handleDelete(filter)}}>
          <p>Delete filter</p>
        </button>
      </div>
    </div>
  );
};