import { DataGrid } from '@mui/x-data-grid';
import { EtherscanAddress, TokenTableFieldV1, ShowPercentChange, ETHChainTableField } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';

export const columns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 200,
        cellClassName: 'datagrid-token',
        renderCell: (props) => { return (<TokenTableFieldV1 value={props.value}/>) },
        sortable: false,
    },

    {
        field: '',
        headerName: 'Chain',
        width: 140,
        // description: 'MVRV=(Market Cap)/(Realised Cap)',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ETHChainTableField/>) },
    },
    {
        field: 'address',
        headerName: 'Contract Address',
        width: 140,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<EtherscanAddress value={props.value}/>) },
    },

    {
        field: 'price_0d',
        headerName: 'Price',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'price_percent_7d',
        headerName: '7d %',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'price_percent_30d',
        headerName: '30d %',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },

    {
        field: 'num_transfers',
        headerName: '# On-chain Transfers',
        width: 160,
        // description: 'MVRV=(Market Cap)/(Realised Cap)',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{addCommas(props.value)}</p>)},
    },
]

export const initialState = {
    sorting: {
      sortModel: [{ field: 'symbol', sort: 'desc' }],
    },
}