import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {titleLabel, titleTooltip, scaleX, scalePrice} from './utils.js'

// const nullifyEmptyStringsPlugin = {
//   id: 'nullifyEmptyStrings',
//   beforeDraw: (chart) => {
//     chart.data.datasets.forEach((dataset) => {
//       dataset.data = dataset.data.map(value => value === '' ? null : value);
//     });
//   },
// };
// const removeEmptyStringsPlugin = {
//   id: 'removeEmptyStringsPlugin',
//   beforeDatasetDraw: (chart) => {
//     // Перебираем все данные в графике и заменяем пустые строки на null
//     chart.data.datasets.forEach(dataset => {
//       dataset.data = dataset.data.map(value => value === "" ? null : value);
//     });
//   }
// };

const removeEmptyStringsPlugin = {
  id: 'removeEmptyStringsPlugin',
  beforeDatasetDraw: (chart) => {
    // console.log(`ЗДГПШТ ШТ ЦЩКЛ`);
    // Перебираем все данные в графике и заменяем пустые строки на null
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      // Проверяем каждое значение данных
      dataset.data.forEach((value, dataIndex) => {
        if (value.y === "") {
          // Логируем замену пустых строк на null
          // console.log(`Dataset ${datasetIndex}, DataIndex ${dataIndex}: Empty string replaced with null`);
          // console.log(value, value.y, dataIndex);
          dataset.data[dataIndex].y = undefined; // Заменяем пустую строку на null
        }
      });
    });
  }
};

  
ChartJS.register(
    // removeEmptyStringsPlugin,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

// // Функция для создания градиента
// function createGradient(chart) {
//   const { ctx, chartArea } = chart;
//   if (!chartArea) {
//     return "rgba(0, 0, 0, 0)"; // Пока область не прорисована
//   }
//   const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

//   gradient.addColorStop(0, "green"); // MVRV ниже 1
//   gradient.addColorStop(0.5, "yellow"); // MVRV ~ 1
//   gradient.addColorStop(1, "red"); // MVRV выше 1

//   return gradient;
// }

// function createColorGradient(value) {
//   if (value <= 1) {
//     // Плавный переход от зелёного к жёлтому
//     const greenRatio = 1 - value;
//     const yellowRatio = value;
//     return `rgba(${255 * yellowRatio}, ${255 * greenRatio}, 0, 1)`;
//   } else {
//     // Плавный переход от жёлтого к красному
//     const redRatio = Math.min((value - 1), 1); // Ограничиваем до 1
//     const yellowRatio = 1 - redRatio;
//     return `rgba(${255}, ${255 * yellowRatio}, 0, 1)`;
//   }
// }

const ColorMap2 = ['rgba(236,27,122,255)', 
  'rgba(238,96,144,255)', 
  'rgba(243,137,166,255)', 
  // 'rgba(233,174,194,255)',
  'rgba(253,211,222,255)', 
  'rgba(223,240,216,255)', 
  'rgb(195,227,181)', 
  'rgb(163,213,148)', 
  'rgba(144,200,121,255)', 
  'rgba(112,192,86,255)']



const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

// const getColor = (ctx) => {
//   const yValue = ctx.p1.parsed.y; // Получаем значение на оси Y для текущего сегмента

//   // Проверяем диапазон p1.parsed.y и возвращаем нужный цвет
//   if (yValue > 2) {
//     return '#fc268d'; // Красный
//   } else if (yValue >= 1.5 && yValue <= 2) {
//     return '#f16d8a'; // Желтый
//   } else if (yValue >= 1.25 && yValue <= 1.5) {
//     return '#f7b48c'; // Желтый
//   } else if (yValue >= 1.1 && yValue <= 1.25) {
//     return '#eeca6e'; // Желтый
//   } else if (yValue >= 0.9 && yValue <= 1.1) {
//     return '#ebec6b'; // Желтый
//   } else if (yValue >= 0.8 && yValue <= 0.9) {
//     return '#bff14a'; // Желтый
//   } else if (yValue >= 0.5 && yValue <= 0.8) {
//     return '#74dd5e'; // Желтый
//   } else if (yValue < 0.5) {
//     return '#1dc676'; // Зеленый
//   }
// };

// const getColor = (ctx) => {
//   const yValue = ctx.p1.parsed.y; // Получаем значение на оси Y для текущего сегмента

//   // Проверяем диапазон p1.parsed.y и возвращаем нужный цвет с opacity
//   if (yValue > 2) {
//     return 'rgba(252, 38, 141, 0.8)'; // Красный с opacity 0.5
//   } else if (yValue >= 1.5 && yValue <= 2) {
//     return 'rgba(241, 109, 138, 0.8)'; // Желтый с opacity 0.5
//   } else if (yValue >= 1.25 && yValue <= 1.5) {
//     return 'rgba(247, 180, 140, 0.8)'; // Желтый с opacity 0.5
//   } else if (yValue >= 1.1 && yValue <= 1.25) {
//     return 'rgba(238, 202, 110, 0.8)'; // Желтый с opacity 0.5
//   } else if (yValue >= 0.9 && yValue <= 1.1) {
//     return 'rgba(235, 236, 107, 0.8)'; // Желтый с opacity 0.5
//   } else if (yValue >= 0.8 && yValue <= 0.9) {
//     return 'rgba(191, 241, 74, 0.8)'; // Желтый с opacity 0.5
//   } else if (yValue >= 0.5 && yValue <= 0.8) {
//     return 'rgba(116, 221, 94, 0.8)'; // Желтый с opacity 0.5
//   } else if (yValue < 0.5) {
//     return 'rgba(29, 198, 118, 0.8)'; // Зеленый с opacity 0.5
//   }
// };

const getNull = (ctx) => {
  if ((ctx.p0.raw.y === '') || (ctx.p1.raw.y === '')) {
    return [6, 6];
  } else {
    return []; 
  }
}

const getColor = (ctx) => {
  // const yValue = ctx.p1.parsed.y; // Получаем значение на оси Y для текущего сегмента
  const direction = ctx.p1.parsed.y > ctx.p0.parsed.y ? ctx.p1 : ctx.p0; // Определяем направление: вверх или вниз
  const yValue = direction.parsed.y; // Берем значение из выбранной точки
  // console.log(ctx.p1.parsed.x,ctx.p1.parsed.y);
  // console.log(ctx);
  

  // if ((ctx.p0.raw.y === '') || (ctx.p1.raw.y === '')) {
  //   return 'rgba(0,0,0,0)';
  // }

  // Проверяем диапазон p1.parsed.y и возвращаем нужный цвет с opacity
  if (yValue > 2) {
    return 'rgba(255, 68, 102, 0.9)'; // Холодный красный (розовый) с opacity 0.8
  } else if (yValue >= 1.5 && yValue <= 2) {
    return 'rgba(255, 128, 128, 0.9)'; // Мягкий красный с opacity 0.8
  // } else if (yValue >= 1.5 && yValue <= 1.5) {
  //   return 'rgba(255, 184, 184, 0.9)'; // Светлый розовый с opacity 0.8
  } else if (yValue >= 1.25 && yValue <= 1.5) {
    return 'rgba(255, 215, 215, 0.9)'; // Пастельный розовый с opacity 0.8
  } else if (yValue >= 0.8 && yValue <= 1.1) {
    return 'rgba(255, 255, 180, 0.9)'; // Светло-желтый с opacity 0.8
  } else if (yValue >= 0.5 && yValue <= 0.8) {
    return 'rgba(158, 255, 192, 0.9)'; // Легкий холодный зеленый с opacity 0.8
  } else if (yValue >= 0.3 && yValue <= 0.5) {
    return 'rgba(77, 255, 179, 0.9)'; // Холодный зеленый с opacity 0.8
  } else if (yValue < 0.3) {
    return 'rgba(47, 235, 184, 0.9)'; // Яркий холодный зеленый с opacity 0.8
  }
};





export const data = {
    labels: [],
    datasets: [
        {
          label: 'Price',
          type: 'line',
          data: [],
          fill: false,
          borderColor: 'rgb(255, 255, 255)',
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: 'rgb(255, 255, 255)',
          yAxisID: 'y1',
          order: 1,
        },
        {
          label: 'MVRV',
          type: 'line',
          data: [],
          fill: false,
          borderColor:  'rgba(255, 255, 180, 0.9)',
          backgroundColor: 'rgba(255, 255, 180, 0.9)',
          segment: {
            // Применяем цвет для каждой части линии
            borderColor: (ctx) => getColor(ctx),
            borderDash: (ctx) => getNull(ctx),
          },
          // segment: {
          //   borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
          //   backgroundColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
          //   // borderDash: ctx => skipped(ctx, [6, 6]),
          // },
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          yAxisID: 'y',
          order: 2,
          spanGaps: true,
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        // custom: removeEmptyStringsPlugin, // Добавляем плагин в конфигурацию
        zoom: {
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.04,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
              overScaleMode: 'y',
            },
            pan: {
              enabled: true,
              overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              // y: {min: 0, max:5},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
            labels: {
              font: {
                family: 'Arial', // Шрифт
                size: 14,        // Размер шрифта
                // weight: 'simibold',  // Жирный шрифт
              },
              color: 'rgba(255, 255, 255, 1)', // Цвет текста
              boxWidth: 20, // Размер значков в легенде
              padding: 15, 
              generateLabels: (chart) => {
                // Создаем пользовательские метки для легенды
                return [
                  {
                    text: 'MVRV >> 1, Overheated Market 🥵',  // Описание для красного цвета
                    fillStyle: 'rgba(255, 68, 102, 0.8)', // Красный цвет
                    strokeStyle: 'rgba(255, 68, 102, 0.8)', // Красный цвет
                    lineWidth: 2,
                    borderRadius: 2, // Скругление углов
                  },
                {
                  text: 'MVRV ~ 1, Balanced Market',  // Описание для желтого цвета
                  fillStyle: 'rgba(255, 255, 180, 0.8)', // Желтый цвет
                  strokeStyle: 'rgba(255, 255, 180, 0.8)', // Желтый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'MVRV << 1, Undervalued Market 😴',  // Описание для зеленого цвета
                  fillStyle: 'rgba(47, 235, 184, 0.8)', // Зеленый цвет
                  strokeStyle: 'rgba(47, 235, 184, 0.8)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'Price',  // Описание для зеленого цвета
                  fillStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  strokeStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
              ];
              },
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: titleLabel,
              title: titleTooltip,
            },
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 1,
              yMax: 1,
              borderColor: 'rgba(255, 255, 180, 0.2)',
              borderWidth: 1,
              order: 1,
            }
          }
        }
    },
    scales: {
        x: scaleX,
        y: {
          min: 0,
          max: 15,
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
          // stacked: true,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
    maintainAspectRatio: false,
  };