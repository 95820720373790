const ipAddress = 'internal.cherry-pick.xyz'
// const protocol = 'https'
// const ipAddress = 'devapi.cherry-pick.xyz'
const protocol = 'https'

export const index2NameNew = {
    0: "price",

    1: "mpl",
    2: "sopr",
    3: "sopr_pl",
    4: "onchain_volume",
    5: "realised_price",
    6: "mvrv",
    7: "mvrv",
    8: "num_addresses_change",
    9: "upl",
    10: "upl",

    11: "supply_pl",

    // 11: "upl",
    // 12: "upl",

    // 8: "mvrv",
    // 9: "mvrv",
    // 10: "mvrv",
    // 6: "realised_cap",
}

export const index2Name = {
    0: "price",

    2: "mpl",
    3: "sopr",
    4: "onchain_volume",
    5: "realised_price",
    // 6: "realised_cap",
    6: "sc_flows",

    // 7: "mvrv",
    // 8: "mvrv",

    7: "mpl",
    8: "mpl",
    9: "mpl_cohorts",
    10: "realised_profit_loss_ratio",
    11: "sopr",
    12: "sopr_pl",

    13: "mpl",
    14: "mpl_cohorts",
    15: "realised_profit_loss_ratio",

    16: "onchain_volume",
    17: "average_transaction_volume",
    18: "num_transactions",

    19: "balance_cohort_flows",
    20: "balance_cohort_flows",
    21: "moving_realised_price",
    22: "onchain_volume_cohorts",

    // 32: "realised_price_dist",
    
    23: "upl",
    24: "upl_cohorts",
    25: "supply_pl",
    26: "supply_pl_cohorts",
    27: "num_addresses_pl",

    28: "num_addresses",
    29: "num_addresses_cohorts",
    30: "num_addresses_net",
    31: "realised_price",
    32: "realised_price_cohorts",
    // 34: "realised_cap",
    33: "balance_cohorts",
    34: "realised_cap_cohorts",
    35: "balance_cohorts",
    36: "realised_cap_cohorts",

    37: "cex_balance",
    38: "cex_flows",
    39: "cex_flows",
    40: "cex_flows_cohorts",
    41: "cex_cohort_flows",

    42: "sc_balance",
    43: "sc_flows",
    44: "sc_flows",

    // 49: "mvrv",
    // 50: "mvrv",
    // 51: "mvrv",

}

// const CEX = [
//     'abcc', 'ascendex', 'bgogo', 'binance', 'bitmex', 'bitmart', 'bitfinex',
//     'bithumb', 'bitstamp', 'bittrex', 'coss.io', 'crex24', 'cobinhood', 'coinmetro',
//     'coinhako', 'coinone', 'compromised', 'crypto.com', 'derivatives', 'eth2_depositor',
//     'ftx', 'fiat_gateway', 'gbx', 'gate.io', 'gemini', 'hitbtc', 'hoo.com', 'hot_wallet',
//     'hotbit', 'huobi', 'kraken', 'kryptono', 'kucoin', 'latoken', 'liquid', 'okex', 'otc', 
//     'options_trading', 'other', 'paribu', 'poloniex', 'remitano', 'shapeshift', 'streamity',
//     'tagz', 'tidex', 'topbtc', 'trade.io', 'upbit', 'yunbi', 'zb.com'
//   ]

const CEX = [
    'binance', 'bitmex', 'bitfinex', 'bittrex', 'crypto.com', 
    'ftx', 'gate.io',  'huobi', 'kraken',  'kucoin', 'other',
  ]

//   "Overview",
//         "Moving Profits & Losses",
//         "SOPR",
//         "Total Transaction Volume",
//         "Realised Price",
//         "Realised Capitalization", 
//         "CEX Inflow & Outflow",

export const parseIndexFieldsNew = {
    0: ["close"],

    1: ["losses", "profits"],
    2: ["sopr"],
    3: ["sopr_losses", "sopr_profits"],
    4: ["volume"],
    5: ["realised_price"],
    6: ["mvrv"],
    7: ["mvrv_z_score"],
    8: ["last_num_addresses", "num_addresses_change"],

    
    9: ["unrealised_profits", "unrealised_losses"],
    10: ["net_unrealised_pl"],
    11: ["supply_profits_rel", "supply_losses_rel"],
    // 11: ["supply_profits", "supply_losses"],

    // 11: ["unrealised_profits", "unrealised_losses", "net_unrealised_pl"],
    // 12: ["unrealised_profits", "unrealised_losses", "net_unrealised_pl"],

    // 8: ["mvrv"],
    // 9: ["mvrv"],
    // 10: ["mvrv"],
    // 6: ["market_cap", "realised_cap"],
    
}


export const parseIndexFields = {
    0: ["close"],

    2: ["losses", "profits"],
    3: ["sopr"],
    4: ["onchain_volume"],
    5: ["realised_price"],
    // 6: ["market_cap", "realised_cap"],
    6: ["inflow", "outflow"],

    // 7: ["mvrv"],
    // 8: ["mvrv_z_score"],

    7: ["losses", "profits"],
    8: ["nmpl"],
    9: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    10: ["ratio"],
    11: ["sopr"],
    12: ["sopr_losses", "sopr_profits"],

    13: ["losses", "profits"],
    14: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    15: ["ratio"],

    16: ["onchain_volume"],
    17: ["average_volume"],
    18: ["tx_number"],
    // 16: ["num_addresses"],
    19: ["inflow", "outflow"],
    20: ["netflow"],
    21: ["moving_realised_price"],
    22: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],

    // 32: [""], 

    23: ["unrealised_losses", "unrealised_profits"],
    24: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    25: ["supply_losses", "supply_profits"],
    26: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    27: ["num_addresses_losses", "num_addresses_profits"],
    
    28: ["num_addresses"],
    29: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    30: ["num_addresses"],
    31: ["realised_price"],
    32: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    // 34: ["market_cap", "realised_cap"],
    33: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", 'Exchanges', 'Smart Contracts'],
    34: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", 'Exchanges', 'Smart Contracts'],
    35: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts'],
    36: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts'],

    37: ["balance"],
    38: ["inflow", "outflow"],
    39: ["netflow"],
    40: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    41: ["netflow"],

    42: ["balance"],
    43: ["inflow", "outflow"],
    44: ["netflow"],

    // 49: ["mvrv"],
    // 50: ["mvrv"],
    // 51: ["mvrv"],

}

const toolName2params = {
    "Resolution": "resolution",
    "Metric": "metric",
    "Wallet Cohort": "cohort",
    "Wallet Size": "cohort",
    "Wallet Age": "cohort",
    "Moving average": "moving_average",
    "Equivalent": "equivalent",
    "Sample": "sample",
    "Cohort type":"cohort_type",
    "CEX": "CEX",
    "Address Type": "address_type",
    "Moving average type": "moving_average_type",
}

async function getRequest(index, token, params, tokenVersion, apiVersion) {

    // Based on token version, select corresponding endpoint
    const endpoint = (tokenVersion === '1' || tokenVersion === undefined)  ? index2Name[index] : index2NameNew[index];

    // console.log("GET REQUEST", `${protocol}://${ipAddress}/${token}/${index2Name[index]}?` + new URLSearchParams(params))
    if(apiVersion === '2') {
            const response = await fetch(`${protocol}://${ipAddress}/v3/${token}/${endpoint}?` + new URLSearchParams(params), {
                method: 'GET',
            })
            return await response.json()
    }
    const response = await fetch(`${protocol}://${ipAddress}/${token}/${endpoint}?` + new URLSearchParams(params), {
        method: 'GET',
    })
    return await response.json()
}

function parseGeneral(index, data, params, version) {
    // let dataOutput = [data.map(point => point['datetime'])]
    var fields = version === '2' ? parseIndexFieldsNew[index] : parseIndexFields[index]
    if ("cohort_type" in params) {
        if (params["cohort_type"] == "age") {
            fields = ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y",  "5y-7y", 'Exchanges', 'Smart Contracts']
        }
        else if (params["cohort_type"] == "balance") {
            fields = [
                "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
                "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
                'Exchanges', 'Smart Contracts'
            ]
        }
        else {
            fields = ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts']
        }
    }

    let dataOutput = []
    for (const field of fields) {
        // console.log("FIELD:", parseIndexFields[index])
        // console.log("FIELD:", field)
        // console.log("FIELD:", parseIndexFields[index][0])
        // console.log("FIELD:", data.slice(0, 10))
        // console.log("DATA", data.map(point => point[field]))
        // dataOutput.push(data.map(point => point[field]))

        dataOutput.push(data.map(point => {return {x: point['datetime'].split('T').join(' ') , y: point[field]}}))
    }
    return dataOutput
}

function parseSopr(index, data, params) {
    // let dataOutput = [data.map(point => point['datetime'])]
    console.log("PARSE SOPR")
    var fields = parseIndexFields[index]
    if ("cohort_type" in params) {
        if (params["cohort_type"] == "age") {
            fields = ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y",  "5y-7y", 'Exchanges', 'Smart Contracts']
        }
        else if (params["cohort_type"] == "balance") {
            fields = [
                "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
                "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
                'Exchanges', 'Smart Contracts'
            ]
        }
        else {
            fields = ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts']
        }
    }

    let dataOutput = []
    for (const field of fields) {
        // console.log("FIELD:", parseIndexFields[index])
        // console.log("FIELD:", field)
        // console.log("FIELD:", parseIndexFields[index][0])
        // console.log("FIELD:", data.slice(0, 10))
        // console.log("DATA", data.map(point => point[field]))
        // dataOutput.push(data.map(point => point[field]))
        console.log("PARSE SOPR", data)
        dataOutput.push(data.map(point => {return {x: point['datetime'].split('T').join(' ') , y: point[field] === '' ? NaN : point[field]}}))
    }
    return dataOutput
}

function parseRealisedPriceDist(index, data) {
    // let dates = []
    // let dists = []
    const dates = data.map(point => point.datetime)
    const dists = data.map(point => point.dist)
    // let i=0
    // for(i=0; i < data.length; i++) {
    //     dists.push(data[i].dist.map(point => {return {y:point.price_level, x:point.value}}))
    //     dates.push(data[i].datetime)
    // }
    const dataOutput = [dates, dists, dates.length]
    return dataOutput
}

function parse(index, data, params, version) {
    // if ((index !== 3) && (index !== 17)) {
    // if (index !== 32) {
    //     return parseGeneral(index, data, params, version)
    // } else {
    //     return parseRealisedPriceDist(index, data)
    // }
    return parseGeneral(index, data, params, version)
}

export async function getData(index, token, selectedTools, tools, tokenVersion, apiVersion) {
    let params = {
        from: '2017-01-01T00:00:00',
        to: '2027-01-07T00:00:00',
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    }
    for (const tool in selectedTools) {
        console.log("tools", tool, tools, toolName2params[tool], tools[tool], selectedTools[tool])
        params[toolName2params[tool]] = tools[tool][selectedTools[tool]]
    }
    // const params = {
    //     resolution: 'day',
    //     from: '2020-01-01T00:00:00',
    //     to: '2022-01-07T00:00:00'
    // }
    console.log("Parameters", params)
    if ('cohort' in params) {
        const regex = new RegExp(`[🦠🍤🦀🐙🐟🐬🦈🐳🐋 ]`, 'g');
        params['cohort'] = params['cohort'].replace(regex, '');
    }
    console.log("Parameters", params)
    const data = await getRequest(index, token, params, tokenVersion, apiVersion).then((data) => parse(index, data, params, tokenVersion))
    // if(token !== 'btc') {
    //     const data = await getRequest(index, token, params).then((data) => parse(index, data, params))
    // } else {
    //     const data
    // }

    let priceParams = {
        from: params.from,
        to: params.to,
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    }
    // priceParams['resolution'] = 'resolution' in params ? params.resolution : 'day'
    priceParams['resolution'] = 
        ('resolution' in params && ['week', 'month'].includes(params.resolution)) 
            ? 'day' 
            : ('resolution' in params ? params.resolution : 'day');

    if (priceParams['resolution'] == 'block') {
        priceParams['resolution'] = 'minute'
    }
    const price = await getRequest(0, token, priceParams, tokenVersion, apiVersion).then((data) => parse(0, data, [], tokenVersion))

    console.log('DATA:')
    console.log(data)
    console.log('Price:')
    console.log(price)
    
    return [data, price]
    // return [data, price[1]]

}

// async function parseData(index, data) {

// }

// function updateChart(index, chartRef, data) {

// }

export async function getMPL(token) {
    const params = {
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
        resolution: 'day',
        from: '2020-01-01T00:00:00',
        to: '2027-01-07T00:00:00'
    }
    // const options = {
    //     method: 'GET',
    //     body: JSON.stringify(params)
    // }
    // const response = await fetch('http://192.168.1.94/sushi/mpl?' + new URLSearchParams(params), {
    //     method: 'GET',

    // })
    if (token == 'uniswap') {
        token = 'uni'
    }
    const response = await fetch(`${protocol}://${ipAddress}/${token}/mpl?` + new URLSearchParams(params), {
        method: 'GET',
    })
    console.log("GET RESPONSE")
    console.log(response)
    return await response.json()
}

export async function getPrice(token) {
    const params = {
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
        resolution: 'day',
        from: '2017-01-01T00:00:00',
        to: '2027-01-07T00:00:00'
    }
    // const options = {
    //     method: 'GET',
    //     body: JSON.stringify(params)
    // }
    // const response = await fetch('http://192.168.1.94/sushi/mpl?' + new URLSearchParams(params), {
    //     method: 'GET',

    // })
    if (token == 'uniswap') {
        token = 'uni'
    }
    const response = await fetch(`${protocol}://${ipAddress}/${token}/price?` + new URLSearchParams(params), {
        method: 'GET',
    })
    console.log("GET RESPONSE")
    return await response.json()
}


async function getOverviewRequest(token, page) {
    //const requestString = `${protocol}://${ipAddress}/${token}/${page}`
    const requestString = `${protocol}://${ipAddress}/${token}/overview?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`
    // console.log("GET REQUEST", `${protocol}://${ipAddress}/${token}/${index2Name[index]}?` + new URLSearchParams(params))
    const response = await fetch(requestString, {
        method: 'GET',
    })
    return await response.json()
}

export async function getRealisedCap(index) {
    // const requestString = `${protocol}://${ipAddress}/tokens/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    // const requestString = `${protocol}://${ipAddress}${index === 'macro' ? '/v3/tokens/realised_price': `/tokens/${index}`}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`
    let requestString;

    if (['supported_tokens', 'supported_tokens_old', 'realised_price', 'holders_growth'].includes(index)) {
        requestString = `${protocol}://${ipAddress}/v3/tokens/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`;
    } else {
        requestString = `${protocol}://${ipAddress}/tokens/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`;
    }
        const response = await fetch(requestString, {
            method: 'GET',
    })

    const unparsedData = await response.json()
    console.log("DATA unnpaarsed", unparsedData)
    // return unparsedData
    return parseRealisedCap(unparsedData)
}

export async function getTokens() {
    const requestString = `${protocol}://${ipAddress}/v3/tokens_list_extended?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const dataList = await response.json()

    // const tokensDict = data.reduce((acc, token) => {
    //     acc[token.token_address] = {
    //       symbol: token.symbol,
    //       name: token.name,
    //       slug: token.slug,
    //       logo: token.logo
    //     };
    //     return acc;
    //   }, {});
    
    return dataList
}

export async function getTable(index) {
    const requestString = `${protocol}://${ipAddress}/tokens/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const unparsedData = await response.json()
    console.log("DATA unnpaarsed", unparsedData)
    // return unparsedData
    return parseRealisedCap(unparsedData)
}

export async function getLeaderboard(index, token) {
    const requestString = `${protocol}://${ipAddress}/${token}/leaderboard/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const unparsedData = await response.json()
    console.log("DATA unnpaarsed", unparsedData)
    // return unparsedData
    return parseLeaderboard(unparsedData, token)
}

function parseLeaderboard(data, token) {
    let res = []
    for (const [key, value] of Object.entries(data)) {
        value['id'] = key
        value['address'] = {address: value['address'], 'token':token, tag: value['tag']}

        res.push(value)
    }
    return res
}

export async function getWalletStats(address, token) {
    const requestString = `${protocol}://${ipAddress}/${address}/${token}/stats?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    return data
}

export async function getWalletInfo(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/overview?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, 
        {method: 'GET',
    })
    const data = await response.json()

    if(data.status === 200) {
        if(data.result.is_contract || data.result.is_erc20) {
            data['show_all'] = false
        } else {
            data['show_all'] = true
        }
        return data.result
    }

    return false
}

export async function getWalletBalanceInfo(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/balance?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, 
        {method: 'GET',
    })
    const data = await response.json()

    console.log("WALLET INFO::", data)

    if(data.status === 200) {
        data.result.balances_by_tokens.forEach((element, id) => {
            element['id'] = id
        })
        return data.result
    }

    return false
}

export async function getWalletPerformanceInfo(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/performance?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, 
        {method: 'GET',
    })
    const data = await response.json()

    console.log("WALLET INFO::", data)

    if(data.status === 200) {
        data.result.tokens_by_total_pl.forEach((element, id) => {
            element['id'] = id
        })
        data.result.tokens_by_realised_pl.forEach((element, id) => {
            element['id'] = id
        })
        return data.result
    }

    return false
}

export async function getTransfers(address, token, res='auto') {
    const requestString = `${protocol}://${ipAddress}/${address}/${token}/transfers?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()
    parseDataTransfers(data)

    let priceParams = {
        from: '2017-01-01T00:00:00',
        to: '2027-01-07T00:00:00',
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
        // resolution:  'day',
        resolution: res,
    }

    const [received_r, sent_r] = parseRadiuses(data)

    const price = await getRequest(0, token, priceParams).then((data) => parse(0, data, []))

    // console.log('DATA:')
    // console.log(data)
    // console.log('Price:')
    // console.log(price)
    
    return [data, received_r, sent_r, price]
}

export async function getBalanceHistory(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/balance_history?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    if(data.status === 200) {
        let values = data.result 
        parseBalance(values)

        return values
    }

    return false
}

export async function getPLHistory(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/total_pl_history?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    if(data.status === 200) {
        let values = data.result 
        parsePL(values)

        return values
    }

    return false
}

export async function getRealisedPLHistory(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/realised_pl_history?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    if(data.status === 200) {
        let values = data.result 
        parseRealisedPL(values)

        return values
    }

    return false
}

function parseRadiuses(data) {
    const received_r = data.data_received.map((point) => point.r)
    const sent_r = data.data_sent.map((point) => point.r)
    return [received_r, sent_r]
}

function parseDataTransfers(data) {
    data.data_received.forEach(element => {
        if(element.x.length >= 12) {
            element.x = element.x.split('T').join(' ').slice(0, 13) + ':00'
        }
        // element.x = element.x + (element.x.length < 12 ? '' : ':00')
    });
    data.data_sent.forEach(element => {
        if(element.x.length >= 12) {
            element.x = element.x.split('T').join(' ').slice(0, 13) + ':00'
        }
        // element.x = element.x.split('T').join(' ').slice(0, 13)
        // element.x = element.x + (element.x.length < 12 ? '' : ':00')
    });
}

function parseBalance(data) {
    data.forEach(element => {
        element.y = element.balance_dollar
        element.x = element.week_start
    })
}

function parsePL(data) {
    data.forEach(element => {
        element.y = element.total_pl
        element.x = element.week_start
    })
}

function parseRealisedPL(data) {
    data.forEach(element => {
        element.y = element.realised_pl_by_token
        element.x = element.week_start
        element.y2 = element.realised_profit
        element.y3 = element.realised_loss
    })
}

function calculatePercent(val1, val2) {
    return ((val2 - val1)/val1) * 100.0
}

function parseRealisedCap(data) {
    let res = []
    for (const [key, value] of Object.entries(data)) {
        value['id'] = key
        // const percent7d = calculatePercent(value['realised_cap_7d'], value['realised_cap_1d'])
        // const percent30d = calculatePercent(value['realised_cap_30d'], value['realised_cap_1d'])
        // // currevalue['realised_cap_1d']
        // value['realised_cap_7d'] = percent7d
        // value['realised_cap_30d'] = percent30d

        // const percent7dM = calculatePercent(value['market_cap_7d'], value['market_cap_1d'])
        // const percent30dM = calculatePercent(value['market_cap_30d'], value['market_cap_1d'])

        // value['market_cap_7d'] = percent7dM
        // value['market_cap_30d'] = percent30dM

        res.push(value)
    }
    return res
}

function parseGigantTransfers(dataResponse, token) {
    const scanLink = token === 'arb' ? 'arbiscan.io' : 'etherscan.io'
    const data = dataResponse.map((row, index) => {
        return {
            id: index,
            hash: [
                    row.transaction_hash, 
                    scanLink
                  ],
            from: [
                    (row.from_address_type !== 'address' ? row.from_address_type : ""), 
                    (row.from_address_tag === "" ? row.from_address : row.from_address_tag),
                    scanLink,
                  ],
            to: [
                    (row.to_address_type !== 'address' ? row.to_address_type : ""), 
                    (row.to_address_tag === "" ? row.to_address : row.to_address_tag),
                    scanLink,
                ],
            // typeFrom: row.fromType,
            // typeTo: row.toType,
            ageFrom: row.from_address_age_cohort,
            ageTo: row.to_address_age_cohort,
            wealthFrom: row.from_address_balance_cohort,
            wealthTo: row.to_address_balance_cohort,
            value: row.value,
            valueDollar: row.value_dollar,
            profit: row.npl,
            time: new Date(row.block_timestamp),
        }
    })
    return data
} 

export async function checkAddress(address) {
    const requestString = `${protocol}://${ipAddress}/${address}/is_valid?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()
    return data
}

async function getGigantTransfersRequest(token, limit) {
    const requestString = `${protocol}://${ipAddress}/${token}/giant_transactions?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97&limit=${limit}`

    const response = await fetch(requestString, {
        method: 'GET',
    })
    return await response.json()
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}



export async function getOverviewData(token, page) {
    const data = await getOverviewRequest(token, page)

    return data
}

export async function getGigantTransfers(token, limit=200) {
    const data = parseGigantTransfers(await getGigantTransfersRequest(token, limit), token)
    
    return data
}