import { getShortNameMapping } from "./data"

import { columns as columnsTypeMacro } from "./TableSettings/MacroTable.js"
import { columns as columnsTypeHODL } from './TableSettings/HODLTable.js';
import { columns as columnsTypeCEX } from "./TableSettings/CEXTable";
import { columns as columnsTypeSupportedTokens } from "./TableSettings/SupportedTokens.js"
import { columns as columnsTypeSupportedOldTokens } from "./TableSettings/SupportedOldTokens.js"
import { columns as columnsTypeHoldersGrowth } from "./TableSettings/HoldersGrowth.js"

import { initialState as initialStateHODL } from "./TableSettings/HODLTable.js";
import { initialState as initialStateMacro } from "./TableSettings/MacroTable.js";
import { initialState as initialStateCEX } from "./TableSettings/CEXTable";
import { initialState as initialStateSupportedTokens } from "./TableSettings/SupportedTokens.js"
import { initialState as initialStateSupportedOldTokens } from "./TableSettings/SupportedOldTokens.js"
import { initialState as initialStateHoldersGrowth } from "./TableSettings/HoldersGrowth.js"

export const tables = {
    "Token Tracker": [
        // "Over&Undervalued Tokens",   
        "💥 Over&Undervalued Tokens (MVRV)",
        // "Assets Holder Dynamics",
        "🌱 Community Growth & Decline",
        "HODL Flows",
        "CEX Flows",
    ],
    "Experimental": [
        "🫀 SOPR Heartbeat"
    ],
    "Supported Assets": [
        "All Assets",
        "Assets with Extended List of Indicators"
    ]
    // "Professional Tracker": [
    //     "SOPR Panel",
    // ]
}

export const tableIndexes = {
    "💥 Over&Undervalued Tokens (MVRV)": {
        shortName: "over-under-valued",
        apiPath: "realised_price",
        columnType: columnsTypeMacro,
        initialSate: initialStateMacro,
    },
    "🌱 Community Growth & Decline": {
        shortName: "holders-growth",
        apiPath: "holders_growth",
        columnType: columnsTypeHoldersGrowth,
        initialSate: initialStateHoldersGrowth,
    },
    "HODL Flows": {
        shortName: "hodl-flows",
        apiPath: "hodler",
        columnType: columnsTypeHODL,
        initialState: initialStateHODL,
    },
    "CEX Flows": {
        shortName: "cex-flows",
        apiPath: "cex",
        columnType: columnsTypeCEX,
        initialStateCEX: initialStateCEX,
    },
    "🫀 SOPR Heartbeat": {
        shortName: "sopr-heartbeat",
        apiPath: "sopr-heartbeat",
        columnType: columnsTypeCEX,
        initialStateCEX: initialStateCEX,
        redirect: '/sopr-heartbeat',
    },
    "All Assets": {
        shortName: "supported-assets",
        apiPath: "supported_tokens",
        // apiPath: "macro",
        columnType: columnsTypeSupportedTokens,
        initialSate: initialStateSupportedTokens,
    },
    "Assets with Extended List of Indicators": {
        shortName: "supported-assets-old",
        apiPath: "supported_tokens_old",
        // apiPath: "macro",
        columnType: columnsTypeSupportedOldTokens,
        initialSate: initialStateSupportedOldTokens,
    },

    // "SOPR Panel": {
    //     shortName: 'sopr-panel',
    //     apiPath: "sopr",
    //     columnType: columnsTypeCEX, 
    //     initialStateCEX: initialStateCEX
    // }
}

export const [shortNames, shortNames2Index] = getShortNameMapping(tables, tableIndexes)
export const indexNames = Object.entries(tables).flatMap(([key, name]) => name)