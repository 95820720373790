import {enUS as en} from 'date-fns/locale';

export function addCommas(nStr, withPoint=true)
{
      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      if(!withPoint) {
          return x1
      }
      return x1 + x2;
  }

export function parseValueForTooltip(value) {
    try {
      if (value >= 10_000) {
        var value_2 = value.toFixed(0)
      }
      else if ((value < 10_000) && (value >= 1_000)) {
        var value_2 = value.toFixed(1)
      }
      else if (((value < 1_000) && (value >= 0.1)) || (value <= 0.)) {
          var value_2 = value.toFixed(2)
      }
      else if ((value >= 0.01) && (value < 0.1)) {
          var value_2 = value.toFixed(3)
      }
      else if ((value >= 0.001) && (value < 0.01)) {
          var value_2 = value.toFixed(4)
      }
      else if ((value >= 0.0001) && (value < 0.001)) {
          var value_2 = value.toFixed(5)
      }
      else if ((value >= 0.00001) && (value < 0.0001)) {
          var value_2 = value.toFixed(6)
      }
      else {
          var value_2 = value.toFixed(7)
      }
      value_2 = value_2.toString()
      value_2 = addCommas(value_2)
      return value_2
    } catch(error) {
      return undefined
    }
}

export function parseBigValues(value) {
  if  (Math.abs(value) >= 1_000_000_000_000) {
    var value_new = `${(value/1_000_000_000_000).toFixed(2)}`
    value_new =  `${addCommas(value_new)}T`
  }
  else if ((Math.abs(value) < 1_000_000_000_000) && (Math.abs(value) >= 10_000_000_000)) {
    var value_new = `${(value/1_000_000_000).toFixed(1)}`
    value_new =  `${addCommas(value_new)}B`
  }
  else if ((Math.abs(value) < 10_000_000_000) && (Math.abs(value) >= 1_000_000_000)) {
    var value_new = `${(value/1_000_000_000).toFixed(2)}`
    value_new =  `${addCommas(value_new)}B`
  }
  else if ((Math.abs(value) < 1_000_000_000) && (Math.abs(value) >= 10_000_000)) {
    var value_new = `${(value/1_000_000).toFixed(1)}`
    value_new =  `${addCommas(value_new)}M`
  }
  else if ((Math.abs(value) < 10_000_000) && (Math.abs(value) >= 1_000_000))  {
    var value_new =  `${(value/1_000_000).toFixed(2)}M`
  }
  else if ((Math.abs(value) < 1_000_000) && (Math.abs(value) >= 10_000)) {
    var value_new =  `${(value/1_000).toFixed(1)}k`
  }
  else if ((Math.abs(value) < 10_000) && (Math.abs(value) >= 1_000)) {
    var value_new =  `${(value/1_000).toFixed(2)}k`
  }
  else {
    try {
      var value_new =  `${(value).toFixed(2)}`
    } catch {
      console.log("ERROR", value)
      var value_new =  `${(value)}`
    }
  }
  return value_new
}

export function titleTooltip(context) {
    var date = context[0].raw.x.split(" ")
    let time = date[1].split(":")
    return `${date[0]+ " " + time[0] + ":" + time[1]}`
}

export function titleTooltipRPD(context) {

  var priceLevel = context[0].raw.y
  var x = context[0].raw.x

  if (!isNaN(x)) {
    priceLevel = parseValueForTooltip(priceLevel)
    return `Price level: $` + priceLevel
  }
  else {
    var date = context[0].raw.x.split(" ")
    let time = date[1].split(":")
    return `${date[0]+ " " + time[0] + ":" + time[1]}`
  }
}

export function labelTooltipRPD(context) {
  let label = context.dataset.label || ''
  console.log(label, context.parsed)
  console.log(context.parsed.x, context.parsed.y)
  
  var y = context.parsed.y
  var x = context.parsed.x

  if (label.toLowerCase().includes("volume")) {
    x = parseIntValueForTooltip(x) 
    label += ': ' + x
  }
  else {
    y = parseValueForTooltip(y) ;
    label += ': $' + y
  }
  return label
}

export function titleLabel(context) {
    let label = context.dataset.label || '';
    if (label) {

      console.log("label", label)

      if (label.startsWith('Supply in') ) {
        label += ': ' +  parseValueForTooltip(context.parsed.y) + '%' ;
      } 
      else if ((label.toLowerCase().includes("supply")) || (label.toLowerCase().includes("number"))) {
        label += ': ' +  parseValueForTooltip(context.parsed.y) ;
      }
      else if ((label.toLowerCase().includes('price')) || (label.startsWith('Net Unrealised')) || (label.toLowerCase().includes('profit')) || (label.toLowerCase().includes('loss')) || ( label.includes('PL'))) {
        let value = parseValueForTooltip(context.parsed.y)
        if (value[0] == "-") {
          label += ': -$' + value.slice(1,)
        }
        else {
          label += ': $' + value
        }
      }
      else if ((label.toLowerCase().startsWith('sopr')) || ((label.toLowerCase().includes('ratio'))) || (label.toLowerCase().includes('volume')) || (label.toLowerCase().includes('flow')) ) {
        label += ': ' +  parseValueForTooltip(context.parsed.y) ;
      }
      else if ((label.toLowerCase().includes('number')) ) {
        label += ': ' +  parseIntValueForTooltip(context.parsed.y) ;
      }
      // else {
      //   label += ': ' +  parseValueForTooltip(context.parsed.y) + '%';
      // }
      else {
        label += ': ' +  parseValueForTooltip(context.parsed.y);
      }
    }
    return label;
  }

export function parseIntValueForTooltip(value) {
    var value_2 = value.toFixed(0)
    value_2 = value_2.toString()
    value_2 = addCommas(value_2)
    return value_2
}

export const scaleX = {
    type: 'time',
    time: {
      displayFormats: {
        'millisecond': 'yyyy-MM-dd HH:mm',
        'second': 'yyyy-MM-dd HH:mm',
        'minute': 'yyyy-MM-dd HH:mm',
        'hour': 'yyyy-MM-dd HH:mm',
        'day': 'yyyy-MM-dd',
        'week': 'yyyy-MM-dd',
        'month': 'yyyy-MM',
        'quarter': 'yyyy-MM',
        'year': 'yyyy',
     }
    },
    adapters: {
      date: {
          locale: en
      }
    },
    grid: {
        display: false
    },
    ticks: {
        font: {
          size: 12, 
          family: 'SFProLight',
        },
        color: '#9AA2AA',
        maxTicksLimit: 7,
        minTicksLimit: 3,
        maxRotation: 0,
        minRotation: 0,
    }
}

export const scalePrice = {
    position: 'right',
    grid: {
        display: false,
    },
    ticks: {
      callback: function(val) {
        return "$" + parseValueForTooltip(val)
      },
      font: {
        size: 12,
        family: 'SFProLight',
      },
      color: '#9AA2AA',
      min: 0,
      max: 100,
    },
    // type: 'logarithmic',
}