import { data, options, dataArea, optionsArea } from "./Chart";
import {options as options_MPL, data as data_MPL} from "./ChartSettings/MPL"
import {options as options_SOPR, data as data_SOPR} from "./ChartSettings/SOPR"
import {options as options_volume, data as data_volume} from "./ChartSettings/OnchainVolume"
import {options as options_tx, data as data_tx} from "./ChartSettings/TxNumber"
import {options as options_CohortFlows, data as data_CohortFlows} from "./ChartSettings/BalanceCohortFlows"
import {options as options_CohortNetlow, data as data_CohortNetlow} from "./ChartSettings/BalanceCohortNetlow"
import {options as options_realised_price, data as data_realised_price} from "./ChartSettings/RealisedPrice"
import {options as options_num_addresses, optionsLimited100 as options_address_waves, data as data_address_waves} from "./ChartSettings/AddressWaves"
import {options as options_hodl_waves, data as data_hodl_waves} from "./ChartSettings/HODLWaves"
import {options as optionsAgeDist, data as dataAgeDist} from "./ChartSettings/AgeDist"
import {options as optionsBalanceCohortDist, data as dataBalanceCohortDist} from "./ChartSettings/BalanceCohortDist"
import {options as optionsCEXBalance, data as dataCEXBalance} from "./ChartSettings/CEXBalance"
import {chartName2Settings} from "./ChartSettings/chartComponent"
import {options as optionsRealisedPriceDist, data as dataRealisedPriceDist} from "./ChartSettings/RealisedPriceDist"

import {options as optionsMVRV, data as dataMVRV} from "./ChartSettings/MVRV"
import {options as optionsMVRVZScore, data as dataMVRVZScore} from "./ChartSettings/MVRVZScore"
import {options as optionsMVRVbyCohort, data as dataMVRVbyCohort} from "./ChartSettings/MVRVbyCohort"
import {options as optionsMVRVbyAge, data as dataMVRVbyAge} from "./ChartSettings/MVRVbyAge"
import {options as optionsAddressGrowth, data as dataAddressGrowth} from "./ChartSettings/AddressGrowth"
import {options as optionsUPL, data as dataUPL} from "./ChartSettings/UPL"
import {options as optionsNetUPL, data as dataNetUPL} from "./ChartSettings/NetUPL"
import {options as optionsSupplyPL, data as dataSupplyPL} from "./ChartSettings/SupplyPL"

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

export function getShortNameMapping(indxs, cryptoIndxs) {
    let shortNames = {}
    let shortNames2Index = {}
    const idxNames = Object.values(indxs).flat()

    idxNames.map((name, i) => {
        const shortName = cryptoIndxs[name].shortName
        shortNames[i] = shortName
        shortNames2Index[shortName] = i
    })

    console.log("SHORT NAMES", shortNames)

    return [shortNames, shortNames2Index]
}

const images = importAll(require.context('../assets/tokenLogos', false, /\.(png|jpe?g|svg)$/));

export const tokensV1toV2 = {
    '1inch': { slugV2: '1inch'},
    'aave': { slugV2: 'aave'},
    'alice': { slugV2: 'myneighboralice'},
    'ankr': { slugV2: 'ankr'},
    'ape': { slugV2: 'apecoin-ape'},
    'arkm': { slugV2: 'arkham'},
    'arpa': { slugV2: 'arpa-chain'},
    'audio': { slugV2: 'audius'},
    'axs': { slugV2: 'axie-infinity'},
    'bal': { slugV2: 'balancer'},
    'blur': { slugV2: 'blur-token'},
    'bnb': { slugV2: 'bnb'},
    'bond': { slugV2: 'barnbridge'},
    'chz': { slugV2: 'chiliz'},
    'comp': { slugV2: 'compound'},
    'cro': { slugV2: 'cronos'},
    'crv': { slugV2: 'curve-dao-token'},
    'cult': { slugV2: 'cult-dao'},
    'cvx': { slugV2: 'convex-finance'},
    'cyber': { slugV2: 'cyberconnect'},
    'dodo': { slugV2: 'dodo'},
    'dydx': { slugV2: 'dydx-ethdydx'},
    'enj': { slugV2: 'enjin-coin'},
    'ens': { slugV2: 'ethereum-name-service'},
    'floki': { slugV2: 'floki-inu'},
    'ftm': { slugV2: 'fantom'},
    'ftt': { slugV2: 'ftx-token'},
    'fxs': { slugV2: 'frax-share'},
    'gmt': { slugV2: 'green-metaverse-token'},
    'gno': { slugV2: 'gnosis-gno'},
    'grt': { slugV2: 'the-graph'},
    'gt': { slugV2: 'gatetoken'},
    'ilv': { slugV2: 'illuvium'},
    'imx': { slugV2: 'immutable-x'},
    'inj': { slugV2: 'injective'},
    'jasmy': { slugV2: 'jasmy'},
    'ladys': { slugV2: 'milady-meme-coin'},
    'ldo': { slugV2: 'lido-dao'},
    'lina': { slugV2: 'linear'},
    'link': { slugV2: 'chainlink'},
    'looks': { slugV2: 'looksrare'},
    'lrc': { slugV2: 'loopring'},
    'mask': { slugV2: 'mask-network'},
    'matic': { slugV2: 'polygon'},
    'pendle': { slugV2: 'pendle'},
    'pepe': { slugV2: 'pepe'},
    'pooh': { slugV2: 'pooh'},
    'qnt': { slugV2: 'quant'},
    'rfd': { slugV2: 'refund'},
    'rndr': { slugV2: 'render'},
    'sand': { slugV2: 'the-sandbox'},
    'shib': { slugV2: 'shiba-inu'},
    'ssv': { slugV2: 'ssv-network'},
    'storj': { slugV2: 'storj'},
    'sushi': { slugV2: 'sushiswap'},
    'tru': { slugV2: 'truefi-token'},
    'turbo': { slugV2: 'turbo'},
    'uni': { slugV2: 'uniswap'},
    'vra': { slugV2: 'verasity'},
    'wojak': { slugV2: 'wojak'},
    'woo': { slugV2: 'wootrade'},
    'yfi': { slugV2: 'yearn-finance'},
    'ygg': { slugV2: 'yield-guild-games'},
    'zrx': { slugV2: '0x'},
}

export const tokens = {
    // "arb": {
    //     name: "Arbitrum (ARB)",
    //     img: images["arb.png"],
    //     chainscan_url: "https://arbiscan.io/",
    // },
    "1inch": {
        name: "1inch Network (1INCH)",
        img: images["1inch.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "aave": {
        name: "Aave (AAVE)",
        img: images["aave.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "alice": {
        name: "My Neighbor Alice (ALICE)",
        img: images["alice.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "ankr": {
        name: "Ankr (ANKR)",
        img: images["ankr.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "ape": {
        name: "ApeCoin (APE)",
        img: images["ape.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "arkm": {
        name: "Arkham (ARKM)",
        img: images["arkm.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "arpa": {
        name: "ARPA (ARPA)",
        img: images["arpa.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "audio": {
        name: "Audius (AUDIO)",
        img: images["audio.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "axs": {
        name: "Axie Infinity (AXS)",
        img: images["axs.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "bal": {
        name: "Balancer (BAL)",
        img: images["bal.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "blur": {
        name: "Blur (BLUR)",
        img: images["blur.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "bnb" : {
        name: "Binance (BNB)",
        img: images["bnb.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "bond" : {
        name: "BarnBridge (BOND)",
        img: images["bond.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "chz": {
        name: "Chiliz (CHZ)",
        img: images["chz.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "comp": {
        name: "Compound (COMP)",
        img: images["comp.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "cro": {
        name: "Crypto.com (CRO)",
        img: images["cro.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "crv": {
        name: "Curve (CRV)",
        img: images["curve.png"],
        chainscan_url: "https://etherscan.io/"    
    },
    "cult": {
        name: "Cult DAO (CULT)",
        img: images["cult.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "cvx": {
        name: "Convex Finance (CVX)",
        img: images["cvx.png"],
        chainscan_url: "https://etherscan.io/"    
    },
    "cyber": {
        name: "CyberConnect (CYBER)",
        img: images["cyber.png"],
        chainscan_url: "https://etherscan.io/"    
    },
    "dodo": {
        name: "DODO (DODO)",
        img: images["dodo.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "dydx": {
        name: "dYdX Exchange (DYDX)",
        img: images["dydx.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "enj": {
        name: "Enjin Coin (ENJ)",
        img: images["enj.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "ens": {
        name: "Ethereum Name Service (ENS)",
        img: images["ens.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "floki": {
        name: "Floki (FLOKI)",
        img: images["floki.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "ftm": {
        name: "Fantom (FTM)",
        img: images["ftm.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "ftt" : {
        name: "FTX (FTT)",
        img: images["ftt.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "fxs": {
        name: "Frax Share (FXS)",
        img: images["fxs.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "imx": {
        name: "Immutable X (IMX)",
        img: images["imx.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "gala": {
        name: "Gala (GALA)",
        img: images["gala.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "gmt": {
        name: "STEPN (GMT)",
        img: images["gmt.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "gno": {
        name: "Gnosis (GNO)",
        img: images["gno.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "grt": {
        name: "The Graph (GRT)",
        img: images["grt.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "gt": {
        name: "GateToken (GT)",
        img: images["gt.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "ilv": {
        name: "Illuvium (ILV)",
        img: images["ilv.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "inj": {
        name: "Injective (INJ)",
        img: images["inj.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "jasmy": {
        name: "JasmyCoin (JASMY)",
        img: images["jasmy.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "ladys": {
        name: "Milady Meme Coin (LADYS)",
        img: images["ladys.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "ldo": {
        name: "Lido DAO (LDO)",
        img: images["ldo.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "lina": {
        name: "Linear Finance (LINA)",
        img: images["lina.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "link": {
        name: "Chainlink (LINK)",
        img: images["link.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "looks": {
        name: "Looksrare (LOOKS)",
        img: images["looks.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "lrc": {
        name: "Loopring V2 (LRC)",
        img: images["lrc.png"],
        chainscan_url: "https://etherscan.io/",
    },
    // "magic": {
    //     name: "Magic (MAGIC)",
    //     img: images["magic.png"],
    //     chainscan_url: "https://etherscan.io/",
    // },
    "mask": {
        name: "Mask Network (MASK)",
        img: images["mask.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "matic" : {
        name: "Polygon (MATIC)",
        img: images["matic.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "ocean": {
        name: "Ocean Protocol (OCEAN)",
        img: images["ocean.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "pendle": {
        name: "Pendle (PENDLE)",
        img: images["pendle.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "pepe": {
        name: "Pepe (PEPE)",
        img: images["pepe.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "pooh": {
        name: "Pooh (POOH)",
        img: images["pooh.png"],
        chainscan_url: "https://etherscan.io/"
    },
    
    "qnt": {
        name: "Quant (QNT)",
        img: images["qnt.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "rfd": {
        name: "RefundCoin (RFD)",
        img: images["rfd.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "rndr": {
        name: "Render Token (RNDR)",
        img: images["rndr.png"],
        chainscan_url: "https://etherscan.io/",
    },
    // "rdnt": {
    //     name: "Radiant Capital (RDNT)",
    //     img: images["rdnt.png"],
    //     chainscan_url: "https://arbiscan.io/",
    // },
    "sand": {
        name: "Sandbox (SAND)",
        img: images["sand.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "shib" : {
        name: "Shiba Inu (SHIB)",
        img: images["shiba.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "ssv": {
        name: "ssv.network (SSV)",
        img: images["ssv.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "storj": {
        name: "Storj (STORJ)",
        img: images["storj.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "sushi" : {
        name: "SushiSwap (SUSHI)",
        img: images["sushi.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "tru": {
        name: "TrueFi (TRU)",
        img: images["tru.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "turbo": {
        name: "Turbo (TURBO)",
        img: images["turbo.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "uni" : {
        name: "UniSwap (UNI)",
        img: images["uniswap.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "vra": {
        name: "Verasity (VRA)",
        img: images["vra.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "wojak": {
        name: "Wojak (WOJAK)",
        img: images["wojak.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "woo": {
        name: "WOO Network (WOO)",
        img: images["woo.png"],
        chainscan_url: "https://etherscan.io/"
    },
    "yfi": {
        name: "yearn.finance (YFI)",
        img: images["yfi.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "ygg": {
        name: "Yield Guild Games (YGG)",
        img: images["ygg.png"],
        chainscan_url: "https://etherscan.io/",
    },
    "zrx": {
        name: "0x (ZRX)",
        img: images["zrx.png"],
        chainscan_url: "https://etherscan.io/",
    },
    // "btc": {
    //     name: "Bitcoin (BTC)",
    //     img: images["btc.png"],
    //     showingIndexes: [1, 2]
    // }
}


export const indexesV1 = {
    "Most viewed": [
        "Overview",
        "Realised Profits & Losses",
        "SOPR",
        "Total Transaction Volume",
        "Realised Price",
        // "Realised Capitalization", 
        "CEX Inflow & Outflow",
    ],
    // "💥MVRV💥 Overbought of Oversold?": [
    //     "MVRV",
    //     "MVRV Z-Score",
    // ],
    "Realised Profits & Losses": [

        "Realised Profits & Losses", 
        "Net Realised Profits & Losses",
        "Realised Profits & Losses - Cohorts Distribution",
        "Realised Profit / Loss Ratio",
        "SOPR",
        "SOPR in Profit / Loss", //6]
    ],
    "Transaction Volume in Profit/Loss": [

        "Volume in Profit / Loss",
        // "Volume in Net Profit / Loss",
        "Volume in Profit / Loss - Cohorts Distribution",
        "Volume in Profit / Loss Ratio",
    ],
    "Transactions": [
        "Total Transaction Volume",
        "Average Transaction Volume",
        "Number of Transactions",
        // "Number of Active Addresses",
        "Balance Cohort Inflow & Outflow",
        // "Balance Cohort Inflow & Outflow - Cohorts Distribution",
        "Balance Cohort Netflow",
        "Moved Coins Realised Price",
        "Moved Coins Age Distribution", //14
    ],
    "Unrealised Profits & Losses": [
        
        "Unrealised Profits & Losses",
        "Unrealised Profits & Losses - Cohorts Distribution",
        "Supply in Profit / Loss",
        "Supply in Profit / Loss - Cohorts Distribution",
        "Number of Addresses in Profit / Loss", //20
    ],
    "Macro": [
        "Number of Addresses",
        "Number of Addresses - Cohorts Distribution",
        "Change in Number of Addresses",
        "Realised Price",
        "Realised Price - Cohorts Distribution",
        // "Realised Capitalization", //23
        "HODL Waves", 
        "Realised Cap HODL Waves",
        "Balance Cohort Waves", 
        "Realised Cap Balance Cohort Waves", 
    ],
    "Exchanges": [
        "CEX Reserve",
        "CEX Inflow & Outflow",
        "CEX Netflow",
        "CEX Inflow & Outflow - Cohorts Distribution",
        // "CEX Inflow & Outflow by Balance Cohort",
        "CEX Netflow by Balance Cohort",
        // "CEX Inflow Age Distribution",
        // "CEX Inflow Balance Cohort Distribution",
        
    ],
    "Smart Contracts": [
        "SC Volume Locked",
        "SC Inflow & Outflow",
        "SC Netflow",
    ],
}

export const indexesV2 = {
    "Most viewed": [
        "Realised Profits & Losses",
        "SOPR",
        "SOPR in Profit / Loss",
        "Total Transaction Volume",
        "Realised Price",
        // "Realised Capitalization", 
    ],
    "💥 MVRV: Overbought of Oversold?": [
        "MVRV",
        "MVRV Z-Score",
        // "MVRV by Wallet Size",
        // "MVRV by Wallet Size ($)",
        // "MVRV by Age",
    ],
    // "🌱Holder Trends🌱 Growth or Exit?": [
    // "🌱Community Trends🌱 Growth or Decline?": [
    "🌱 Community Trends": [
    // "🌱Community Activity🌱": [
        // "Holders Growth & Total Count"
        // "Holders Count: Growth or Decline?"
        "Holders Count & Growth"
    ],
    "💸 Unrealised P&L: Potential Market Movement": [
        "Unrealised Profit/Loss",
        "Unrealised Profit/Loss Difference",
        // "Relative Unrealised Profit/Loss",
        // "NUPL Indicator",
    ],
    "💰Supply Distribution: Market Health": [
        "Supply in Profit/Loss",
    ]
}

const SOPRPLTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": ["users", "total"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const SOPRTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Wallet Cohort": ["users", "total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const SOPRToolsV2 = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const MPLTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["$",],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Wallet Cohort": ["users", "total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const MPLToolsV2 = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["$",],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const MPLVolumeTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token"],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Wallet Cohort": ["users", "total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const MPLCohortsTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Metric": ["profits", "losses"],
    "Equivalent": ["$"],
    "Cohort type": ["balance_dollar", "balance", "age"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const VolumePLCohortsTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Metric": ["profits", "losses"],
    "Equivalent": ["token"],
    "Cohort type": ["balance_dollar", "balance", "age"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}

const CEXFlowsCohortsTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    // "Wallet Cohort": ["All", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", "<1d", 
    //             "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y"],
    "Metric": ["inflow", "outflow",],
    "Equivalent": ["token", "$"],
    "Cohort type": ["balance_dollar", "balance", "age"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],          
}



const UPLTools = {
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": ["users", "total", "cex", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],        
}

const UPLCohortsTools = {
    "Price Scale": ["linear", "log"],
    "Metric": ["unrealised_profits", "unrealised_losses"],
    "Cohort type": ["balance_dollar", "balance", "age"],         
}

const RealisedPriceCohortsTools = {
    "Price Scale": ["linear", "log"],
    "Cohort type": ["balance_dollar", "balance", "age"],         
}

const supplyPLTools = {
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "%"], 
    "Wallet Cohort": ["users", "total", "cex", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
            "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
            "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
            "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"], 
        
}
const numberOfAddressesPLTools = {
    "Price Scale": ["linear", "log"],
    "Equivalent": ["#", "%"], 
    "Wallet Cohort": ["users", "total", "cex", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
            "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
            "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
            "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"], 
        
}

const supplyPLCohortsTools = {
    "Price Scale": ["linear", "log"],
    "Metric": ["supply_profits", "supply_losses"],
    "Cohort type": ["balance_dollar", "balance", "age"],         
}

const onchainVolumeTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": ["total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
            "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
            "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
            "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    "Equivalent": ["token", "$"],
    // "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],   
}

const numberOfTransactionsTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Sample": ["all", "$0-100", "$100-1k", "$1k-10k", "$10k-50k", "$50k-100k", "$100k-250k", "$250k-500k", "$500k-1m",
                "$1m-2.5m", "$2.5m-5m", "$5m-7.5m", "$7.5m-10m", "$10m-15m", "$15m-20m", "$20m+"],
}

const numberActiveAddresses = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Address Type": ["all", "sender", "receiver"],
}

const averageTransactionVolume = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "$"],
}

const balanceCohortInflowOutflowTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": [
        "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
    ],
    "Equivalent": ["token", "$",],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"], 
}

const realisedPriceTools = {
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": [
        "users", "total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"
    ], 
}

const realisedPriceToolsV2 = {
    "Price Scale": ["linear", "log"],
}

const onchainVolumeToolsV2 = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": ["total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    "Equivalent": ["token", "$"],
    // "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],   
}

const realisedPriceDistTools = {
    "Resolution": ["day"],
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": [
        "users", "total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"
    ], 
}

const addressWavesTools = {
    "Cohort type": ["balance_dollar", "balance"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["%"],
}

const hodlWavesTools = {
    "Cohort type": ["age"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["%"],
}

const spentCoinsRealisedPriceTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": [
        "users", "total", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"
    ], 
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],    
}

const spentCoinsAgeDistTools = {
    "Cohort type": ["age"],
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "$"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],    
}

const CEXBalanceTools = {
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "$"],
    "CEX": [
        "all", "ascendex", "bibox", "bilaxy", "binance", "bitmart", "bitfinex", "bithumb", 
        "bitrue", "bitstamp", "bittrex", "bitzlato", "bw.com", "crex24", "changelly", 
        "coinexchange.io", "coinbase", "coinbene", "coinex", "coinone", "crypto.com", 
        "cryptopia", "exmo", "fcoin", "ftx", "ftx_us", "gate.io", "gemini", "hitbtc", 
        "hoo.com", "hotbit", "huobi", "indodax", "kraken", "kucoin", "kuna.io", "liqui.io", 
        "luno", "mexc", "maicoin", "mercatox", "okex", "paribu", "poloniex", "remitano", 
        "shapeshift", "upbit", "whitebit", "yobit.net", "yunbi", "zb.com", "other",
    ],
//     "CEX": [
//         'all', 'abcc', 'ascendex', 'bgogo', 'binance', 'bitmex', 'bitmart', 'bitfinex',
//         'bithumb', 'bitstamp', 'bittrex', 'coss.io', 'crex24', 'cobinhood', 'coinmetro',
//         'coinhako', 'coinone', 'compromised', 'crypto.com', 'derivatives', 'eth2_depositor',
//         'ftx', 'fiat_gateway', 'gbx', 'gate.io', 'gemini', 'hitbtc', 'hoo.com', 'hot_wallet',
//         'hotbit', 'huobi', 'kraken', 'kryptono', 'kucoin', 'latoken', 'liquid', 'okex', 'otc', 
//         'options_trading',  'paribu', 'poloniex', 'remitano', 'shapeshift', 'streamity',
//         'tagz', 'tidex', 'topbtc', 'trade.io', 'upbit', 'yunbi', 'zb.com'
//     ],
}

const CEXInflowOutflowTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "$"],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"], 
    "CEX": [
        "all", "ascendex", "bibox", "bilaxy", "binance", "bitmart", "bitfinex", "bithumb", 
        "bitrue", "bitstamp", "bittrex", "bitzlato", "bw.com", "crex24", "changelly", 
        "coinexchange.io", "coinbase", "coinbene", "coinex", "coinone", "crypto.com", 
        "cryptopia", "exmo", "fcoin", "ftx", "ftx_us", "gate.io", "gemini", "hitbtc", 
        "hoo.com", "hotbit", "huobi", "indodax", "kraken", "kucoin", "kuna.io", "liqui.io", 
        "luno", "mexc", "maicoin", "mercatox", "okex", "paribu", "poloniex", "remitano", 
        "shapeshift", "upbit", "whitebit", "yobit.net", "yunbi", "zb.com", "other",
    ],
    // [
    //     'all', 'abcc', 'ascendex', 'bgogo', 'binance', 'bitmex', 'bitmart', 'bitfinex',
    //     'bithumb', 'bitstamp', 'bittrex', 'coss.io', 'crex24', 'cobinhood', 'coinmetro',
    //     'coinhako', 'coinone', 'compromised', 'crypto.com', 'derivatives', 'eth2_depositor',
    //     'ftx', 'fiat_gateway', 'gbx', 'gate.io', 'gemini', 'hitbtc', 'hoo.com', 'hot_wallet',
    //     'hotbit', 'huobi', 'kraken', 'kryptono', 'kucoin', 'latoken', 'liquid', 'okex', 'otc', 
    //     'options_trading',  'paribu', 'poloniex', 'remitano', 'shapeshift', 'streamity',
    //     'tagz', 'tidex', 'topbtc', 'trade.io', 'upbit', 'yunbi', 'zb.com'
    // ],
}

const CEXInflowAgeDistTools = {
    "Resolution": ["day", "hour"],
    "Equivalent": ["token", "$"],
    "Cohort type": ["age"],
    "Metric": ["inflow"],
}

const CEXInflowBalanceCohortDistTools = {
    "Resolution": ["day", "hour"],
    "Equivalent": ["token", "$"],
    "Cohort type": ["balance_dollar"],
    "Metric": ["inflow"],
}

const CEXNetflowByBalanceCohortTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "$", ], 
    "Wallet Cohort": [
        "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+",
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
    ],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],
}

const TVLTools = {
    "Equivalent": ["token", "$", ], 
    "Price Scale": ["linear", "log"],
}

const SCFlowsTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token", "$", ], 
    "Wallet Cohort": [
        "all",
        "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+",
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
    ],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],
}

const MPLRatioTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["$"], 
    "Wallet Cohort": [
        "total", "users",
        "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+",
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"
    ],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],
}

const VolumePLRatioTools = {
    "Resolution": ["day", "hour"],
    "Price Scale": ["linear", "log"],
    "Equivalent": ["token"], 
    "Wallet Cohort": [
        "total", "users",
        "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+",
        "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
        "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
        "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"
    ],
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],
}

const numberOfAddressesTools = {
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": ["total", "users", "cex", "sc", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
            "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
            "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
            "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],      
}

const changeNumberOfAddressesTools = {
    "Price Scale": ["linear", "log"],
    "Wallet Cohort": ["total", "users", "cex", "sc", "$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 
            "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3",
            "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
            "<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],   
    "Moving average type": ["mean", "median"],
    "Moving average": ["1", "3", "5", "7", "14", "30", "45", "60"],    
}

const MacroByWalletSizeV2 = {
    "Price Scale": ["linear", "log"],
    "Wallet Size": ["🦠 plankton", "🍤 shrimps", "🦀 crabs", 
            "🐙 octopuses", "🐟 fishs", "🐬 dolphins", "🦈 sharks", "🐳 whales", "🐋 megawhales"],   
}
const MacroByDollarWalletSizeV2 = {
    "Price Scale": ["linear", "log"],
    "Wallet Size": ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],   
}
const MacroByAgeV2 = {
    "Price Scale": ["linear", "log"],
    "Wallet Age": ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", "7y-10y", "10y+"],   
}

const SuperMacro = {
    "Price Scale": ["linear", "log"],
    // "Resolution": ["day", "week", "month"],
    "Resolution": ["month", "week", "day"],
}

export var cryptoIndexesV2 = {
    "Unrealised Profit/Loss": {
        type: "Macro",
        shortName: "upl",
        typeChart: "line",
        typeData: dataUPL,
        typeOptions: optionsUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'Unrealised P&L (Profit and Loss) shows how much investors could gain or lose if they sold their assets at the current price. It’s “unrealised” because the assets haven’t been sold yet — profits or losses exist only in theory. However, this powerful indicator provides a deep insight into market sentiment and investor behavior, offering valuable clues about future market movements.💡',
            ]],
            [true, 'Why does it matter? 🔍'],
            [false, [
                'Unrealised P&L reveals the emotional state of the market and helps predict investor behavior:',
                ' 🟩 When Unrealised Profits > Unrealised Losses (UP > UL): The majority of investors are in profit, increasing the likelihood of selling pressure as they look to cash out.',
                ' 🟥 When Unrealised Losses > Unrealised Profits (UL > UP): Most investors are at a loss, often choosing to hold and wait for a recovery instead of selling.',
                'These dynamics create an observable tension between profit-taking and hesitation, driving market movements.',
            ]],
            [true, 'Selling Pressure: A Key Indicator 💸'],
            [false, [
                'Periods of high Unrealised Profits signal elevated risk of sell-offs, which can cascade into price corrections. On the other hand, persistently high Unrealised Losses highlight a "wait-and-see" attitude, potentially suppressing market activity but building pressure for future volatility.',
            ]],
            [true, 'How does Unrealised P&L complement Realised Price? 🧩'],
            [false, [
                'While Realised Price (RP) shows the average acquisition cost of assets across the market, it lacks context about how much unrealised profit or pain exists at current prices. Unrealised P&L fills this gap by quantifying the emotional stakes:',
                ' ▫️ If prices hover near or below RP, significant Unrealised Losses suggest a market under stress, where large groups of investors are unlikely to sell, potentially creating illiquidity.',
                ' ▫️ Conversely, prices far above RP often reflect substantial Unrealised Profits, signaling opportunities for profit-taking waves that may lead to corrections',
                'This combination allows traders to assess not just the market’s cost basis, but also its breaking points—where pressure to act becomes too great to ignore.'
            ]],
        ],
    },
    "Unrealised Profit/Loss Difference": {
        type: "Macro",
        shortName: "net-upl",
        typeChart: "line",
        typeData: dataNetUPL,
        typeOptions: optionsNetUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'The Unrealised Profit/Loss Difference shows the gap between Unrealised Profits (UP) and Unrealised Losses (UL). It provides a quick view of whether the market is more inclined to take profits or hold out for better prices.',
            ]],
            [true, 'How is it calculated?'],
            [false, [
                'It’s the difference between Unrealised Profits and Unrealised Losses. Alternatively, it can be seen as the difference between the market price and the Realised Price, multiplied by the on-chain circulating supply.',
            ]],

            [true, 'Why does it matter? 🔍'],
            [false, [
                ' 🟩 Positive Difference: When Unrealised Profits are higher, it signals that investors may start selling, creating selling pressure.',
                ' 🟥 Negative Difference: If Unrealised Losses outweigh Profits, investors tend to hold, waiting for a potential recovery.',
                'This difference helps traders understand market behavior—whether investors are more likely to sell or wait.',
            ]],
            
        ],
    },
    "Relative Unrealised Profit/Loss": {
        type: "Macro",
        shortName: "relative-upl",
        typeChart: "line",
        typeData: dataUPL,
        typeOptions: optionsUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [],
    },
    "NUPL Indicator": {
        type: "Macro",
        shortName: "nupl",
        typeChart: "line",
        typeData: dataUPL,
        typeOptions: optionsUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [],
    },
    "Supply in Profit/Loss": {
        type: "Macro",
        shortName: "supply-pl",
        typeChart: "line",
        typeData: dataSupplyPL,
        typeOptions: optionsSupplyPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'Supply in P/L represents the portion of the circulating supply that is either in profit or loss based on the difference between the current market price and the Realised Price. It shows how much of the total supply is exposed to potential profit-taking or loss-holding decisions.'
            ]],
            [true, 'Key Insights'],
            [false, [
                ' 🚨 Shows the percentage of circulating supply at risk of being sold. A larger portion of the supply in profit increases the likelihood of selling, creating selling pressure. Conversely, a significant portion in loss suggests investors are less likely to sell, leading to potential market stability.',
                ' 🟩 High Supply in Profit: When a significant portion of the circulating supply is in profit, the potential for selling pressure rises. This can lead to price corrections and higher market liquidity.',
                ' 🟥 High Supply in Loss: When most of the circulating supply is in a loss, investors tend to hold their assets, contributing to short-term market stability. However, it can also signal market stress, slowing recovery.',
            ]],
        ],
    },

    "Holders Count & Growth": {
        type: "Macro",
        shortName: "number-addresses-change",
        typeChart: "line",
        typeData: dataAddressGrowth,
        typeOptions: optionsAddressGrowth,
        tools: SuperMacro,
        showTools: true,
        description: [
            [false, [
                'This chart tracks the number of blockchain addresses holding a specific altcoin and how it changes over time, providing valuable insights into market sentiment, adoption trends, and the overall health of the asset.',
            ]],
            [true, [
                'What does this on-chain indicator track?',
            ]],
            [false, [
                ' 1️⃣ Total Holders: The total number of blockchain addresses holding the asset (non-zero balance).',
                ' 2️⃣ Holders Growth: Daily, weekly, and monthly changes in the number of holders over time.'
            ]],
            [true, 'How to interpret it for investments?'],
            [false, [
                ' 🚀 Rising growth: An increase in holders can indicate growing interest and adoption, especially for new assets.',
                ' 🟢 High total holders: A large, stable holder base often signals market confidence and healthier distribution.',
                ' 🔻 Declining growth or total: If holder numbers drop, it may mean tokens are consolidating in fewer wallets, potentially on exchanges — a sign of selling pressure.',

            ]],
            [true, 'Why is this important?'],
            [false, [
                '📊 The Holders Growth chart helps you gauge market sentiment and identify key trends in asset adoption. It’s a powerful tool to assess the health of a project and adjust your strategy accordingly.',
            ]],
        ],
    },
    "MVRV by Age": {
        type: "Transactions",
        shortName: "mvrv-by-age",
        typeChart: "line",
        typeData: dataMVRVbyAge,
        typeOptions: optionsMVRVbyAge,
        tools: MacroByAgeV2,
        showTools: true,
        description:  [
            [false, [
                'MVRV by Age is an enhanced version of the Market Value to Realized Value (MVRV) metric, which compares the market capitalization (current market value) to realized capitalization (the value when the coins last moved). Unlike the standard MVRV, this metric segments wallets into age cohorts, allowing for a more detailed analysis of how investor behavior changes based on how long they have held their assets.',
            ]],
            [true, 'How is Wallet Age defined?'],
            [false, [
                'Wallet age refers to the time elapsed since the last movement of a token. The longer a coin has been held, the older it is considered. Tokens that have recently changed hands fall into the "younger" category, while those that have been held for a longer time are considered "older."',
            ]],
            [true, 'How to use the MVRV by Age metric?'],
            [false, [
                ' 📊 Analyze Investor Behavior: Understand how the market and realized values differ for tokens of different ages — older tokens may represent long-term investments, while younger tokens may indicate more active trading.',
            ]],
        ],
    },
    "MVRV by Wallet Size ($)": {
        type: "Transactions",
        shortName: "mvrv-by-wallet-size-dollar",
        typeChart: "line",
        typeData: dataMVRVbyCohort,
        typeOptions: optionsMVRVbyCohort,
        tools: MacroByDollarWalletSizeV2,
        showTools: true,
        description: [
            [false, [
                'MVRV (Market Value to Realized Value) is a crucial on-chain metric used to assess whether a cryptocurrency is overvalued or undervalued. It compares the market capitalization (current value based on the price) to the realized capitalization (the value when the coins last moved). This helps to understand if the market price is higher or lower than the "fair value" of the asset.',
                'The MVRV by Wallet Size ($) metric takes the traditional MVRV and further segments it by wallet sizes, but instead of using the number of tokens, it categorizes wallets based on their value in dollars. This allows for a more granular analysis of how different wallet groups (ranging from small retail investors to large whales) are holding their assets in terms of USD.',
            ]],
            [true, 'How do we define Wallet Size ($)?'],
            [false, [
                'Wallets are grouped based on the USD value of the tokens they hold:',
                ' ▫️ $0-100: Wallets with a balance worth between $0 and $100.',
                ' ▫️ $100-1,000: Wallets with a balance worth between $100 and $1,000.',
                ' ▫️ $1,000-10,000: Wallets with a balance worth between $1,000 and $10,000, and so on.',
                'It`s important to note that as the price of a token changes, addresses may move between these categories, even if no transaction has occurred.',
            ]],
            [true, 'How to use the MVRV by Wallet Size ($) metric?'],
            [false, [
                ' 📊 Track investor behavior: See if large investors (whales) are holding assets at a higher value compared to smaller investors (retail).',
                ' 💡 Identify potential market imbalances: Large discrepancies between wallet sizes can indicate whether certain investor groups are overexposed or underexposed.',
                ' 🔍 Spot trends: Look for unusual MVRV values in specific wallet categories to detect possible price corrections or market shifts.',
            ]],
        ],
    },
    "MVRV by Wallet Size": {
        type: "Transactions",
        shortName: "mvrv-by-wallet-size",
        typeChart: "line",
        typeData: dataMVRVbyCohort,
        typeOptions: optionsMVRVbyCohort,
        tools: MacroByWalletSizeV2,
        showTools: true,
        description: [
            [false, [
                'MVRV (Market Value to Realized Value) is a key on-chain metric that helps evaluate whether a cryptocurrency is overvalued or undervalued relative to its "fair value." It compares the market capitalization (the current value based on the price) to the realized capitalization (the value based on the price when the coins last moved).',
                'The MVRV by Wallet Size metric takes this concept a step further by segmenting the data based on wallet sizes. It provides a deeper understanding of how different investor classes, such as whales and retail investors, are holding their assets relative to the token’s market value and realized value.',
            ]],
            [true, 'How do we define Wallet Size?'],
            [false, [
                'Wallet size is determined by the amount of a specific token held by each address:',
                ' 🦠 plankton: Addresses holding very small amounts (e.g., for WETH, balances > 0 and <= 0.1 WETH).',
                ' 🐋 megawhales: Addresses with large amounts (e.g., for WETH, balances > 1,000,000 WETH).',
                'This categorization is tailored to each token based on its total supply and average token price, providing a precise classification relevant to each specific token.',
            ]],
            [true, 'How to use the MVRV by Wallet Size metric?'],
            [false, [
                ' 📊 Track investor behavior: See if large investors (whales) are holding assets at a higher value compared to smaller investors (retail).',
                ' 💡 Identify potential market imbalances: Large discrepancies between wallet sizes can indicate whether certain investor groups are overexposed or underexposed.',
                ' 🔍 Spot trends: Look for unusual MVRV values in specific wallet categories to detect possible price corrections or market shifts.',
            ]],
        ],
    },
    "MVRV": {
        type: "Transactions",
        shortName: "mvrv",
        typeChart: "line",
        typeData: dataMVRV,
        typeOptions: optionsMVRV,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'MVRV (Market Value to Realized Value) is a metric that compares the market price of an altcoin to its realized price — the price at which each token was last moved.',
            ]],
            [true, 'How to use MVRV in investment?'],
            [false, [
                'For altcoins, the MVRV chart helps spot overbought or oversold conditions:',
                ' 🟥 MVRV >> 1: The altcoin is likely overvalued, and a price correction could be coming,',
                ' 🟨 MVRV = 1: The market price matches the realized price, indicating a balanced market,',
                ' 🟩 MVRV << 1: The altcoin is likely undervalued, presenting a potential buying opportunity.',
                'This metric helps assess market cycles and make more informed trading decisions based on the relationship between market and realized prices.',
            ]],
        ],
    },
    "MVRV Z-Score": {
        type: "Transactions",
        shortName: "mvrv-z-score",
        typeChart: "line",
        typeData: dataMVRVZScore,
        typeOptions: optionsMVRVZScore,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'The MVRV Z-score is an on-chain metric that helps assess whether a cryptocurrency is overvalued or undervalued relative to its "fair value." The MVRV Z-score is calculated by taking the difference between the market capitalization and the realized capitalization, then dividing it by the standard deviation of the market capitalization, i.e., (market cap – realized cap) / std(market cap).',
                'Compared to the regular MVRV, the MVRV Z-score gives a clearer picture by adjusting for the token’s unique behavior. It takes into account how much the price usually moves away from its "fair value" over time and uses this to make the metric more accurate and easier to interpret.',

            ]],
            [true, 'How to Use MVRV Z-Score in Investment?'],
            [false, [
                ' 🟥 MVRV Z-Score >> 0: It typically indicates that the market is in an overbought state.  This means that the market capitalization is significantly higher than the realized capitalization relative to historical averages for this specific token. For traders and investors, this suggests that the asset may be overvalued, and a price correction could follow. It`s often seen as a signal to reduce exposure or take profits.',
                ' 🟨 MVRV Z-Score = 0: A Z-score near 0 indicates that the market capitalization is in line with the realized capitalization, meaning the market is fairly valued.',
                ' 🟩 MVRV Z-Score << 0: It indicates that the market is undervalued, meaning the market capitalization is lower than usual compared to the realized capitalization. This could signal a potential buying opportunity, as the price may be too low relative to the token`s historical value. It could suggest that the market is oversold, and a price rebound may be coming.',
            ]],
        ],
    },
    "SOPR in Profit / Loss": {
        type: "Realesed Profits & Losses",
        shortName: "sopr-pl",
        typeChart: "line",
        typeData: chartName2Settings["SOPRPL"]["data"],
        typeOptions: chartName2Settings["SOPRPL"]["options"],
        tools: SOPRToolsV2,
        showTools: true,
        description: [
            [false, [
                'SOPR (Spent Output Profit Ratio) - one of the market indicators that reflects whether coins are currently being sold at a profit or loss.',
                'SOPR is calculated as follows:',
                ' 1. Consider the coins sent on-chain during the time period under consideration.',
                ' 2. Take their total value at the time of sending.',
                ' 3. Divide by their total value at the time of receiving. ',
                'Thus, SOPR is equal to the ratio of the sale value of the traded coins to their value when they were purchased.',
            ]],
            [true, 'SOPR in Profit / Loss'],
            [false, ['SOPR in Profit / Loss are two SOPR metrics calculated only for coins that are currently trading in profit and loss respectively. These metrics allow you to understand how much the cost of selling coins differs from the cost of buying them separately for profitable and losing coins.']],
        ],
    },
    "Total Transaction Volume": {
        type: "Transactions",
        shortName: "total-tx-volume",
        typeChart: "bar",
        typeData: chartName2Settings["Total Transaction Volume"]["data"],
        typeOptions: chartName2Settings["Total Transaction Volume"]["options"],
        tools: onchainVolumeToolsV2,
        showTools: true,
        description: [
            [false, [`Total Transaction Volumes represents the total volume of coins traded within the blockchain over the time-frame considered.`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in metric values. Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ dollar or native token equivalent. '
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                ' ▫️ Identifying "fair" price levels. A local peak in the on-chain volume indicates that the current price is considered fair by a large number of market participants and that they are willing to exchange coins at that price. If by moving the price from this level, the on-chain volume drops sharply, it can lead to a rebound in price and a return to the \"fair\" value. ',
                ' ▫️ This indecator is good to use in conjunction with the Transaction number metric. Using both metrics, you can determine how many market participants traded at a given price and in what volume, as well as the average transaction volume. This will avoid the error of determining a fair price level when a large amount of capital has been moved by a small number of participants.',
                ' ▫️ Identifying the age of the coins being traded. By switching between age cohorts ("<1d", "1d-1w", etc.) you can determine how much of the "old" coins have come back into circulation, and also determine the sentiment of long-term investors.'
            ]],
        ],
    },
    "Realised Profits & Losses": {
        type: "Transactions",
        shortName: "mpl",
        typeChart: "line",
        // typeData: data_MPL,
        typeData: chartName2Settings["MPL"]["data"],
        // typeOptions: options_MPL,
        typeOptions: chartName2Settings["MPL"]["options"],
        tools: MPLToolsV2,
        showTools: true,
        description: [
            [false, ['Realised Profits and Losses is the total value of profits and losses in dollar equivalent for all coins traded at a given time. A coin is said to be traded at a profit if its purchase price is lower than the market price at the time of sale. A coin is traded at a loss if its purchase price is higher than the market price at the time of sale.']],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ resolution. The hourly/daily data shows the total values of profits and losses per hour/day.',
                ' ▫️ address cohort for which you are interested in profits and losses. Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.']],
            [true, 'Guidance for use in trading'],
            [false, [
                ' ▫️ Identifying local/global price peaks. Profit peaks indicate that a large number of investors thought it was a good time to take profit and sell their asset to the upside. This could mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. In this approach, the 7/14/30 daily moving average is the best, as it is less sensitive to random noise.', 
                ' ▫️ Identifying local/global price lows. A sustained excess of losses over profits signals a bearish market sentiment. When losses reach their local/global highs, it indicates that loss taking has declined, and a large amount of capital has been passed on to other players, waiting for future growth. Consequently, we can expect a shift in market sentiment and a future global/local market reversal. In this approach the 14/30 daily moving average is the best, as it is less sensitive to random noise. ',
                ' ▫️ Identify the big players\' sentiment. The big players in the market are those with balances of $10M or more. Investors of this size often include hedge funds and institutions with high expertise and the ability to influence the market. By selecting $10M+ cohorts one can see the values of profits and losses and determine the strategy of this group: holding funds, fixing losses or profits.'
            ]]
        ],

        // 'In the right-hand menu, you can select address cohort for which you want to see the metric:',
        //         '▫️ total: all ETH addresses.',
        //         '▫️ users: a group of addresses that does not include the addresses of smart contracts and centralized exchanges. This can give a more detailed view of real market participants sentiment.',
        //         '▫️ balance & age cohorts.',
    },
    "SOPR": {
        type: "Transactions",
        shortName: "sopr",
        typeChart: "line",
        typeData: chartName2Settings["SOPR"]["data"],
        typeOptions: chartName2Settings["SOPR"]["options"],
        tools: SOPRToolsV2,
        showTools: true,
        description: [
            [false, [
                'The SOPR (Spent Output Profit Ratio) is measured by looking only at coins moved the timescale considered (daily, hourly, etc.) and determining the ratio between the fiat value at the time the token is purchased and the fiat value at the time the token is spent.',
                'The SOPR indicator provides insight into macro market sentiment, profitability and losses taken over a particular time-frame. It reflects the degree of realized profit for all coins moved on-chain.',
                'The SOPR indicator can be considered within the following framework:',
                ' ▫️ SOPR values greater than 1 implies that the coins moved that day are, on average, selling at a profit (price sold is greater than the price paid).',
                ' ▫️ SOPR value less than 1 implies that the coins moved that day are, on average, selling at a loss (price sold is less than the price paid).',
                ' ▫️ SOPR value of exactly 1 implies that the coins moved that day are, on average, selling coins at break even.',
                ' ▫️ SOPR trending higher implies profits are being realized with potential for previously illiquid supply being returned to liquid circulation.',
                ' ▫️ SOPR trending lower implies losses are being realised and/or profitable coins are not being spent.',
                'For more info: https://academy.glassnode.com/indicators/sopr/sopr-spent-output-profit-ratio'
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in SOPR values. Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.'
            ]],
    
        ],
    },
    "Realised Capitalization": {
        type: "Macro",
        shortName: "realised-cap",
        typeChart: "line",
        typeData: chartName2Settings["Realised Capitalization"]["data"],
        typeOptions: chartName2Settings["Realised Capitalization"]["options"],
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                "Realised Cap shows the real amount of money (in USD terms) 'accumulated' or 'stored' in the market of the considered cryptocurrency.",
                "It is calculated as the sum of all existing coins weighted by the price of their last movement. Coins received at a high price will have a large contribution to Realised Cap. Coins received at a low price --  small contribution.",
                "",
                "Changes in Realised Cap:",
                "📈 Increasing RC trend means that cheap coins received at a price lower than the current price are spent now. ",
                "📉 Decreasing RC trend means that expensive coins received earlier at a higher price than the current market price are spent now.",
            ]],
            [true, [
                "Understanding of Market Sentiment"
            ]],
            [false, [
                "When the Realised Cap is higher than the Market Cap, a large number of coins have been received at a price higher than the current market price and are being held at a loss. If all the coins are sold, the resulting losses will exceed the profits. (To see the size of these losses and profits use Unrealised Profits & Losses chart.)",
                "It is the same and vice versa. When the Realised Cap is lower than the Market Cap, lots of coins are held in profits and these unrealised profits exceed the unrealised losses.",
                "So, the moment when the Realised Cap approaches the Market Cap is an important psychological level for the market. At that time, someone reaches the stop-loss level or takes profit, many coins change hands and the market can change the trend."
            ]],
            [true, [
                "Use Case: Market Phases"
            ]],
            [false, [
                "📈 Bull market - uptrend of the Realized Cap.",
                "📉 Bear market - downtrends in the Realized ap. This is the smart money accamulation period."
            ]],
            [true, 'Tools'],
            [false, [
                'In the right-hand menu, you can select address cohort for which you want to see the metric:',
                '▫️ total: all ETH addresses.',
                '▫️ users: a group of addresses that does not include the addresses of smart contracts and centralized exchanges. This can give a more detailed view of real market participants sentiment.',
                '▫️ balance & age cohorts.',
            ]],
        ]
    },
    "Realised Price": {
        type: "Macro",
        shortName: "realised-price",
        typeChart: "line",
        typeData: data_realised_price,
        typeOptions: options_realised_price,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [`Realised Price is the average price at which all existing tokens were purchased (moved last time). If the realised price is higher than the current market price, this indicates that most of the coins are now held at a loss. If the realised price is lower than the market price, it means that most of the supply is holding at a profit.`]],
            [true, 'Guidance for use in trading & Interpretation '],
            [false, [
                `The realised price level is a difficult psychological barrier for a large number of market participants. Let's look at two cases.`,
            ]],
            [false, [
                `Case 1. 📉 The market price is above the realised price and drops to the realised price level.`,
                `As long as the price is above the realised price, on average circulating tokens are held in profit. When the price approaches the realised price level, two opposing mechanisms are triggered: `,
                ` ▫️ Some investors who were holding tokens in profit, approaching the zero profit level, begin to sell the asset. The closer the price approaches the realised price level, the more panic is in the market, which increases the supply volume. `,
                ` ▫️ At the same time, other investors assume further price correction and start to buy coins.`,
                `At this time, tokens are redistributed between wallets, concentrating coins among investors who just bought the asset and intend to hold it. When the price crosses the realised price level, another mechanism is triggered: investors who have not sold coins at the breakeven level begin holding their coins waiting for a correction.`,
                `Usually, after the panic subsides, there is a sharp drop in supply in the market and further price reversal.`,
            ]],
            [false, [
                `Case 2. 📈 The market price is below the realised price and rises to the realised price. `,
                `The opposite mechanism from the first case applies here: `,
                ` ▫️ Some market participants who have been holding the asset at a loss when it reaches the break-even point may want to sell the asset.`,
                ` ▫️ At the same time, for others, an uptrend is a time to buy.`,
                `One of the opposing forces will win, determining whether the price moves away from or breaks through the realised price level.`,
                `Thus, the realised price line is a barrier, which acts as a support/resistance level in case of a downward/upward trend. In case the price breaks through this level, it will not stay there.`
            ]],
        
        ],
    },
}

export var cryptoIndexesV1 = {
    "Unrealised Profit/Loss": {
        type: "Macro",
        shortName: "upl",
        typeChart: "line",
        typeData: dataUPL,
        typeOptions: optionsUPL,
        tools: SuperMacro,
        showTools: true,
        description: [],
    },
    "Unrealised Profit/Loss Difference": {
        type: "Macro",
        shortName: "net-upl",
        typeChart: "line",
        typeData: dataNetUPL,
        typeOptions: optionsNetUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [],
    },
    "Relative Unrealised Profit/Loss": {
        type: "Macro",
        shortName: "relative-upl",
        typeChart: "line",
        typeData: dataUPL,
        typeOptions: optionsUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [],
    },
    "NUPL Indicator": {
        type: "Macro",
        shortName: "nupl",
        typeChart: "line",
        typeData: dataUPL,
        typeOptions: optionsUPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [],
    },
    "Supply in Profit/Loss": {
        type: "Macro",
        shortName: "supply-pl",
        typeChart: "line",
        typeData: dataSupplyPL,
        typeOptions: optionsSupplyPL,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [],
    },
    "Holders Count & Growth": {
        type: "Macro",
        shortName: "number-addresses-change",
        typeChart: "line",
        typeData: dataAddressGrowth,
        typeOptions: optionsAddressGrowth,
        tools: SuperMacro,
        showTools: true,
        description: [],
    },

    "MVRV by Age": {
        type: "Transactions",
        shortName: "mvrv-by-age",
        typeChart: "line",
        typeData: dataMVRVbyAge,
        typeOptions: optionsMVRVbyAge,
        tools: MacroByAgeV2,
        showTools: true,
        description:  [
            [false, [
                'MVRV by Age is an enhanced version of the Market Value to Realized Value (MVRV) metric, which compares the market capitalization (current market value) to realized capitalization (the value when the coins last moved). Unlike the standard MVRV, this metric segments wallets into age cohorts, allowing for a more detailed analysis of how investor behavior changes based on how long they have held their assets.',
            ]],
            [true, 'How is Wallet Age defined?'],
            [false, [
                'Wallet age refers to the time elapsed since the last movement of a token. The longer a coin has been held, the older it is considered. Tokens that have recently changed hands fall into the "younger" category, while those that have been held for a longer time are considered "older."',
            ]],
            [true, 'How to use the MVRV by Age metric?'],
            [false, [
                ' 📊 Analyze Investor Behavior: Understand how the market and realized values differ for tokens of different ages — older tokens may represent long-term investments, while younger tokens may indicate more active trading.',
            ]],
        ],
        version: '2',
    },
    "MVRV by Wallet Size ($)": {
        type: "Transactions",
        shortName: "mvrv-by-wallet-size-dollar",
        typeChart: "line",
        typeData: dataMVRVbyCohort,
        typeOptions: optionsMVRVbyCohort,
        tools: MacroByDollarWalletSizeV2,
        showTools: true,
        description: [
            [false, [
                'MVRV (Market Value to Realized Value) is a crucial on-chain metric used to assess whether a cryptocurrency is overvalued or undervalued. It compares the market capitalization (current value based on the price) to the realized capitalization (the value when the coins last moved). This helps to understand if the market price is higher or lower than the "fair value" of the asset.',
                'The MVRV by Wallet Size ($) metric takes the traditional MVRV and further segments it by wallet sizes, but instead of using the number of tokens, it categorizes wallets based on their value in dollars. This allows for a more granular analysis of how different wallet groups (ranging from small retail investors to large whales) are holding their assets in terms of USD.',
            ]],
            [true, 'How do we define Wallet Size ($)?'],
            [false, [
                'Wallets are grouped based on the USD value of the tokens they hold:',
                ' ▫️ $0-100: Wallets with a balance worth between $0 and $100.',
                ' ▫️ $100-1,000: Wallets with a balance worth between $100 and $1,000.',
                ' ▫️ $1,000-10,000: Wallets with a balance worth between $1,000 and $10,000, and so on.',
                'It`s important to note that as the price of a token changes, addresses may move between these categories, even if no transaction has occurred.',
            ]],
            [true, 'How to use the MVRV by Wallet Size ($) metric?'],
            [false, [
                ' 📊 Track investor behavior: See if large investors (whales) are holding assets at a higher value compared to smaller investors (retail).',
                ' 💡 Identify potential market imbalances: Large discrepancies between wallet sizes can indicate whether certain investor groups are overexposed or underexposed.',
                ' 🔍 Spot trends: Look for unusual MVRV values in specific wallet categories to detect possible price corrections or market shifts.',
            ]],
        ],
        version: '2',
    },
    "MVRV by Wallet Size": {
        type: "Transactions",
        shortName: "mvrv-by-wallet-size",
        typeChart: "line",
        typeData: dataMVRVbyCohort,
        typeOptions: optionsMVRVbyCohort,
        tools: MacroByWalletSizeV2,
        showTools: true,
        description: [
            [false, [
                'MVRV (Market Value to Realized Value) is a key on-chain metric that helps evaluate whether a cryptocurrency is overvalued or undervalued relative to its "fair value." It compares the market capitalization (the current value based on the price) to the realized capitalization (the value based on the price when the coins last moved).',
                'The MVRV by Wallet Size metric takes this concept a step further by segmenting the data based on wallet sizes. It provides a deeper understanding of how different investor classes, such as whales and retail investors, are holding their assets relative to the token’s market value and realized value.',
            ]],
            [true, 'How do we define Wallet Size?'],
            [false, [
                'Wallet size is determined by the amount of a specific token held by each address:',
                ' 🦠 plankton: Addresses holding very small amounts (e.g., for WETH, balances > 0 and <= 0.1 WETH).',
                ' 🐋 megawhales: Addresses with large amounts (e.g., for WETH, balances > 1,000,000 WETH).',
                'This categorization is tailored to each token based on its total supply and average token price, providing a precise classification relevant to each specific token.',
            ]],
            [true, 'How to use the MVRV by Wallet Size metric?'],
            [false, [
                ' 📊 Track investor behavior: See if large investors (whales) are holding assets at a higher value compared to smaller investors (retail).',
                ' 💡 Identify potential market imbalances: Large discrepancies between wallet sizes can indicate whether certain investor groups are overexposed or underexposed.',
                ' 🔍 Spot trends: Look for unusual MVRV values in specific wallet categories to detect possible price corrections or market shifts.',
            ]],
        ],
        version: '2',
    },
    "MVRV": {
        type: "Transactions",
        shortName: "mvrv",
        typeChart: "line",
        typeData: dataMVRV,
        typeOptions: optionsMVRV,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'MVRV (Market Value to Realized Value) is a metric that compares the market price of an altcoin to its realized price — the price at which each token was last moved.',
            ]],
            [true, 'How to use MVRV in investment?'],
            [false, [
                'For altcoins, the MVRV chart helps spot overbought or oversold conditions:',
                ' 🟥 MVRV >> 1: The altcoin is likely overvalued, and a price correction could be coming,',
                ' 🟨 MVRV = 1: The market price matches the realized price, indicating a balanced market,',
                ' 🟩 MVRV << 1: The altcoin is likely undervalued, presenting a potential buying opportunity.',
                'This metric helps assess market cycles and make more informed trading decisions based on the relationship between market and realized prices.',
            ]],
        ],
        version: '2',
    },
    "MVRV Z-Score": {
        type: "Transactions",
        shortName: "mvrv-z-score",
        typeChart: "line",
        typeData: dataMVRVZScore,
        typeOptions: optionsMVRVZScore,
        tools: realisedPriceToolsV2,
        showTools: true,
        description: [
            [false, [
                'The MVRV Z-score is an on-chain metric that helps assess whether a cryptocurrency is overvalued or undervalued relative to its "fair value." The MVRV Z-score is calculated by taking the difference between the market capitalization and the realized capitalization, then dividing it by the standard deviation of the market capitalization, i.e., (market cap – realized cap) / std(market cap).',
                'Compared to the regular MVRV, the MVRV Z-score gives a clearer picture by adjusting for the token’s unique behavior. It takes into account how much the price usually moves away from its "fair value" over time and uses this to make the metric more accurate and easier to interpret.',

            ]],
            [true, 'How to Use MVRV Z-Score in Investment?'],
            [false, [
                ' 🟥 MVRV Z-Score >> 0: It typically indicates that the market is in an overbought state.  This means that the market capitalization is significantly higher than the realized capitalization relative to historical averages for this specific token. For traders and investors, this suggests that the asset may be overvalued, and a price correction could follow. It`s often seen as a signal to reduce exposure or take profits.',
                ' 🟨 MVRV Z-Score = 0: A Z-score near 0 indicates that the market capitalization is in line with the realized capitalization, meaning the market is fairly valued.',
                ' 🟩 MVRV Z-Score << 0: It indicates that the market is undervalued, meaning the market capitalization is lower than usual compared to the realized capitalization. This could signal a potential buying opportunity, as the price may be too low relative to the token`s historical value. It could suggest that the market is oversold, and a price rebound may be coming.',
            ]],
        ],
        version: '2',
    },
    "Realised Price - Cohorts Distribution": {
        type: "Macro",
        shortName: "realised-price-cohort-dist",
        typeChart: "line",
        typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
        typeOptions: chartName2Settings["Realised Price - Cohorts Distribution"]["options"],
        tools: RealisedPriceCohortsTools,
        showTools: true,
        description: [
            [false, ['This chart shows realised price levels for different categories of addresses.', 
                    'Addresses that do not include smart contracts and centralised exchanges are considered here. The addresses are divided into groups (cohorts) according to one of the following parameters: asset holding time / balance / balance in $ terms. You can select the breakdown parameter in the menu on the right.']],
        ],
    },
    "Volume in Profit / Loss - Cohorts Distribution": {
        type: "Transactions",
        shortName: "volume-pl-cohort-dist",
        typeChart: "line",
        typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
        typeOptions: chartName2Settings["MPLBalanceCohorts"]["options"],
        tools: VolumePLCohortsTools,
        showTools: true,
        description: [
            [false, ['This chart visualizes the distribution of transaction volume traded in profit or loss across different address cohorts. This metric gives you a deeper understanding of which address categories own profits and losses over the time period considered.']],
        ],
    },
    "Volume in Profit / Loss Ratio": {
        type: "Realesed Profits & Losses",
        shortName: "volume-pl-ratio",
        typeChart: "line",
        // typeData: data_MPL,
        typeData: chartName2Settings["Realised Profit / Loss Ratio"]["data"],
        typeOptions: chartName2Settings["Realised Profit / Loss Ratio"]["options"],
        tools: VolumePLRatioTools,
        showTools: true,
        description: [
            [false, [
                'Volume in Profit / Loss Ratio is the ratio between all coins moved at a profit and at a loss, i.e. Volume in Profit / Volume in Loss.',
                `When calculating the metric, transfers made by the coin's holders are considered. You can select a specific group of holders for whom you want to see the metric value. This is controlled by the "address cohort" parameter in the right menu.`,
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ resolution. The hourly/daily data shows the total values of profits and losses per hour/day.',
                ' ▫️ address cohort: Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "sc" and "cex" represent smart contract addresses and addresses belonging to centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
                'To learn more about address categories, read the article: https://docs.cherry-pick.xyz/api/address-cohorts',
            
            ]],
        ],
    },
    "Volume in Profit / Loss": {
        type: "Realesed Profits & Losses",
        shortName: "volume-pl",
        typeChart: "line",
        typeData: chartName2Settings["MPLVolume"]["data"],
        typeOptions: chartName2Settings["MPLVolume"]["options"],
        tools: MPLVolumeTools,
        showTools: true,
        description: [
            [false, [
                `The transaction volume in profit/loss shows the amount of transferred coins whose price during the previous transfer was lower/higher than the price during the current transfer.`,
                `When calculating the metric, transfers made by the coin's holders are considered. You can select a specific group of holders for whom you want to see the metric value. This is controlled by the "address cohort" parameter in the right menu.`,
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ resolution. The hourly/daily data shows the total values of profits and losses per hour/day.',
                ' ▫️ address cohort: Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "sc" and "cex" represent smart contract addresses and addresses belonging to centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
                'To learn more about address categories, read the article: https://docs.cherry-pick.xyz/api/address-cohorts',
            
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                ' ▫️ Identifying local/global price peaks. Peaks of the curve that corresponds to volume to profit indicate that a large number of investors thought it was a good time to take profit and sell their asset to the upside. This could mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, peaks of this curve often correlate with local/global price peaks. In this approach, the 7/14/30 daily moving average is the best, as it is less sensitive to random noise.', 
                ' ▫️ Identifying local/global price lows. A sustained excess of the "volume in loss" curve over the "volume in profit" curve signals a bearish market sentiment. When losses reach their local/global highs, it indicates that loss taking has declined, and a large amount of capital has been passed on to other players, waiting for future growth. Consequently, we can expect a shift in market sentiment and a future global/local market reversal. In this approach the 14/30 daily moving average is the best, as it is less sensitive to random noise. ',
            ]]
        ]
    },
    "Number of Addresses": {
        type: "Macro",
        shortName: "number-addresses",
        typeChart: "line",
        typeData: chartName2Settings["Number of Addresses"]["data"],
        typeOptions: chartName2Settings["Number of Addresses"]["options"],
        tools: numberOfAddressesTools,
        showTools: true,
        description: [
            [false, [
                `Number of addresses shows the number of unique addresses that hold a positive (non-zero) number of coins and are representative of the selected address category.`,
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ address cohort: Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "sc" and "cex" represent smart contract addresses and addresses belonging to centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                'To learn more about address categories, read the article: https://docs.cherry-pick.xyz/api/address-cohorts',
            ]],
            [true, 'Tips'],
            [false, [
                `Thus, if the "total" category is selected in the right menu, then the graph shows the total number of unique addresses with a non-zero balance. If the "sc" category is selected on the right, the graph shows the number of smart contracts with non-zero balance. And so on.`,
            ]],
        ],
    },

    "Change in Number of Addresses":  {
        type: "Macro",
        shortName: "number-addresses-change",
        typeChart: "line",
        typeData: chartName2Settings["Number of Addresses"]["data"],
        typeOptions: chartName2Settings["Number of Addresses"]["options"],
        tools: changeNumberOfAddressesTools,
        showTools: true,
        description: [
            [false, [
                `Change in Number of Addresses shows the change in the number of addresses with a non-zero balance in the address category under consideration.`,
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ address cohort: Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "sc" and "cex" represent smart contract addresses and addresses belonging to centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
                'To learn more about address categories, read the article: https://docs.cherry-pick.xyz/api/address-cohorts',
            ]],
            [true, 'Tips'],
            [false, [
                'If the "total" category is selected, the graph shows how many more or fewer addresses with non-zero balance have appeared. If the category "sc" is selected, the graph shows how many more or fewer smart contracts with a non-zero balance have appeared.'
            ]],
        ],
    },

    "Number of Addresses - Cohorts Distribution": {
        type: "Macro",
        shortName: "number-addresses-cohort-dist",
        typeChart: "area",
        typeData: data_address_waves,
        typeOptions: options_num_addresses,
        tools: addressWavesTools,
        showTools: true,
        description: [
            [false, [
                `This chart shows how many addresses with non-zero balance belong to different address cohorts.`,
                `Note that here we consider only addresses that do not belong to centralized exchanges and are not smart contracts. To learn more about address cohorts, read the article: https://docs.cherry-pick.xyz/api/address-cohorts'`,
            ]]
        ],
    },

    "Realised Profit / Loss Ratio": {
        type: "Realesed Profits & Losses",
        shortName: "mpl-ratio",
        typeChart: "line",
        typeData: chartName2Settings["Realised Profit / Loss Ratio"]["data"],
        typeOptions: chartName2Settings["Realised Profit / Loss Ratio"]["options"],
        tools: MPLRatioTools,
        showTools: true,
        description: [
            [false, [
                'Realised Profit / Loss Ratio is the ratio between realized profits and realized losses, i.e. Realised Profits / Realised Losses.',
                `When calculating the metric, transfers made by the coin's holders are considered. You can select a specific group of holders for whom you want to see the metric value. This is controlled by the "address cohort" parameter in the right menu.`,
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ resolution. The hourly/daily data shows the total values of profits and losses per hour/day.',
                ' ▫️ address cohort: Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "sc" and "cex" represent smart contract addresses and addresses belonging to centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
                'To learn more about address categories, read the article: https://docs.cherry-pick.xyz/api/address-cohorts',
            
            ]],
        ],
    },
    "CEX Inflow & Outflow - Cohorts Distribution": {
        type: "Exchanges",
        shortName: "cex-flows-cohort-dist",
        typeChart: "line",
        typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
        typeOptions: chartName2Settings["MPLBalanceCohorts"]["options"],
        tools: CEXFlowsCohortsTools,
        showTools: true,
        description: [
            [false, ['']],
        ],
    },
    "SOPR in Profit / Loss": {
        type: "Realesed Profits & Losses",
        shortName: "sopr-pl",
        typeChart: "line",
        typeData: chartName2Settings["SOPRPL"]["data"],
        typeOptions: chartName2Settings["SOPRPL"]["options"],
        tools: SOPRPLTools,
        showTools: true,
        description: [
            [false, [
                'SOPR (Spent Output Profit Ratio) - one of the market indicators that reflects whether coins are currently being sold at a profit or loss.',
                'SOPR is calculated as follows:',
                ' 1. Consider the coins sent on-chain during the time period under consideration.',
                ' 2. Take their total value at the time of sending.',
                ' 3. Divide by their total value at the time of receiving. ',
                'Thus, SOPR is equal to the ratio of the sale value of the traded coins to their value when they were purchased.',
            ]],
            [true, 'SOPR in Profit / Loss'],
            [false, ['SOPR in Profit / Loss are two SOPR metrics calculated only for coins that are currently trading in profit and loss respectively. These metrics allow you to understand how much the cost of selling coins differs from the cost of buying them separately for profitable and losing coins.']],
        ],
    },
    "SC Volume Locked": {
        type: "Smart Contracts",
        shortName: "sc-volume-locked",
        typeChart: "line",
        typeData: chartName2Settings["TVL"]["data"],
        typeOptions: chartName2Settings["TVL"]["options"],
        tools: TVLTools,
        showTools: true,
        description: [
            [false, ['']],
        ],
    },
    "SC Inflow & Outflow": {
        type: "Smart Contracts",
        shortName: "sc-flows",
        typeChart: "line",
        typeData: data_CohortFlows,
        typeOptions: options_CohortFlows,
        tools: SCFlowsTools,
        showTools: true,
        description: [
            [false, ['']],
        ],
    },
    "SC Netflow": {
        type: "Smart Contracts",
        shortName: "sc-netflow",
        typeChart: "line",
        typeData: data_CohortNetlow,
        typeOptions: options_CohortNetlow,
        tools: SCFlowsTools,
        showTools: true,
        description: [
            [false, ['']],
        ],
    },
    "Overview": {
        shortName: "overview",
        showOverview: true,
        showTools: false,
    },
    "Net Realised Profits & Losses": {
        type: "Transactions",
        shortName: "nmpl",
        typeChart: "line",
        typeData: chartName2Settings["NMPL"]["data"],
        // typeOptions: optionsNMPL,
        typeOptions: chartName2Settings["NMPL"]["options"],
        tools: MPLTools,
        showTools: true,
        description: [
            [false, ['Net Realised Profits & Losses is calculated as (Realised Profits - Realised Losses).']],
        ],
    },
    "Realised Profits & Losses": {
        type: "Transactions",
        shortName: "mpl",
        typeChart: "line",
        // typeData: data_MPL,
        typeData: chartName2Settings["MPL"]["data"],
        // typeOptions: options_MPL,
        typeOptions: chartName2Settings["MPL"]["options"],
        tools: MPLTools,
        showTools: true,
        description: [
            [false, ['Realised Profits and Losses is the total value of profits and losses in dollar equivalent for all coins traded at a given time. A coin is said to be traded at a profit if its purchase price is lower than the market price at the time of sale. A coin is traded at a loss if its purchase price is higher than the market price at the time of sale.']],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ resolution. The hourly/daily data shows the total values of profits and losses per hour/day.',
                ' ▫️ address cohort for which you are interested in profits and losses. Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.']],
            [true, 'Guidance for use in trading'],
            [false, [
                ' ▫️ Identifying local/global price peaks. Profit peaks indicate that a large number of investors thought it was a good time to take profit and sell their asset to the upside. This could mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. In this approach, the 7/14/30 daily moving average is the best, as it is less sensitive to random noise.', 
                ' ▫️ Identifying local/global price lows. A sustained excess of losses over profits signals a bearish market sentiment. When losses reach their local/global highs, it indicates that loss taking has declined, and a large amount of capital has been passed on to other players, waiting for future growth. Consequently, we can expect a shift in market sentiment and a future global/local market reversal. In this approach the 14/30 daily moving average is the best, as it is less sensitive to random noise. ',
                ' ▫️ Identify the big players\' sentiment. The big players in the market are those with balances of $10M or more. Investors of this size often include hedge funds and institutions with high expertise and the ability to influence the market. By selecting $10M+ cohorts one can see the values of profits and losses and determine the strategy of this group: holding funds, fixing losses or profits.'
            ]]
        ],

        // 'In the right-hand menu, you can select address cohort for which you want to see the metric:',
        //         '▫️ total: all ETH addresses.',
        //         '▫️ users: a group of addresses that does not include the addresses of smart contracts and centralized exchanges. This can give a more detailed view of real market participants sentiment.',
        //         '▫️ balance & age cohorts.',
    },
    "Realised Profits & Losses - Cohorts Distribution": {
        type: "Transactions",
        shortName: "mpl-cohort-dist",
        typeChart: "line",
        typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
        typeOptions: chartName2Settings["MPLBalanceCohorts"]["options"],
        tools: MPLCohortsTools,
        showTools: true,
        description: [
            [false, ['This chart visualizes the distribution of realized profits or losses across different address cohorts. This metric gives you a deeper understanding of which address categories own profits and losses over the time period considered.']],
        ],
    },
    "SOPR": {
        type: "Transactions",
        shortName: "sopr",
        typeChart: "line",
        typeData: chartName2Settings["SOPR"]["data"],
        typeOptions: chartName2Settings["SOPR"]["options"],
        tools: SOPRTools,
        showTools: true,
        description: [
            [false, [
                'The SOPR (Spent Output Profit Ratio) is measured by looking only at coins moved the timescale considered (daily, hourly, etc.) and determining the ratio between the fiat value at the time the token is purchased and the fiat value at the time the token is spent.',
                'The SOPR indicator provides insight into macro market sentiment, profitability and losses taken over a particular time-frame. It reflects the degree of realized profit for all coins moved on-chain.',
                'The SOPR indicator can be considered within the following framework:',
                ' ▫️ SOPR values greater than 1 implies that the coins moved that day are, on average, selling at a profit (price sold is greater than the price paid).',
                ' ▫️ SOPR value less than 1 implies that the coins moved that day are, on average, selling at a loss (price sold is less than the price paid).',
                ' ▫️ SOPR value of exactly 1 implies that the coins moved that day are, on average, selling coins at break even.',
                ' ▫️ SOPR trending higher implies profits are being realized with potential for previously illiquid supply being returned to liquid circulation.',
                ' ▫️ SOPR trending lower implies losses are being realised and/or profitable coins are not being spent.',
                'For more info: https://academy.glassnode.com/indicators/sopr/sopr-spent-output-profit-ratio'
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in SOPR values. Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.'
            ]],
    
        ],
    },
    "Total Transaction Volume": {
        type: "Transactions",
        shortName: "total-tx-volume",
        typeChart: "bar",
        typeData: chartName2Settings["Total Transaction Volume"]["data"],
        typeOptions: chartName2Settings["Total Transaction Volume"]["options"],
        tools: onchainVolumeTools,
        showTools: true,
        description: [
            [false, [`Total Transaction Volumes represents the total volume of coins traded within the blockchain over the time-frame considered.`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in metric values. Cohort "total" represents all ETH addresses. Cohort "users" represents a group of addresses that does not include the addresses of smart contracts and centralized exchanges. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ dollar or native token equivalent. '
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                ' ▫️ Identifying "fair" price levels. A local peak in the on-chain volume indicates that the current price is considered fair by a large number of market participants and that they are willing to exchange coins at that price. If by moving the price from this level, the on-chain volume drops sharply, it can lead to a rebound in price and a return to the \"fair\" value. ',
                ' ▫️ This indecator is good to use in conjunction with the Transaction number metric. Using both metrics, you can determine how many market participants traded at a given price and in what volume, as well as the average transaction volume. This will avoid the error of determining a fair price level when a large amount of capital has been moved by a small number of participants.',
                ' ▫️ Identifying the age of the coins being traded. By switching between age cohorts ("<1d", "1d-1w", etc.) you can determine how much of the "old" coins have come back into circulation, and also determine the sentiment of long-term investors.'
            ]],
        ],
    },
    "Average Transaction Volume": {
        type: "Transactions",
        shortName: "average-tx-volume",
        typeChart: "bar",
        typeData: chartName2Settings["Average Transaction Volume"]["data"],
        typeOptions: chartName2Settings["Average Transaction Volume"]["options"],
        tools: averageTransactionVolume,
        showTools: true,
        description: [
            [false, [`Average Transaction Volume represents the average number of coins transferred for the selected time interval. The Average Transaction Volume is calculated as the Total Transaction Volume divided by Number of Transaction.`]],
        ],
    },
    "Number of Transactions": {
        type: "Transactions",
        shortName: "number-tx",
        typeChart: "bar",
        typeData: chartName2Settings["Number of Transactions"]["data"],
        typeOptions: chartName2Settings["Number of Transactions"]["options"],
        tools: numberOfTransactionsTools,
        showTools: true,
        description: [
            [false, [`Transaction number shows the number of token transfers made with a value in a given range ("$0-100", "$100-1k", and so on) over the time-frame considered. The range can be selected in the right-side menu.`]],
            [true, 'Guidance for use in trading'],
            [false, ['Identifying the "fair" price levels. This index should be used in conjunction with the on-chain volume. If a local maximum is formed on the on-chain volume chart and at the same time there were quite a few transactions, then the price value corresponding to the on-chain volume peak can be considered to be a "fair" price level. In other words, a large number of market participants are willing to trade at this price. Such a price level could be regarded as a stable one. Such price levels are often stable. If the on-chain volume drops sharply when the price moves away from this level, the price is likely to return to its "fair" value.']],
        ],
    },
    "Number of Active Addresses": {
        type: "Transactions",
        shortName: "number-active-addresses",
        typeChart: "bar",
        typeData: chartName2Settings["Number of Active Addresses"]["data"],
        typeOptions: chartName2Settings["Number of Active Addresses"]["options"],
        tools: numberActiveAddresses,
        showTools: true,
        description: [
            [false, [`Number of Active Addresses is the number of addresses participated in transfers during the considered period of time.`]],
        ],
    },
    "Realised Price Distribution": {
        type: "Macro",
        shortName: "realised-price-dist",
        interactiveChart: true,
        typeChart: "area",
        typeData: dataRealisedPriceDist,
        typeOptions: optionsRealisedPriceDist,
        tools: realisedPriceDistTools,
        showTools: false,
        description: [[false, ['The Realised Price Distribution shows the distribution of supply over the purchase price levels.']]],
    },
    "Realised Capitalization": {
        type: "Macro",
        shortName: "realised-cap",
        typeChart: "line",
        typeData: chartName2Settings["Realised Capitalization"]["data"],
        typeOptions: chartName2Settings["Realised Capitalization"]["options"],
        tools: realisedPriceTools,
        showTools: true,
        description: [
            [false, [
                "Realised Cap shows the real amount of money (in USD terms) 'accumulated' or 'stored' in the market of the considered cryptocurrency.",
                "It is calculated as the sum of all existing coins weighted by the price of their last movement. Coins received at a high price will have a large contribution to Realised Cap. Coins received at a low price --  small contribution.",
                "",
                "Changes in Realised Cap:",
                "📈 Increasing RC trend means that cheap coins received at a price lower than the current price are spent now. ",
                "📉 Decreasing RC trend means that expensive coins received earlier at a higher price than the current market price are spent now.",
            ]],
            [true, [
                "Understanding of Market Sentiment"
            ]],
            [false, [
                "When the Realised Cap is higher than the Market Cap, a large number of coins have been received at a price higher than the current market price and are being held at a loss. If all the coins are sold, the resulting losses will exceed the profits. (To see the size of these losses and profits use Unrealised Profits & Losses chart.)",
                "It is the same and vice versa. When the Realised Cap is lower than the Market Cap, lots of coins are held in profits and these unrealised profits exceed the unrealised losses.",
                "So, the moment when the Realised Cap approaches the Market Cap is an important psychological level for the market. At that time, someone reaches the stop-loss level or takes profit, many coins change hands and the market can change the trend."
            ]],
            [true, [
                "Use Case: Market Phases"
            ]],
            [false, [
                "📈 Bull market - uptrend of the Realized Cap.",
                "📉 Bear market - downtrends in the Realized ap. This is the smart money accamulation period."
            ]],
            [true, 'Tools'],
            [false, [
                'In the right-hand menu, you can select address cohort for which you want to see the metric:',
                '▫️ total: all ETH addresses.',
                '▫️ users: a group of addresses that does not include the addresses of smart contracts and centralized exchanges. This can give a more detailed view of real market participants sentiment.',
                '▫️ balance & age cohorts.',
            ]],
        ]
    },
    "Realised Price": {
        type: "Macro",
        shortName: "realised-price",
        typeChart: "line",
        typeData: data_realised_price,
        typeOptions: options_realised_price,
        tools: realisedPriceTools,
        showTools: true,
        description: [
            [false, [`Realised Price is the average price at which all existing tokens were purchased (moved last time). If the realised price is higher than the current market price, this indicates that most of the coins are now held at a loss. If the realised price is lower than the market price, it means that most of the supply is holding at a profit.`]],
            [true, 'Guidance for use in trading & Interpretation '],
            [false, [
                `The realised price level is a difficult psychological barrier for a large number of market participants. Let's look at two cases.`,
            ]],
            [false, [
                `Case 1. 📉 The market price is above the realised price and drops to the realised price level.`,
                `As long as the price is above the realised price, on average circulating tokens are held in profit. When the price approaches the realised price level, two opposing mechanisms are triggered: `,
                ` ▫️ Some investors who were holding tokens in profit, approaching the zero profit level, begin to sell the asset. The closer the price approaches the realised price level, the more panic is in the market, which increases the supply volume. `,
                ` ▫️ At the same time, other investors assume further price correction and start to buy coins.`,
                `At this time, tokens are redistributed between wallets, concentrating coins among investors who just bought the asset and intend to hold it. When the price crosses the realised price level, another mechanism is triggered: investors who have not sold coins at the breakeven level begin holding their coins waiting for a correction.`,
                `Usually, after the panic subsides, there is a sharp drop in supply in the market and further price reversal.`,
            ]],
            [false, [
                `Case 2. 📈 The market price is below the realised price and rises to the realised price. `,
                `The opposite mechanism from the first case applies here: `,
                ` ▫️ Some market participants who have been holding the asset at a loss when it reaches the break-even point may want to sell the asset.`,
                ` ▫️ At the same time, for others, an uptrend is a time to buy.`,
                `One of the opposing forces will win, determining whether the price moves away from or breaks through the realised price level.`,
                `Thus, the realised price line is a barrier, which acts as a support/resistance level in case of a downward/upward trend. In case the price breaks through this level, it will not stay there.`
            ]],
        
        ],
    },
    "Unrealised Profits & Losses": {
        type: "Macro",
        shortName: "upl",
        typeChart: "line",
        typeData: chartName2Settings["UPL"]["data"],
        typeOptions: chartName2Settings["UPL"]["options"],
        tools: UPLTools,
        showTools: true,
        description: [],
    },
    "Unrealised Profits & Losses - Cohorts Distribution": {
        type: "Transactions",
        shortName: "upl-cohort-dist",
        typeChart: "line",
        typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
        typeOptions: chartName2Settings["MPLBalanceCohorts"]["options"],
        tools: UPLCohortsTools,
        showTools: true,
        description: [],
    },
    "Supply in Profit / Loss": {
        type: "Macro",
        shortName: "supply-pl",
        typeChart: "line",
        typeData: chartName2Settings["Supply P&L"]["data"],
        typeOptions: chartName2Settings["Supply P&L"]["options"],
        tools: supplyPLTools,
        showTools: true,
        description: [],
    },
    "Supply in Profit / Loss - Cohorts Distribution": {
        type: "Transactions",
        shortName: "supply-pl-cohort-dist",
        typeChart: "line",
        typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
        typeOptions: chartName2Settings["MPLBalanceCohorts"]["options"],
        tools: supplyPLCohortsTools,
        showTools: true,
        description: [],
    },

    "Number of Addresses in Profit / Loss": {
        type: "Macro",
        shortName: "number-addresses-pl",
        typeChart: "line",
        typeData: chartName2Settings["Number of addresses P&L"]["data"],
        typeOptions: chartName2Settings["Number of addresses P&L"]["options"],
        tools: numberOfAddressesPLTools,
        showTools: true,
        description: [],
    },
    "Balance Cohort Waves":{
        type: "Macro",
        shortName: "balance-cohort-waves",
        typeChart: "area",
        typeData: data_address_waves,
        typeOptions: options_address_waves,
        tools: addressWavesTools,
        showTools: true,
        description: [
            [false, [
                `Balance Cohort Waves shows what percentage of supply belongs to different address cohorts, where a cohort is a group of addresses with balances in a given range: $0-100, $100-1k, $1k-10k and so on.`,
                `The changing distribution of Balance Cohort Waves from day to day can tell which address cohorts are accumulating tokens and which ones are spending the opposite. The categories of addresses with large balances usually belong to funds and institutions and so called smart money. An increase or decrease in supply in these categories provides insight into the times when smart money investors with huge balances are accumulating and holding funds or spending coins.`,
                `Balance Cohort Waves is the accumulated result of Balance Cohort Inflow & Outflow which naturally follows the indications that in/outflow has and is presented in relative values. `
            ]]
        ],
    }, 
    "Realised Cap Balance Cohort Waves":{
        type: "Macro",
        shortName: "realised-cap-balance-cohort-waves",
        typeChart: "area",
        typeData: data_address_waves,
        typeOptions: options_address_waves,
        tools: addressWavesTools,
        showTools: true,
        description:  [
            [false, [
                `Realised Cap Balance Cohort Waves show what percentage of realised cap belongs to different address cohorts, where cohorts are a group of addresses with balances in a given range: $0-100, $100-1k, $1k-10k and so on. `,
                `For each address, the Realised Cap of address is defined as Average price of purchasing tokens by address * balance of address. That is, the Realised Cap of address is essentially how much money the address paid in dollar terms for its asset. 
                Realised Cap Balance Cohort Waves shows what percentage of the invested funds in the market for a given token belong to each address cohort.`
            ]]
        ],
    }, 
    "HODL Waves":{
        type: "Macro",
        shortName: "hodl-waves",
        typeChart: "area",
        typeData: data_hodl_waves,
        typeOptions: options_hodl_waves,
        tools: hodlWavesTools,
        showTools: true,
        description: [
            [false, [
                `HODL Waves shows the distribution of circulating supply by age of coins. For example, if at any date a cohort '1w-1m' has 15% it means that at that time 15% of all circulating supply was last moved from 1 week to 1 month ago.`,
                `HODL Waves allows you to determine what percentage of the total circulating supply is involved in transfers each day, and what volume of tokens are held and for how long.`,
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `If an increasing proportion of coins are moving from younger to older cohorts, this indicates the existence of accumulation or HODLing trend in the market. This may indicate that market participants hope to sell the asset at a better price in the future.`,
                `A decrease in the percentage of supply in the older age cohorts and an increase in the percentage of younger cohorts indicates that old coins are being spent during this period. This provides insight into the times when smart money investors with long time horizons are spending and re-activating dormant coins. `,
            ]],
        ],
    }, 
    "Realised Cap HODL Waves":{
        type: "Macro",
        shortName: "realised-cap-hodl-waves",
        typeChart: "area",
        typeData: data_hodl_waves,
        typeOptions: options_hodl_waves,
        tools: hodlWavesTools,
        showTools: true,
        description: [
            [false, [`Realised Cap HODL Waves shows the supply distribution by coin age, weighted by how much money in dollar equivalent has been invested by each of the cohorts. 
            For example, if a '1w-1m' cohort has 15% at any given date, this means that, at that point all of those tokens that were last moved (participated in a transfers) between 1 week and 1 month ago cover 15% of all money invested in that token's market.`,
            `This indicator provides insight into the relative economic weight stored by coins of various holding times, and changes arising from holding and spending behaviour.`]]
        ],
    },
    // "Balance Cohort Inflow & Outflow - Cohorts Distribution": {
    //     type: "Transactions",
    //     shortName: "balance-cohort-flows",
    //     typeChart: "line",
    //     typeData: chartName2Settings["MPLBalanceCohorts"]["data"],
    //     typeOptions: chartName2Settings["MPLBalanceCohorts"]["options"],
    //     tools: MPLCohortsTools,
    //     showTools: true,
    //     description: [],
    // },
    "Balance Cohort Inflow & Outflow": {
        type: "Transactions",
        shortName: "balance-cohort-flows",
        typeChart: "line",
        typeData: data_CohortFlows,
        typeOptions: options_CohortFlows,
        tools: balanceCohortInflowOutflowTools,
        showTools: true,
        description: [
            [false, [`Balance Cohort Inflow & Outflow shows how many tokens were received (inflow) and sent (outflow) by each address cohort during the time period considered, where a cohort is a group of addresses with a balance in a given range (for example: '$0-100', '$100-1k', and so on).`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in metric values. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range.',
                ' ▫️ dollar or native token equivalent. ',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
            ]],
            [true, 'Guidance for use in trading'],
            [false, [`The inflow and outflow can be used to identify which cohorts of addresses are accumulating tokens and which are spending them. Address categories with large balances are the most interesting, as they usually belong to funds and institutions and so-called smart money. The inflow and outflow peaks in these categories provide insight into the times when smart money investors with huge balances are accumulating funds or spending coins.`]],
        ],
    },
    "Balance Cohort Netflow": {
        type: "Transactions",
        shortName: "balance-cohort-netflow",
        typeChart: "line",
        typeData: data_CohortNetlow,
        typeOptions: options_CohortNetlow,
        tools: balanceCohortInflowOutflowTools,
        showTools: true,
        description: [
            [false, [
                'Balance Cohort Netflow is defined as Balance Cohort Inflow - Balance Cohort Outflow. Balance Cohort Netflow shows how much token inflow or outflow has occurred for each address cohort, where a cohort is a group of addresses with a balance within a given range (for example: \'$0-100\', \'$100-1k\', and so on). ',
                'For example, if on any given day the Netflow value for the \'$10k-100k\' cohort of addresses is greater than zero, this means that on that day addresses with balances between $10k and $100k received more tokens than they sent in. In other words, the coin inflow for that cohort of addresses was greater than the outflow. If the Netflow is negative, it indicates the opposite: there were more asset outflows than inflows from this address cohort.'
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in metric values. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range.',
                ' ▫️ dollar or native token equivalent. ',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `The balance cohort netflow can be used to identify which cohorts of addresses are accumulating tokens and which are spending them. 
                Most interesting may be the categories of addresses with large balances, which often belong to funds, institutions and so-called smart money. The netflow' peaks for these cohorts provide insight into the times when smart money investors with huge balances are accumulating funds or spending coins.`,
                `To get rid of noise in the data, use the moving average tool.`
            ]]
        ],
    },
    "Moved Coins Realised Price": {
        type: "Transactions",
        shortName: "moved-coins-realised-price",
        typeChart: "line",
        typeData: data_realised_price,
        typeOptions: options_realised_price,
        tools:  spentCoinsRealisedPriceTools,
        showTools: true,
        description: [
            [false, [`Moved Coins Realised Price is the average purchase price of tokens that have been transferred during the time period considered. If the MCRP is lower than the market price, it means that on average market participants are trading at a profit. If the MCRP is higher than the market price, it is the other way round, at a loss.`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ address cohort for which you are interested in metric values. Cohorts "$0-100", "$100-1k" and others with "$" in the title represent groups of addresses with balances in the specified range. Cohorts "<1d", "1d-1w" and others, showing time intervals,  represent groups of addresses, whose average age of coins belonging to them lies in the given range.',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.'
            ]],
        ],
    },
    "Moved Coins Age Distribution": {
        type: "Transactions",
        shortName: "moved-coins-age-dist",
        typeChart: "line",
        typeData: dataAgeDist,
        typeOptions: optionsAgeDist,
        tools:  spentCoinsAgeDistTools,
        showTools: true,
        description: [
            [false, [`Moved Coins Age Distribution shows the distribution of traded volume by coin age, where the age of a coin is defined as the time interval between the average time all coins are received by an address and the time the coin is sent to an exchange.`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ dollar or native token equivalent. ',
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `Determine how much of the 'old' coins have returned into circulation. 
                If old coins are activated, this may indicate that long-term investors, often seen as smart money, consider this a good period to sell assets. Also, the return of coins to liquid circulation increases the probability of a local price peak.`,
            ]],
        ],
    },
    "CEX Reserve": {
        type: "Exchanges",
        shortName: "cex-reserve",
        typeChart: "line",
        typeData: dataCEXBalance,
        typeOptions: optionsCEXBalance,
        tools: CEXBalanceTools,
        showTools: true,
        description: [
            [false, [
                `CEX Reserver represents the total amount of coins held on exchange addresses. In the right-hand menu you can select a particular exchange (e.g. Binance or FTX) or view totals for all exchanges.`,
                `CEX Reserve is a collective measure of potential coins that are ready to be sold in the market. CEX Reserve is the accumulated result of CEX Inflow & Outflow which naturally follows the indications that in/outflow has. While CEX Inflow & Outflow indicates the specific moment or period, Exchange Reserve is easy to track the result of the entire period's movements.`
            ]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ dollar or native token equivalent. ',
                ` ▫️ particular CEX (e.g. Binance or FTX) for which you want to see metric values, or you can select all exchanges.`,
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `The increase in CEX Reserves is a Bearish signal. The increasing trend indicates that more and more tokens are concentrated in the exchanges' wallets and can be sold at any time. This increases selling pressure and decreases scarcity of tokens on the exchange. `,
                `The decrease in CEX Reserves is a Bullish signal. The decreasing trend indicates that fewer tokens remain available in the exchanges, forming a scarcity on the exchange.`,
            ]],
    
        ],
    },
    "CEX Inflow & Outflow": {
        type: "Exchanges",
        shortName: "cex-flows",
        typeChart: "line",
        typeData: data_CohortFlows,
        typeOptions: options_CohortFlows,
        tools: CEXInflowOutflowTools,
        showTools: true,
        description: [
            [false, [`CEX Inflow & Outflow represents the total amount of tokens transferred to and from the centralized exchange. In the right-hand menu, you can select a particular exchange (e.g. Binance or FTX) or view totals for all exchanges.
            In order to explore the general trend, it is recommended to use the moving average, which allows you to get rid of noise in the data.
            `]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ dollar or native token equivalent. ',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
                ` ▫️ particular CEX (e.g. Binance or FTX) for which you want to view metic values or all exchanges.`,
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `The analysis of inflow and outflow trends makes it possible to predict changes in supply and demand on the centralized exchanges and, as a consequence, changes in price.`,
                ` ▫️ An increase in inflow is most often a Bearish signal and indicates a potential risk of increased volatility.`,
                ` ▫️ An increase in outflow is most often a Bulish signal.`,
            ]],
            [true, 'Explanation'],
            [false, [
                `Let's consider the inflow first. The transfer of coins by someone to an exchange happens for one of the following reasons:`,
                ` ▫️ The desire to sell the asset. This reason for action most often leads to a drop in price, which is a bearish sign.`,
                ` ▫️ To trade in derivative markets. This will lead to an increase in trading activity and consequently lead to increased volatility risk.`,
                ` ▫️ To hold them. This behavior will not affect the price in any way.`,
                ``,
                `The increase in outflow from the centralized exchange indicated that more investors are withdrawing coins from the exchange. Instead of storing coins in an exchange wallet, where they can be sold quickly, investors are moving their assets out. This may be an indication of their desire for long-term secure holding in a cold wallet. This reason of action mostly leads to the price rise which indicates a bullish sign. `,
            ]],
        ],
    },
    "CEX Netflow": {
        type: "Exchanges",
        shortName: "cex-netflow",
        typeChart: "line",
        typeData: data_CohortNetlow,
        typeOptions: options_CohortNetlow,
        tools: CEXInflowOutflowTools,
        showTools: true,
        description: [
            [false, [`CEX Netflow is the difference between token flowing in and out of the exchange. (Inflow - Outflow = Netflow). In the right-hand menu you can select a specific exchange (e.g. Binance or FTX) or look at totals for all exchanges. In order to study the general trend, we recommend using the moving average, which allows you to get rid of the noise in the data.`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ dollar or native token equivalent. ',
                ' ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.',
                ` ▫️ particular CEX (e.g. Binance or FTX) for which you want to see metric values, or you can select all exchanges.`,
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `The analysis of inflow and outflow trends makes it possible to predict changes in supply and demand on the centralized exchanges and, as a consequence, changes in price.`,
                `If the netflow is much greater than 0, it is most often a Bearish signal. If the netflow is much less than 0, it is most often a bullish signal.`,
            ]],
            
        ],
    },
    // "CEX Inflow Age Distribution": {
    //     type: "Exchanges",
    //     shortName: "cex-inflow-age-dist",
    //     typeChart: "line",
    //     typeData: dataAgeDist,
    //     typeOptions: optionsAgeDist,
    //     tools: CEXInflowAgeDistTools,
    //     showTools: true,
    //     description: [
    //         [false, [
    //             `CEX Inflow Age Distribution shows the distribution of coins transferred to exchanges by the age of those coins. The age of a coin is defined as the time interval between the average receipt of all coins by an address and the moment the coin is sent to an exchange.`,
    //             `CEX Inflow Age Distribution allows you to determine which age cohorts generate the most selling pressure on the exchanges.  If there is a large peak in one of the cohorts at some point and a spike in price occurs afterwards, it can be argued that this particular coin cohort has influenced the price movement.`
    //         ]],
    //         [true, 'Tools'],
    //         [false, [
    //             'In the menu on the right you can choose:',
    //             ' ▫️ hourly or daily resolution.',
    //             ' ▫️ dollar or native token equivalent. ',
    //         ]],
    //         [true, 'Guidance for use in trading'],
    //         [false, [
    //             `Identify the times when long-term and short-term investors move their assets from cold wallets to exchanges' addresses where they are more likely to be sold. `
    //         ]],
    //         [true, 'Tips'],
    //         [false, [
    //             `It can be assumed that if old coins are transferred to an exchange, they are more likely to be sold. Then if inflow for the 'old' cohorts is high, we can expect a big change in price.`
    //         ]],
    //     ],
    // },
    // "CEX Inflow Balance Cohort Distribution": {
    //     type: "Exchanges",
    //     shortName: "cex-inflow-balance-cohort-dist",
    //     typeChart: "line",
    //     typeData: dataBalanceCohortDist,
    //     typeOptions: optionsBalanceCohortDist,
    //     tools: CEXInflowBalanceCohortDistTools,
    //     showTools: true,
    //     description: [
    //         [false, [
    //             `CEX Inflow Balance Cohort Distribution represents the total amount of tokens transferred to centralized exchanges by addresses belonging to each of the address cohorts. Address cohorts "$0-100", "$100-1k" and others represent groups of addresses with balances in the specified range.`,
    //             `CEX Inflow Balance Cohort Distribution allows you to determine which balance cohorts generate the most selling pressure on the exchanges.  If there is a large peak in one of the cohorts at some point and a spike in price occurs afterwards, it can be argued that this particular coin cohort has influenced the price movement.`
    //         ]],
    //         [true, 'Tools'],
    //         [false, [
    //             'In the menu on the right you can choose:',
    //             ' ▫️ hourly or daily resolution.',
    //             ' ▫️ dollar or native token equivalent. ',
    //         ]],
    //         [true, 'Guidance for use in trading'],
    //         [false, [
    //             `Identify the times when whale and retail investors move their assets from cold wallets to exchanges' addresses where they are more likely to be sold. `
    //         ]],
    //     ]
    // },
    // "CEX Inflow & Outflow by Balance Cohort": {
    //     type: "Exchanges",
    //     shortName: "cex-flows-balance-cohort",
    //     typeChart: "line",
    //     typeData: data_CohortFlows,
    //     typeOptions: options_CohortFlows,
    //     tools: CEXNetflowByBalanceCohortTools,
    //     showTools: true,
    //     description: [
    //         [false, [`CEX Inflow & Outflow by Balance Cohort represents the total amount of tokens transferred to and from centralized exchanges by addresses belonging to one of the address cohorts. Address cohorts "$0-100", "$100-1k" and others represent groups of addresses with balances in the specified range.`]],
    //         [true, 'Tools'],
    //         [false, [
    //             'In the menu on the right you can choose:',
    //             ' ▫️ hourly or daily resolution.',
    //             ' ▫️ dollar or native token equivalent. ',
    //             ' ▫️ address cohort for which you are interested in inflow and outflow values. Cohorts "$0-100", "$100-1k" and others represent groups of addresses with balances in the specified range.',
    //             ` ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.`,
    //         ]],
    //         [true, 'Guidance for use in trading'],
    //         [false, [
    //             `As explained in the guideline for CEX Inflow & Outflow metric, one reason for the increase in inflow on exchanges is that traders and investors want to sell their assets and convert them into fiat or steblecoins, while the increase in outflow rather indicates that investors want to hold their assets for the long term and do not intend to sell them. CEX Inflow & Outflow by Balance Cohort shows inflow and outflow for each address cohort.
    //             This allows us to determine whether large players representing whales, funds, institutions and smart money view this as a good time to sell or hold tokens.
    //             `,
    //         ]],
    //     ],
    // },
    "CEX Netflow by Balance Cohort": {
        type: "Exchanges",
        shortName: "cex-netflow-balance-cohort",
        typeChart: "line",
        typeData: data_CohortNetlow,
        typeOptions: options_CohortNetlow,
        tools: CEXNetflowByBalanceCohortTools,
        showTools: true,
        description: [
            [false, [`CEX Netflow is the difference between token flowing into and out of the exchange. (Inflow - Outflow = Netflow). CEX Netflow by Balance Cohort represents netflow values for each group of addresses with balances in a given range ($0-100, $100-1k and so on).`]],
            [true, 'Tools'],
            [false, [
                'In the menu on the right you can choose:',
                ' ▫️ hourly or daily resolution.',
                ' ▫️ dollar or native token equivalent. ',
                ' ▫️ address cohort for which you are interested in inflow and outflow values. Cohorts "$0-100", "$100-1k" and others represent groups of addresses with balances in the specified range.',
                ` ▫️ moving average. The moving average defines the period of averaging values and allows one to get rid of noise and see the general trend.`,
            ]],
            [true, 'Guidance for use in trading'],
            [false, [
                `The peaks in the netflow values of some address cohort often coincide with times when investors in that cohort are intending to sell their assets. This insight is particularly interesting in the case of cohorts with large balances, as they represent funds, institutions and smart money investors. 
                Also, netflow peaks can be signals of increased volatility in the market.`,
                `Conversely, large negative netflow values indicate that investors in this cohort are withdrawing large amounts of tokens to hold in cold wallets.`,
                `For a more detailed explanation, see CEX Inflow & Outflow guideline.`
            ]],
        ]
    }
}

function Description(props) {
    return props.text.map(([header, paragrpahs]) => (
        <div className="description-section">
                <p className="section-header"> { header } </p>
                <div className="section-text">
                        { paragrpahs }
                </div>
        </div>
    ))
}

export const [shortNamesV1, shortNames2IndexV1] = getShortNameMapping(indexesV1, cryptoIndexesV1)
export const [shortNamesV2, shortNames2IndexV2] = getShortNameMapping(indexesV2, cryptoIndexesV2)


// export let globalConfig = {
//     indexes: indexesV1,
//     cryptoIndexes: cryptoIndexesV1,
//     shortNames: shortNamesV1,
//     shortNames2Index: shortNames2IndexV1,
// };
  
// export const setGlobalConfig = (version) => {
//   if (version === '2') {
//     globalConfig = {
//       indexes: indexesV2,
//       cryptoIndexes: cryptoIndexesV2,
//       shortNames: shortNamesV2,
//       shortNames2Index: shortNames2IndexV2,
//     };
//   } else {
//     globalConfig = {
//       indexes: indexesV1,
//       cryptoIndexes: cryptoIndexesV1,
//       shortNames: shortNamesV1,
//       shortNames2Index: shortNames2IndexV1,
//     };
//   }
// };

// export const getGlobalConfig = () => {
//     if (!globalConfig) {
//       throw new Error("Global configuration has not been set. Call setGlobalConfig(version) first.");
//     }
//     return globalConfig;
// };


// export const tools = {
//     "Resolution": ["Hourly", "Daily"],
//     "Balance cohort": ["$0-100", "$100-1k", "$1k-1m"],
//     "Age cohort": ["<1 day", "1-7 days"],
//     "Moving average": ["7 days", "14 days", "30 days"]
// }