import { React, useState, useRef, useEffect}  from "react";
// import { indexes, cryptoIndexes, shortNames, shortNames2Index } from "./data";
import { Menu } from "./Menu"
import { Overview } from "./Overview";
import { getMPL, getPrice, getData } from "./loadData";
import { Chart, BarChart} from "./Chart";
import hideButton from  "../assets/hideButton.svg"
import toolButton from "../assets/toolButton.svg"
import logoMin from "../assets/logoMin.svg"
import cherrypickLogo from "../assets/cherrypick_logo.svg"
import DescriptionBlock from "./Description";
import ToolMenu from "./ToolMenu";
import { useNavigate, createSearchParams, useSearchParams, generatePath, useMatch, useLocation } from "react-router-dom";
import { render } from "@testing-library/react";

import {options as options_num_addresses, optionsLimited100 as options_address_waves, data as data_address_waves} from "./ChartSettings/AddressWaves"
import {options as options_hodl_waves, data as data_hodl_waves} from "./ChartSettings/HODLWaves"
import {chartName2Settings} from "./ChartSettings/chartComponent"

import { useSubscription } from "./subscriptionContext";
import { useMemo } from 'react';


import { ButtonLogin, ButtonSignUp } from "./Navigation";

import { useTokens } from "./TokensContext";

// import { globalConfig, setGlobalConfig } from "./data";

import { cryptoIndexesV1, indexesV1, shortNames2IndexV1, shortNamesV1 } from "./data";
import { indexesV2, cryptoIndexesV2, shortNamesV2, shortNames2IndexV2 } from "./data";
import {tokensV1toV2} from "./data";


export function Paywall() {
    const { subscriptionTier } = useSubscription();

    const text = {
        0: 'Log in or sign up ' ,
        1: 'Upgrade ',
    }

    if(subscriptionTier !==2) {
    return (
        <div className="paywall-overlay" style={{position: "absolute", 
                            // width: 'calc(100% - 420px)',
                            // height: 'calc(100% - 130px)', 
                            // //  background: 'red',
                            // zIndex: 1, 
                            // // marginTop: 130,
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            // flexDirection: 'column',
                            //   backdropFilter: 'blur(5px)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            // background: 'rgba(255, 255, 255, 0.7)', /* Adjust the background as needed */
                            backdropFilter: 'blur(5px)', /* Adjust the blur as needed */
                            WebkitBackdropFilter: 'blur(5px)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '10',
                            display: 'flex',
                            flexDirection: 'column',
                            }}>
                                <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'}}> {text[subscriptionTier] + 'for a Standard plan to view this metric for all tokens.'} </h3>
                                <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'    }}> For free plan available only $LDO token</h3>
                                
                                <div style={{display:'flex', flexDirection: 'row', marginTop: '30px'}}>
                                    {(subscriptionTier === 0) && <ButtonLogin blackTheme={true} />}
                                    {(subscriptionTier === 0) && <ButtonSignUp blackTheme={true} />}
                                    {(subscriptionTier === 1) && <ButtonLogin blackTheme={true} name={'UPGRADE'} path={'/pricing'} dataAttr={'upgrade'} />}
                                </div>
                                {/* { (subscriptionTier === 1) && <ButtonLogin blackTheme={true} name={'UPGRADE'} path={'/pricing'} />} */}
                                {/* <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'    }}> or you can try to hack this page </h3>
                                <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px'}}> or just sign up!  😉🍒 </h3> */}
                                {/* ButtonSignUp withoutMargin={true} /> */}
        </div>
    )
                            }
    return null
}

const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
}

function Button(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)
    //console.log("BBUTTON", props.selected, props.index)
    const style = { 
        backgroundColor: (isMouseEnter || props.selected == props.index )? "rgba(238, 40, 138, 0.2)" : "",
        cursor: 'pointer',
        color: props.selected == props.index ? "rgba(238, 40, 138)": "white"
    }

    return (
        <div className="sliderButtons-button2" style={ style } 
            onMouseEnter={ () => setMouseEnter(true)}  
            onMouseLeave={ () => setMouseEnter(false)}
            onClick={ () => props.toggleF(props.index) }
        >
            {props.value}
        </div>
    )
}

function convertDate(date) {
    const year = date.getFullYear()
    const day = date.getDate()
    const month = months[date.getMonth()]
    return `${day} ${month} ${year}`
}

export default function Main({tokens, tokenItems}) {
    const { subscriptionTier } = useSubscription();

    const [chartsData, setChartsData] = useState({})

    const navigate = useNavigate()

    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isMobileOverview, setIsMobileOverview] = useState(window.innerWidth < 1150)
    const [isMobileBig, setIsMobileBig] = useState(window.innerWidth < 780)

    const [isRotated, setIsRotated] = useState(false)

    const [toggleDropDown, setDropDown] = useState(false)
    const [toggleToolMenu, setToolMenu] = useState(false)

    const [selectedToken, setToken] = useState("ldo")
    const [selectedVersion, setVersion] = useState('1')
    const [selectedIndex, setIndex] = useState(8)

    const [sliderValue, setValue] = useState(0);
    let [MAX, setMaxValue] = useState(200);

    const descriptionRef = useRef(null);

    // useEffect(() => {
    //     setVersion(version)
    // }, [version])
    // const [config, setConfig] = useState({indexes: indexesV1, cryptoIndexes: cryptoIndexesV1, shortNames: shortNamesV1, shortNames2Index: shortNames2IndexV1})
    const config = {
        '1': {indexes: indexesV1, cryptoIndexes: cryptoIndexesV1, shortNames: shortNamesV1, shortNames2Index: shortNames2IndexV1, indexNames: Object.entries(indexesV1).flatMap(([key, name]) => name)},
        '2': {indexes: indexesV2, cryptoIndexes: cryptoIndexesV2, shortNames: shortNamesV2, shortNames2Index: shortNames2IndexV2, indexNames: Object.entries(indexesV2).flatMap(([key, name]) => name)},
    }
    // console.log('version', version)
    const { indexes, cryptoIndexes, shortNames, shortNames2Index, indexNames } = config[selectedVersion]
    // console.log("INDEXES change", indexes, version)

    let toolsInit = {}
    console.log("Selected::INFO", selectedIndex, indexNames[selectedIndex], cryptoIndexes[indexNames[selectedIndex]])
    for (const tool in cryptoIndexes[indexNames[selectedIndex]].tools) {  
        toolsInit[tool] = tool === "Moving average" ? 4 : 0 
    }
    const [selectedTool, setTool] = useState(toolsInit)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 1270) {
            setIsMobileOverview(true)
        } else {
            setIsMobileOverview(false)
        }
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        if (window.innerWidth < 780) {
            setIsMobileBig(true)
        } else {
            setIsMobileBig(false)
        }

    }

    const handleOrientation = () => {
        setIsRotated(!isRotated)
    }
      
      // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        window.addEventListener("orientationchange", handleOrientation)
        window.addEventListener("popstate", (event) => console.log("return page"))
    })

    // Read url for rendering specific chart
    const [searchParams] = useSearchParams();   
    //const match = useMatch(":token/");
    const location = useLocation();
    //const matchIndex = useMatch("/:index");

    // useEffect(() => {
    //     console.log("Switched between version")
    //     setNewRoute(selectedToken, selectedIndex)
    //     setChart(selectedIndex, selectedToken, selectedTool)
    // }, [selectedVersion])
    
    useEffect(() => {

        const urlParams = location.pathname.split('/')
        const token = urlParams[1]
        const index = urlParams[2]

        console.log("TOKENSINDEX start", token, index, selectedToken, selectedIndex, tokens)
        let toolsInfo = {}
        let utmParams = {}
        searchParams.forEach((value, key) => {
            if(key.startsWith('utm')) {
                utmParams[key] = value
            } else {
                if((key != 'token') && (key != 'index')) {
                    toolsInfo[key] = parseInt(value)
                }
            }
        });

        if(tokens[token].version === '2') {
            console.log("TOKENSINDEX VERSION 2", index)
            // ????
            const newIndex = index===undefined ? 5: (config['2'].shortNames2Index[index]===undefined ? 5: config['2'].shortNames2Index[index])
            const newToken = token===undefined ? selectedToken: (tokens[token]===undefined ? selectedToken : token)

            let toolsInit = {}
            for (const tool in config['2'].cryptoIndexes[config['2'].indexNames[newIndex]].tools) {
                if (tool === "Moving average") {
                    toolsInit[tool] = 4
                } else if (tool === "Moving average type") {
                    toolsInit[tool] = 1
                } else if (tool === "Wallet Age") {
                    toolsInit[tool] = 4
                } else {
                    toolsInit[tool] = 0
                }
            }
            // setTool(toolsInit )
            toolsInit = Object.keys(toolsInfo).length === 0 ? toolsInit: toolsInfo

            setToken((prevToken) => {
                setIndex((prevIndex) => {
                    setVersion((prevVersion) => {
                        setTool((prevTool) => {
                            return toolsInit
                        })
                        return '2'
                    })
                    return newIndex
                })
                return newToken
            })
        } else {
            const newIndex = index===undefined ? selectedIndex: (shortNames2Index[index]===undefined ? selectedIndex: shortNames2Index[index])
            const newToken = token===undefined ? selectedToken: (tokens[token]===undefined ? selectedToken : token)
            console.log("TOKENSINDEX VERSION 1", newIndex, newToken, indexes)
            setToken(newToken)
            setIndex(newIndex)
            let toolsInit = {}
            for (const tool in cryptoIndexes[indexNames[newIndex]].tools) {  
                if (tool === "Moving average") {
                    toolsInit[tool] = 4
                } else if (tool === "Moving average type") {
                    toolsInit[tool] = 1
                } else if (tool === "Wallet Age") {
                    toolsInit[tool] = 4
                } else {
                    toolsInit[tool] = 0
                }
                // toolsInit[tool] = tool === "Moving average" ? 4 : 0  
            }
            // setTool(toolsInit )
            toolsInit = Object.keys(toolsInfo).length === 0 ? toolsInit: toolsInfo
            setTool(toolsInit)
            setChart(newIndex, newToken, toolsInit)

            setNewRoute(newToken, newIndex,  Object.keys(toolsInfo).length === 0 ? undefined : toolsInit, undefined)
        }
    }, [tokenItems]);
    // useEffect(() => {
    //     setChart();
    // }, []);


    const handleFullGuideClick = () => {
        // Logic to navigate to the full guide or display it
        console.log('Navigating to the full guide...');
        // Scroll to the description header
        if (descriptionRef.current) {
          descriptionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    function toggleMenu() {
        console.log("TOGLE")
        setDropDown((prevToggleDropDown) => {
            if(prevToggleDropDown) {
                return false
            }
            return true
        })
        // setDropDown(!toggleDropDown)
        setToolMenu(false)
    }

    function toggleTool() {
        setToolMenu(!toggleToolMenu)
        setDropDown(false)
    }

    const chartRef = useRef()    
    const [firstRealisedPriceDist, setFirst] = useState(true)
    const [dates, setDates] = useState(null)
    const [dists, setDists] = useState(null)
    // setChart();

    function setNewRoute(tokenSel, indexSel, toolSel=undefined, utmParams=undefined) {
        let searchParams = {
            token: tokenSel,
            index: shortNames[indexSel],
        }
        // navigate(`?${createSearchParams(searchParams)}`)
        const obtainedPath = generatePath("/:token/:index", searchParams)
        const navString =  (toolSel ? `${obtainedPath}?${createSearchParams(toolSel)}` : `${obtainedPath}`) + (utmParams ? `&${createSearchParams(utmParams)}` : '')
        console.log('new route', navString)
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }


    function setChart(selectedIndex, selectedToken, selectedTool) {
        // console.log("DRAAW CHART", firstRealisedPriceDist, selectedTool)
        const beforeIndex = selectedIndex
        // console.log("VERY BIG DIFFERENCE BEFORE!", selectedIndex, beforeIndex)

        const tokenVersion = tokens[selectedToken].version

        const apiVersion = ("version" in cryptoIndexes[indexNames[selectedIndex]]) ? cryptoIndexes[indexNames[selectedIndex]].version : tokens[selectedToken].version;
        console.log("NEW VERSION", apiVersion)

        let chartSelectedToken = selectedToken; // Новая переменная, базовое значение
        if (
            apiVersion != tokenVersion &&
            selectedToken in tokensV1toV2 && // 1. Есть ли ключ selectedToken
            "slugV2" in tokensV1toV2[selectedToken] && // 2. Есть ли ключ 'slugV2' в tokensV1toV2[selectedToken]
            tokensV1toV2[selectedToken].slugV2 != null // 3. Значение slugV2 не null и не undefined
        ) {
            chartSelectedToken = tokensV1toV2[selectedToken].slugV2; // Присваиваем новое значение
        }
        console.log("NEW SLUG", selectedToken, "->", chartSelectedToken)
        

        getData(selectedIndex+1, chartSelectedToken, selectedTool, cryptoIndexes[indexNames[selectedIndex]].tools, tokenVersion, apiVersion).then(([datasets, price]) => {
        // getData(selectedIndex+1, selectedToken, selectedTool, cryptoIndexes[indexNames[selectedIndex]].tools, tokens[selectedToken].version).then(([datasets, price]) => {
            // console.log("VERY BIG DIFFERENCE!", selectedIndex, beforeIndex)
            if (beforeIndex != selectedIndex) { 
                console.log("VERY BIG DIFFERENCE!")
                return
            }
            const chart = chartRef.current
            // console.log("DATA", chart.data.datasets, selectedIndex)
            chart.data.datasets[0].data = price[0]
            if (cryptoIndexes[indexNames[selectedIndex]].interactiveChart) {
                setDates(datasets[0])
                setDists(datasets[1])
                let N = datasets[2]
                const yData = datasets[1][N-1].map((value) => (value.y))
                const maxY = Math.max(...yData) 
                chart.data.datasets[2].data = [{x: new Date(datasets[0][N-1]), y: maxY}, {x: new Date(datasets[0][N-1]), y: 0.0}]
                setMaxValue(N-1)
                chart.data.datasets[1].data = datasets[1][N-1]
                // console.log("INSIDE index", firstRealisedPriceDist, selectedIndex)
                setFirst(false)
                setValue(N-1)
                // rerender(N-1)
                // chart.update()
            } else {
                for (let i=1; i < chart.data.datasets.length;i+=1) {
                    chart.data.datasets[i].data = datasets[i-1]
                    console.log("OBTAIN DATA", datasets)
                }
                // chart.update()
            }
            console.log('scale changes', selectedTool)
            if('Price Scale' in selectedTool) {
                console.log('scale changes')
                console.log(selectedTool["Price Scale"])
                console.log( selectedTool["Price Scale"] == 0 ? 'linear':'logarithmic')
                chart.options.scales.y1.type = selectedTool["Price Scale"] == 0 ? 'linear':'logarithmic'; 
            }
            // console.log("INIT DATASETS", price[0])

            chart.update()
        })
    }

    function cleanChart() {
        const chart = chartRef.current
        for (let i=0; i < chart.data.datasets.length;i+=1) {
            chart.data.datasets[i].data = []
            //console.log("OBTAIN DATA", datasets)
        }
        chart.update()
    }

    function rerender(sliderValue) {
        console.log("VAALUE", sliderValue)
        const chart = chartRef.current
        chart.data.datasets[1].data = dists[sliderValue]
        console.log("PriCe dates", chart.data.datasets[0].data, sliderValue, dates[sliderValue])
        //chart.data.datasets[2].data = [{x: new Date(dates[sliderValue]), y: 25.0}, {x: new Date(dates[sliderValue]), y: 0.0}]
        chart.data.datasets[2].data[0].x = new Date(dates[sliderValue])
        chart.data.datasets[2].data[1].x = new Date(dates[sliderValue])
        chart.update()
    }

    function zoomChart(ind) {
        const chart = chartRef.current
        const dateInterval = [7, 30, 90]
        if (ind === 3) {
            chart.resetZoom()
            chart.update()
        } else {
            const intervalData = chart.data.datasets[0].data.slice(-dateInterval[ind])
            console.log("DATES Interval", intervalData[0].x, intervalData.slice(-1)[0].x)
            const yData = intervalData.map((value) => value.y)
            const maxY = Math.max(...yData) 
            const minY = Math.min(...yData)
            console.log("MIN MAX", yData, 1.15*maxY,  0.85*minY)
            chart.zoomScale('x', {min: new Date(intervalData[0].x), max: new Date(intervalData.slice(-1)[0].x)})
            chart.zoomScale('y1', {min: 0.85*minY, max: 1.15*maxY})
        }
        //chart.zoomScale('x1', {min: 200, max: 300}, 'default');
        //
        //chart.resetZoom()
         //chart.zoom(0.5)
        // console.log("MIN MAX", chart.data.datasets[0].data.slice(-10))
        // chart.zoomScale('x', {min: new Date('2022-11-11 00:00'), max: new Date('2022-11-19 00:00')}, 'resize');
        chart.update()
    }

    function setNewIndex(index) {
        console.log("New index", index)
        //loadPlot(selectedToken)
        //chartRef.current.clear()
        setFirst(true)
        if(!cryptoIndexes[indexNames[selectedIndex]].showOverview) { cleanChart() }
        setNewRoute(selectedToken, index)
        setIndex(index)
        toolsInit = {}
        for (const tool in cryptoIndexes[indexNames[index]].tools) {
            if (tool === "Moving average") {
                toolsInit[tool] = 4
            } else if (tool === "Moving average type") {
                toolsInit[tool] = 1
            } else if (tool === "Wallet Age") {
                toolsInit[tool] = 4
            } else {
                toolsInit[tool] = 0
            }
        }
        // for (const tool in cryptoIndexes[indexNames[index]].tools) { toolsInit[tool] = tool === "Moving average" ? 4 : 0  }
        setTool(toolsInit )
        setChart(index, selectedToken, toolsInit)
        //setChart();
        // loadChartData(index, selectedToken, toolsInit)
        // render(index)
        console.log(selectedToken, indexNames[selectedIndex], cryptoIndexes[indexNames[selectedIndex]]);
    }

    function setNewToken(tokenName) {
        setFirst(true)
        const newVersion = tokens[tokenName].version
        console.log("DEBUG:", newVersion, selectedVersion)
        if(newVersion !== selectedVersion) {
            if(!cryptoIndexes[indexNames[selectedIndex]].showOverview) { cleanChart() }
            let newIndex = config[newVersion].shortNames2Index[shortNames[selectedIndex]]
            newIndex = newIndex ? newIndex : 5 // ????

            toolsInit = {}
            for (const tool in config[newVersion].cryptoIndexes[config[newVersion].indexNames[newIndex]].tools) { 
                // toolsInit[tool] = tool === "Moving average" ? 4 : 0 
                if (tool === "Moving average") {
                    toolsInit[tool] = 4
                } else if (tool === "Moving average type") {
                    toolsInit[tool] = 1
                } else if (tool === "Wallet Age") {
                    toolsInit[tool] = 4
                } else {
                    toolsInit[tool] = 0
                } 
            }
            setIndex((prevIndex) => {
                setTool((prevTool) => {
                    setVersion((prevVersion) => {
                        setToken((prevToken) => {

                            return tokenName
                        })
                        return newVersion
                    })
                    return toolsInit
                })
                return newIndex
            })
            
            console.log("RUNNNNNN OTHER STUFFF")
            
        } else {
            if(!cryptoIndexes[indexNames[selectedIndex]].showOverview) { cleanChart() }
            console.log('setNewToken', tokenName)
            if(tokenName === 'btc') {
                setNewIndex(1)
            }
            setNewRoute(tokenName, selectedIndex)
            setToken(tokenName)
            setChart(selectedIndex, tokenName, selectedTool)
        //setChart();
        //loadPlot(tokenName)
        }
    }

    function setNewTool(toolIndex, headerName) {
        console.log("Set new tool", toolIndex, headerName)
        console.log("dict", {[headerName]: toolIndex})
        //console.log("Previous tool", )
        const newTool = {...selectedTool, [headerName]: toolIndex}
        //setTool(prevTool => ({...prevTool, [headerName]: toolIndex}))
        setTool(newTool)
        setNewRoute(selectedToken, selectedIndex, ({...selectedTool, [headerName]: toolIndex}))
        setChart(selectedIndex, selectedToken, newTool);
    }

    let chartDescription = cryptoIndexes[indexNames[selectedIndex]].description 


    console.log("AAAAAA")
    if (("Cohort type" in selectedTool) && (indexNames[selectedIndex]  ).includes("Realised Price") ) {
        console.log("EEEE Realised Price - Cohorts Dict")
        console.log("ResELT", selectedIndex, selectedVersion, indexNames, cryptoIndexes)
        let selectedCohortType = cryptoIndexes[indexNames[selectedIndex]]["tools"]["Cohort type"][selectedTool["Cohort type"]]
        if (selectedCohortType == "age") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["Realised Price - Age Distribution"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] =  chartName2Settings["Realised Price - Cohorts Distribution"]["options"]
        }
        else if (selectedCohortType == "balance") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["Realised Price - Balance Distribution"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["Realised Price - Cohorts Distribution"]["options"]
        }
        else if (selectedCohortType == "balance_dollar") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["Realised Price - Balance Dollar Distribution"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["Realised Price - Cohorts Distribution"]["options"]
        }
    }
    else if (("Cohort type" in selectedTool) && ((indexNames[selectedIndex]).includes('Cohorts Distribution') || (["Number of Addresses"]).includes(indexNames[selectedIndex]))) {
        console.log("SDSDSDS")
        let selectedCohortType = cryptoIndexes[indexNames[selectedIndex]]["tools"]["Cohort type"][selectedTool["Cohort type"]]

        if (selectedCohortType == "age") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["MPLAgeCohorts"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["MPLAgeCohorts"]["options"]
        }
        else if (selectedCohortType == "balance_dollar") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["MPLBalanceDollarCohorts"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["MPLBalanceDollarCohorts"]["options"]
        }
        else {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["MPLBalanceCohorts"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["MPLBalanceCohorts"]["options"]
        }
    }
    else if (("Cohort type" in selectedTool) && ((["Balance Cohort Waves", "Realised Cap Balance Cohort Waves"]).includes(indexNames[selectedIndex]))) {
        console.log("SDSDSDS")
        let selectedCohortType = cryptoIndexes[indexNames[selectedIndex]]["tools"]["Cohort type"][selectedTool["Cohort type"]]
        if (selectedCohortType == "balance") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["BalanceCohortWaves"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["BalanceCohortWaves"]["options"]
        }
        else if (selectedCohortType == "balance_dollar") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["BalanceDollarCohortWaves"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["BalanceDollarCohortWaves"]["options"]
        }
    }
    
    console.log(cryptoIndexes[indexNames[selectedIndex]]["typeData"])
    console.log(cryptoIndexes[indexNames[selectedIndex]]["typeOptions"])

    const getBackgroundSize = () => {
        console.log("BAACKGRPUND", (sliderValue * 100) / MAX)
        return {
            backgroundSize: `${(sliderValue * 100) / MAX}% 100%`,
            width: "100%",
        };
    };

    const [selectedInterval , setInterval] = useState(3)
    function toggleInterval(ind) {
        setInterval(ind)
        zoomChart(ind)
    }

    return (
        <main className="main">
            {/* <div style={{color: 'white'}}>
                {subscriptionTier === 0 && <p>You are not logged in.</p>}
                {subscriptionTier === 1 && <p>You are logged in but don't have a valid subscription.</p>}
                {subscriptionTier === 2 && <p>You have a valid subscription. Enjoy premium features!</p>}
            </div> */}
            {isMobile && (toggleDropDown || toggleToolMenu) && <div className="blackout"></div>}
            {!isMobile && <Menu selectedToken={selectedToken} 
                  selectedIndex={selectedIndex} 
                  setNewIndex={setNewIndex}
                  setNewToken={setNewToken}
                  indexes={indexes}
                  showingIndexes={tokens[selectedToken].showingIndexes}
                  
                  tokens={tokenItems}
                  />}
            <div className="chart" style={isMobile? {flexGrow: 1, flexShrink: 1, flexBasis: 'auto'}: {}}> 
                <div className="chartHeader">
                    <div className="chartHeader-text" style={{marginLeft: isMobile? '25px': '44px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        {/* <img style={{marginLeft: "0px"}} className="tokenField-logo" src={ tokens[selectedToken].img } alt="text" /> */}
                        <img style={{marginLeft: "0px"}} className="tokenField-logo" src={ tokens[selectedToken].img == '' ? 'https://etherscan.io/images/main/empty-token.png' : tokens[selectedToken].img } alt="text" />
                        <p style={{marginLeft:"10px"}}>{ tokens[selectedToken].symbol.toUpperCase()}: { indexNames[selectedIndex] }</p>
                    </div>
                    {cryptoIndexes[indexNames[selectedIndex]].description && <div className="tooltip" style={{marginRight: 'auto', height: '100%', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {/* <p className="filter-tag" style={{backgroundColor:'transparent'}}>
                            {'ℹ️'}
                        </p> */}
                        <div style={{}}>
                            <svg class="info-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="#ffffff" stroke-width="2"/>
                                <text x="12" y="16" text-anchor="middle" font-size="12" font-family="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fill="#ffffff" font-weight="bold">i</text>
                            </svg>
                        </div>
                        {(
                            <span className="tooltiptext" style={{maxWidth: '400px', padding: '14px', flexDirection: 'column', display: 'flex'}}>
                                {/* {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description)}
                                {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0])} */}
                                {/* {<DescriptionBlock header={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0]} texts={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[1]}/>} */}
                                {'ℹ️ ' + cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[1]?.[0]}
                                <button className="full-guide-button" onClick={handleFullGuideClick}>
                                    Full Guide<span className="arrow-down">↓</span>
                                </button>
                            </span>
                        )}
                        
                    </div>}
                    <div style={{display: 'flex'}}>
                        {/* {isMobile && <img src={ toolButton } style={{marginRight:'18px', zIndex: 2}} alt="text" onClick={() => toggleTool()} /> }
                        {isMobile && <img src={ hideButton } style={{marginRight:'25px', zIndex: 2}} alt="text" onClick={() => toggleMenu()} /> } */}

                        {/* {console.log("TTOLSs", toolsInit, toolsInit.length)} */}
                        {isMobile && !toggleDropDown && !toggleToolMenu && (Object.keys(toolsInit).length !== 0) && <button class="menu-button-charts tool-menu-button" id="toolMenuButton" style={{marginRight:'8px'}}  onClick={
                                    (event) => {
                                        event.stopPropagation();
                                        toggleTool()
                                    }
                                    }>
                            <svg viewBox="0 0 24 24">
                                <path d="M19.14,12.94a1.55,1.55,0,0,0,.1-1.45l2.12-1.65a.5.5,0,0,0,.15-.54L19.9,6.36a.5.5,0,0,0-.51-.34l-2.49.2a6.78,6.78,0,0,0-1.4-1L15,2.72a.5.5,0,0,0-.49-.39H9.54a.5.5,0,0,0-.5.39l-.5,2.44a6.78,6.78,0,0,0-1.4,1L4.65,6a.5.5,0,0,0-.51.34L2.56,9.3a.5.5,0,0,0,.15.54l2.12,1.65a1.55,1.55,0,0,0,.1,1.45l-2.12,1.65a.5.5,0,0,0-.15.54L4.14,17.64a.5.5,0,0,0,.51.34l2.49-.2a6.78,6.78,0,0,0,1.4,1l.49,2.44a.5.5,0,0,0,.49.39h4.92a.5.5,0,0,0,.5-.39l.5-2.44a6.78,6.78,0,0,0,1.4-1l2.49.2a.5.5,0,0,0,.51-.34l1.22-2.92a.5.5,0,0,0-.15-.54ZM12,15.5A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/>
                            </svg>
                        </button>}

                        {isMobile && !toggleDropDown && !toggleToolMenu && <button class="menu-button-charts indexes-menu-button" id="indexesMenuButton" style={{marginRight:'25px'}} 
                                onClick={(event) => {
                                    console.log("EVENT PROP STOP")
                                    event.stopPropagation();
                                    if(!toggleDropDown) {
                                        console.log('try fry', toggleDropDown); 
                                        toggleMenu()

                                    }}}>
                            <svg viewBox="0 0 24 24">
                                <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"/>
                            </svg>
                        </button> }

                        {isMobile &&  (toggleDropDown || toggleToolMenu) && <button
                                        className="menu-button-charts"
                                        style={{marginRight:'25px'}}
                                        onClick={{}}
                                    >
                                <svg viewBox="0 0 24 24">
                                <path d="M18.36 6.64a1 1 0 0 0-1.41 0L12 11.59 7.05 6.64a1 1 0 1 0-1.41 1.41L10.59 13l-4.95 4.95a1 1 0 0 0 1.41 1.41L12 14.41l4.95 4.95a1 1 0 0 0 1.41-1.41L13.41 13l4.95-4.95a1 1 0 0 0 0-1.41z" />
                                </svg>
                            </button>}
                    </div>
                    {/* {!isMobile && 
                    <a href="https://cherry-pick.xyz">
                        <img src={ cherrypickLogo } style={{marginRight:'30px', height: '40px'}} alt="text" /> 
                    </a>
                    } */}
                </div>
                {isMobile && toggleDropDown &&

                <Menu selectedToken={selectedToken} 
                  selectedIndex={selectedIndex} 
                  setNewIndex={setNewIndex}
                  setNewToken={setNewToken} 
                  isMobile={isMobile}
                  indexes={indexes}
                  toggleMobile={toggleMenu}
                  showingIndexes={tokens[selectedToken].showingIndexes}
                  tokens={tokenItems}/>
                  
                }
                {/* {isMobile && toggleDropDown && <img src={ hideButton } style={{top:'85.5px' , zIndex: 102, right:'25px', position:"absolute"}} alt="text" onClick={() => toggleMenu()} /> } */}
                {isMobile && toggleToolMenu && cryptoIndexes[indexNames[selectedIndex]].showTools && 
                <div className="chartTool" style={{position:"absolute", backgroundColor: "#060111"}}>
                        <ToolMenu 
                    tools={cryptoIndexes[indexNames[selectedIndex]].tools}
                    selectedIndex={selectedTool} 
                    setNewIndex={setNewTool}
                    toggleTool={toggleTool}
                    isMobile={isMobile}
                    />
                    </div>
  
                }
                {/* {isMobile && toggleDropDown && cryptoIndexes[indexNames[selectedIndex]].showTools && 
                <div className="chartTool">
                        <ToolMenu 
                    tools={cryptoIndexes[indexNames[selectedIndex]].tools}
                    selectedIndex={selectedTool} 
                    setNewIndex={setNewTool} />
                    </div>} */}
                {cryptoIndexes[indexNames[selectedIndex]].showOverview && <Overview token={selectedToken} 
                                                                                    ismobile={isMobileOverview} 
                                                                                    ismobileBig={isMobileBig} 
                                                                                    withoutMenu={isMobile}
                                                                                    setNewIndex={setNewIndex}/>}
                {!cryptoIndexes[indexNames[selectedIndex]].showOverview && <div className="chartBody">
                    <div className="chartPlot">
                        { !isRotated && <div className="chartPlot-plot" style={isMobile? {marginTop: '0px', flexGrow: 0, flexShrink: 0, flexBasis: '60vh', width:'calc(100% - 40px)'}: {}}>
                            {(subscriptionTier !== 2) && (subscriptionTier !== 3) && (selectedToken !== 'ldo') && <Paywall />}
                            {<Chart indexName={indexNames[selectedIndex]} chartRef={chartRef} isMobile={isMobile} />}
                        </div> }
                    
                        {
                            isRotated && 
                            <div className="chartPlot-plot" style={isMobile? {marginTop: '0px', flexGrow: 0, flexShrink: 0, flexBasis: '260px', width:'calc(100% - 40px)'}: {}}>
                               
                                <Chart indexName={indexNames[selectedIndex]} chartRef={chartRef} isMobile={isMobile} />
                            </div>
                        }
                        {(cryptoIndexes[indexNames[selectedIndex]].interactiveChart) && <div className="slideWrapper">
                            <div className="slider" >
                                <input type="range" min="0" max={MAX} style={getBackgroundSize()} onChange={(e) => {
                                    setValue(e.target.value)
                                    rerender(e.target.value)
                                }} value={sliderValue} />
                                <div className="sliderText">
                                <p style={{marginRight: "0px"}}> { dates===null? convertDate(new Date()): convertDate(new Date(dates[sliderValue])) }</p>
                                </div>
                            </div>
                            <div className="sliderButtons-wrapper">
                                <div className="sliderButtons">
                                {["7D", "1M", "3M", "ALL"].map((value, index) => {return (<Button index={index} toggleF={toggleInterval} value={value} selected={selectedInterval}/>)})} 
                            </div>

                            </div>
                        </div>}
                        <div className="description" style={{ marginBottom: isMobile? "150px": "", marginTop: isMobile? "10px": ""}}>
                            {/* <div className="description-header">

                                Description
                            </div> */}
                            <div className="descriptionHeader"  ref={descriptionRef}>
                                <div className="descriptionHeader-text">
                                    Description
                                </div>
                                <a href="https://cherry-pick.xyz">
                                    {isMobile && <img src={ logoMin } style={{marginRight:'40px', height: '30px', marginTop: '7px'}} alt="text"  /> }

                                </a>
                            </div>
                            { console.log("NAME", selectedIndex, indexNames[selectedIndex])}
                            { cryptoIndexes[indexNames[selectedIndex]].description.map(([header, texts]) => {return (<DescriptionBlock header={header} texts={texts} />)})}
                            {/* { indexDescriptions[selectedIndex].map(([header, texts]) => {return (<DescriptionBlock header={header} texts={texts} />)})} */}
                            { (indexNames[selectedIndex] === "SOPR in Profit / Loss") && <div className="descriptionBlock">
                                <p className="descriptionBlock-text" style={{marginTop: "",
                                                            marginBottom: "16px"}}> {"The metric was suggested by "} <a style={{color: "#EE288A"}} href="https://twitter.com/SwellCycle">@SwellCycle</a>. </p>
                            </div>}
                            <DescriptionBlock header={true} texts='Congratulations! 🎉' />
                            <DescriptionBlock header={false} texts={['You have read the index description in full, so now you can improve your investment and trading decisions using it. 😎 🤑']} marginB={'100px'}/>
                            {/* <DescriptionBlock header={false} texts={['MPL is the total value of profits and losses in dollar terms for all coins traded at a given time. A coin is said to be trading at a profit if its purchase price is lower than the current market price. A coin is traded at a loss if its buy price is higher than the current market price.']} /> 
                            <DescriptionBlock header={true} texts={'How do I use MPL in trading ?'} />
                            <DescriptionBlock header={false} texts={['Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.', 
                                                                    'Identifying local/global price lows. A steady excess of losses over profits signals a bearish market sentiment. When losses reach their local/global peak, it means that fixation of losses has decreased, and the large amount of capital has moved to other players, waiting for the future growth. Consequently, we can expect a change in market sentiment and a future global/local market reversal. In this approach the best approach is the 14/30 day moving average, because it is less sensitive to random noise.',
                                                                    'Identify the big players sentiment. The big players in the market are addresses with a balance of $10M or more. Among investors of this scale, there are often hedge funds and institutions which have high expertise and are able to influence the market. By choosing a $10M+ cohort one can see the values of profits and losses and determine the strategy of this group: holding funds, fixing losses or profits.']} /> */}
                            {/* <div className="descriptionHeader">
                                <div className="descriptionHeader-text">
                                    Description
                                </div>
                            </div>
                            <div className="descriptionBlock">
                                <p className="descriptionBlock-text" style={{marginTop:"22px", marginBottom:"16px"}}>
                                    MPL is the total value of profits and losses in dollar terms for all coins traded at a given time. A coin is said to be trading at a profit if its purchase price is lower than the current market price. A coin is traded at a loss if its buy price is higher than the current market price.
                                </p>
                            </div>
                            <div className="descriptionBlock">
                            <div className="descriptionBlock-header">
                                <p style={{marginBottom: '10px'}}>How do I use MPL in trading ?</p>
                            </div>
                            </div>
                            <div className="descriptionBlock">
                                <p className="descriptionBlock-text" style={{marginTop:"22px"}}> 
                                    Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
                                </p>
                                <p className="descriptionBlock-text"> 
                                    Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
                                </p>
                                <p className="descriptionBlock-text" style={{marginBottom:"16px"}}> 
                                    Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
                                </p>
                            </div> */}
                            {/* <div className="description-text">
                                { indexDescriptions[selectedIndex] }
                            </div> */}
                        </div>
                    </div>
                    {/* {selectedIndex in [1, 2, 3] && <div className="chartTool"> */}
                    { !isMobile && cryptoIndexes[indexNames[selectedIndex]].showTools && <div className="chartTool">
                        <ToolMenu 
                    tools={cryptoIndexes[indexNames[selectedIndex]].tools}
                    selectedIndex={selectedTool} 
                    setNewIndex={setNewTool} />
                    </div>}
                </div>}
            </div>
        </main>
    )
}

export function MainNew({tokens, tokenItems}) {
    const { subscriptionTier } = useSubscription();

    const [chartsData, setChartsData] = useState({})

    const navigate = useNavigate()

    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isMobileOverview, setIsMobileOverview] = useState(window.innerWidth < 1150)
    const [isMobileBig, setIsMobileBig] = useState(window.innerWidth < 780)

    const [isRotated, setIsRotated] = useState(false)

    const [toggleDropDown, setDropDown] = useState(false)
    const [toggleToolMenu, setToolMenu] = useState(false)

    const [selectedToken, setToken] = useState("lido-dao")
    const [selectedVersion, setVersion] = useState('2')
    const [selectedIndex, setIndex] = useState(1)

    const [sliderValue, setValue] = useState(0);
    let [MAX, setMaxValue] = useState(200);

    const descriptionRef = useRef(null);

    // useEffect(() => {
    //     setVersion(version)
    // }, [version])
    // const [config, setConfig] = useState({indexes: indexesV1, cryptoIndexes: cryptoIndexesV1, shortNames: shortNamesV1, shortNames2Index: shortNames2IndexV1})
    const config = {
        '1': {indexes: indexesV1, cryptoIndexes: cryptoIndexesV1, shortNames: shortNamesV1, shortNames2Index: shortNames2IndexV1, indexNames: Object.entries(indexesV1).flatMap(([key, name]) => name)},
        '2': {indexes: indexesV2, cryptoIndexes: cryptoIndexesV2, shortNames: shortNamesV2, shortNames2Index: shortNames2IndexV2, indexNames: Object.entries(indexesV2).flatMap(([key, name]) => name)},
    }
    // console.log('version', version)
    const { indexes, cryptoIndexes, shortNames, shortNames2Index, indexNames } = config[selectedVersion]
    // console.log("INDEXES change", indexes, version)

    let toolsInit = {}
    console.log("Selected MAIN NEW::INFO", selectedIndex, indexNames[selectedIndex], cryptoIndexes[indexNames[selectedIndex]])
    for (const tool in cryptoIndexes[indexNames[selectedIndex]].tools) {  
        toolsInit[tool] = tool === "Moving average" ? 4 : 0 
    }
    const [selectedTool, setTool] = useState(toolsInit)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 1270) {
            setIsMobileOverview(true)
        } else {
            setIsMobileOverview(false)
        }
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        if (window.innerWidth < 780) {
            setIsMobileBig(true)
        } else {
            setIsMobileBig(false)
        }

    }

    const handleOrientation = () => {
        setIsRotated(!isRotated)
    }
      
      // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        window.addEventListener("orientationchange", handleOrientation)
        window.addEventListener("popstate", (event) => console.log("return page"))
    })

    // Read url for rendering specific chart
    const [searchParams] = useSearchParams();   
    //const match = useMatch(":token/");
    const location = useLocation();
    //const matchIndex = useMatch("/:index");

    // useEffect(() => {
    //     console.log("Switched between version")
    //     console.log('set new route')
    //     setNewRoute(selectedToken, selectedIndex)
    //     setChart(selectedIndex, selectedToken, selectedTool)
    // }, [selectedVersion])
    
    useEffect(() => {

        const urlParams = location.pathname.split('/')
        const token = urlParams[1]
        const index = urlParams[2]

        console.log("TOKENSINDEX start", token, index, selectedToken, selectedIndex, tokens)
        let toolsInfo = {}
        let utmParams = {}
        searchParams.forEach((value, key) => {
            if(key.startsWith('utm')) {
                utmParams[key] = value
            } else {
                if((key != 'token') && (key != 'index')) {
                    toolsInfo[key] = parseInt(value)
                }
            }
        });

        if(tokens[token].version === '2') {
            console.log("TOKENSINDEX VERSION 2", index)
            // ????
            const newIndex = index===undefined ? 5: (config['2'].shortNames2Index[index]===undefined ? 5: config['2'].shortNames2Index[index])
            const newToken = token===undefined ? selectedToken: (tokens[token]===undefined ? selectedToken : token)

            let toolsInit = {}
            for (const tool in config['2'].cryptoIndexes[config['2'].indexNames[newIndex]].tools) {
                if (tool === "Moving average") {
                    toolsInit[tool] = 4
                } else if (tool === "Moving average type") {
                    toolsInit[tool] = 1
                } else if (tool === "Wallet Age") {
                    toolsInit[tool] = 4
                } else {
                    toolsInit[tool] = 0
                }
            }
            // setTool(toolsInit )
            toolsInit = Object.keys(toolsInfo).length === 0 ? toolsInit: toolsInfo
            console.log("TOKENSINDEX VERSION 2", newIndex, newToken, toolsInit)
            setToken((prevToken) => {
                setIndex((prevIndex) => {
                    setVersion((prevVersion) => {
                        setTool((prevTool) => {
                            return toolsInit
                        })
                        return '2'
                    })
                    return newIndex
                })
                return newToken
            })
            setChart(newIndex, newToken, toolsInit)
            setNewRoute(newToken, newIndex,  Object.keys(toolsInfo).length === 0 ? undefined : toolsInit, undefined)
        } else {
            const newIndex = index===undefined ? selectedIndex: (shortNames2Index[index]===undefined ? selectedIndex: shortNames2Index[index])
            const newToken = token===undefined ? selectedToken: (tokens[token]===undefined ? selectedToken : token)
            console.log("TOKENSINDEX VERSION 1", newIndex, newToken, indexes)
            setToken(newToken)
            setIndex(newIndex)
            let toolsInit = {}
            for (const tool in cryptoIndexes[indexNames[newIndex]].tools) {  
                if (tool === "Moving average") {
                    toolsInit[tool] = 4
                } else if (tool === "Moving average type") {
                    toolsInit[tool] = 1
                } else if (tool === "Wallet Age") {
                    toolsInit[tool] = 4
                } else {
                    toolsInit[tool] = 0
                }
                // toolsInit[tool] = tool === "Moving average" ? 4 : 0  
            }
            // setTool(toolsInit )
            toolsInit = Object.keys(toolsInfo).length === 0 ? toolsInit: toolsInfo
            setTool(toolsInit)
            setChart(newIndex, newToken, toolsInit)

            setNewRoute(newToken, newIndex,  Object.keys(toolsInfo).length === 0 ? undefined : toolsInit, undefined)
        }
    }, [tokenItems]);
    // useEffect(() => {
    //     setChart();
    // }, []);


    const handleFullGuideClick = () => {
        // Logic to navigate to the full guide or display it
        console.log('Navigating to the full guide...');
        // Scroll to the description header
        if (descriptionRef.current) {
          descriptionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    function toggleMenu() {
        console.log("TOGLE")
        setDropDown((prevToggleDropDown) => {
            if(prevToggleDropDown) {
                return false
            }
            return true
        })
        // setDropDown(!toggleDropDown)
        setToolMenu(false)
    }

    function toggleTool() {
        setToolMenu(!toggleToolMenu)
        setDropDown(false)
    }

    const chartRef = useRef()    
    const [firstRealisedPriceDist, setFirst] = useState(true)
    const [dates, setDates] = useState(null)
    const [dists, setDists] = useState(null)
    // setChart();

    function setNewRoute(tokenSel, indexSel, toolSel=undefined, utmParams=undefined) {
        let searchParams = {
            token: tokenSel,
            index: shortNames[indexSel],
        }
        // navigate(`?${createSearchParams(searchParams)}`)
        const obtainedPath = generatePath("/:token/:index", searchParams)
        const navString =  (toolSel ? `${obtainedPath}?${createSearchParams(toolSel)}` : `${obtainedPath}`) + (utmParams ? `&${createSearchParams(utmParams)}` : '')
        console.log('new route', navString)
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }


    function setChart(selectedIndex, selectedToken, selectedTool) {
        console.log("DRAAW CHART", firstRealisedPriceDist, selectedTool)
        const beforeIndex = selectedIndex
        // console.log("VERY BIG DIFFERENCE BEFORE!", selectedIndex, beforeIndex)

        const tokenVersion = tokens[selectedToken].version

        const apiVersion = ("version" in cryptoIndexes[indexNames[selectedIndex]]) ? cryptoIndexes[indexNames[selectedIndex]].version : tokens[selectedToken].version;
        console.log("NEW VERSION", apiVersion)

        let chartSelectedToken = selectedToken; // Новая переменная, базовое значение
        if (
            apiVersion != tokenVersion &&
            selectedToken in tokensV1toV2 && // 1. Есть ли ключ selectedToken
            "slugV2" in tokensV1toV2[selectedToken] && // 2. Есть ли ключ 'slugV2' в tokensV1toV2[selectedToken]
            tokensV1toV2[selectedToken].slugV2 != null // 3. Значение slugV2 не null и не undefined
        ) {
            chartSelectedToken = tokensV1toV2[selectedToken].slugV2; // Присваиваем новое значение
        }
        console.log("NEW SLUG", selectedToken, "->", chartSelectedToken)
        

        getData(selectedIndex+1, chartSelectedToken, selectedTool, cryptoIndexes[indexNames[selectedIndex]].tools, tokenVersion, apiVersion).then(([datasets, price]) => {
        // getData(selectedIndex+1, selectedToken, selectedTool, cryptoIndexes[indexNames[selectedIndex]].tools, tokens[selectedToken].version).then(([datasets, price]) => {
            // console.log("VERY BIG DIFFERENCE!", selectedIndex, beforeIndex)
            if (beforeIndex != selectedIndex) { 
                console.log("VERY BIG DIFFERENCE!")
                return
            }
            const chart = chartRef.current
            // console.log("DATA", chart.data.datasets, selectedIndex)
            chart.data.datasets[0].data = price[0]
            if (cryptoIndexes[indexNames[selectedIndex]].interactiveChart) {
                setDates(datasets[0])
                setDists(datasets[1])
                let N = datasets[2]
                const yData = datasets[1][N-1].map((value) => (value.y))
                const maxY = Math.max(...yData) 
                chart.data.datasets[2].data = [{x: new Date(datasets[0][N-1]), y: maxY}, {x: new Date(datasets[0][N-1]), y: 0.0}]
                setMaxValue(N-1)
                chart.data.datasets[1].data = datasets[1][N-1]
                // console.log("INSIDE index", firstRealisedPriceDist, selectedIndex)
                setFirst(false)
                setValue(N-1)
                // rerender(N-1)
                // chart.update()
            } else {
                for (let i=1; i < chart.data.datasets.length;i+=1) {
                    chart.data.datasets[i].data = datasets[i-1]
                    console.log("OBTAIN DATA", datasets)
                }
                // chart.update()
            }
            console.log('scale changes', selectedTool)
            if('Price Scale' in selectedTool) {
                console.log('scale changes')
                console.log(selectedTool["Price Scale"])
                console.log( selectedTool["Price Scale"] == 0 ? 'linear':'logarithmic')
                chart.options.scales.y1.type = selectedTool["Price Scale"] == 0 ? 'linear':'logarithmic'; 
            }
            // console.log("INIT DATASETS", price[0])

            chart.update()
        })
    }

    function cleanChart() {
        const chart = chartRef.current
        for (let i=0; i < chart.data.datasets.length;i+=1) {
            chart.data.datasets[i].data = []
            //console.log("OBTAIN DATA", datasets)
        }
        chart.update()
    }

    function rerender(sliderValue) {
        console.log("VAALUE", sliderValue)
        const chart = chartRef.current
        chart.data.datasets[1].data = dists[sliderValue]
        console.log("PriCe dates", chart.data.datasets[0].data, sliderValue, dates[sliderValue])
        //chart.data.datasets[2].data = [{x: new Date(dates[sliderValue]), y: 25.0}, {x: new Date(dates[sliderValue]), y: 0.0}]
        chart.data.datasets[2].data[0].x = new Date(dates[sliderValue])
        chart.data.datasets[2].data[1].x = new Date(dates[sliderValue])
        chart.update()
    }

    function zoomChart(ind) {
        const chart = chartRef.current
        const dateInterval = [7, 30, 90]
        if (ind === 3) {
            chart.resetZoom()
            chart.update()
        } else {
            const intervalData = chart.data.datasets[0].data.slice(-dateInterval[ind])
            console.log("DATES Interval", intervalData[0].x, intervalData.slice(-1)[0].x)
            const yData = intervalData.map((value) => value.y)
            const maxY = Math.max(...yData) 
            const minY = Math.min(...yData)
            console.log("MIN MAX", yData, 1.15*maxY,  0.85*minY)
            chart.zoomScale('x', {min: new Date(intervalData[0].x), max: new Date(intervalData.slice(-1)[0].x)})
            chart.zoomScale('y1', {min: 0.85*minY, max: 1.15*maxY})
        }
        //chart.zoomScale('x1', {min: 200, max: 300}, 'default');
        //
        //chart.resetZoom()
         //chart.zoom(0.5)
        // console.log("MIN MAX", chart.data.datasets[0].data.slice(-10))
        // chart.zoomScale('x', {min: new Date('2022-11-11 00:00'), max: new Date('2022-11-19 00:00')}, 'resize');
        chart.update()
    }

    function setNewIndex(index) {
        console.log("New index", index)
        //loadPlot(selectedToken)
        //chartRef.current.clear()
        setFirst(true)
        if(!cryptoIndexes[indexNames[selectedIndex]].showOverview) { cleanChart() }
        setNewRoute(selectedToken, index)
        setIndex(index)
        toolsInit = {}
        for (const tool in cryptoIndexes[indexNames[index]].tools) {
            if (tool === "Moving average") {
                toolsInit[tool] = 4
            } else if (tool === "Moving average type") {
                toolsInit[tool] = 1
            } else if (tool === "Wallet Age") {
                toolsInit[tool] = 4
            } else {
                toolsInit[tool] = 0
            }
        }
        // for (const tool in cryptoIndexes[indexNames[index]].tools) { toolsInit[tool] = tool === "Moving average" ? 4 : 0  }
        setTool(toolsInit )
        setChart(index, selectedToken, toolsInit)
        //setChart();
        // loadChartData(index, selectedToken, toolsInit)
        // render(index)
        console.log(selectedToken, indexNames[selectedIndex], cryptoIndexes[indexNames[selectedIndex]]);
    }

    function setNewToken(tokenName) {
        setFirst(true)
        const newVersion = tokens[tokenName].version
        console.log("DEBUG:", newVersion, selectedVersion)
        
        if(newVersion !== selectedVersion) {
            if(!cryptoIndexes[indexNames[selectedIndex]].showOverview) { cleanChart() }
            let newIndex = config[newVersion].shortNames2Index[shortNames[selectedIndex]]
            newIndex = newIndex ? newIndex : 5 // ????

            toolsInit = {}
            for (const tool in config[newVersion].cryptoIndexes[config[newVersion].indexNames[newIndex]].tools) { 
                // toolsInit[tool] = tool === "Moving average" ? 4 : 0 
                if (tool === "Moving average") {
                    toolsInit[tool] = 4
                } else if (tool === "Moving average type") {
                    toolsInit[tool] = 1
                } else if (tool === "Wallet Age") {
                    toolsInit[tool] = 4
                } else {
                    toolsInit[tool] = 0
                } 
            }
            setIndex((prevIndex) => {
                setTool((prevTool) => {
                    setVersion((prevVersion) => {
                        setToken((prevToken) => {

                            return tokenName
                        })
                        return newVersion
                    })
                    return toolsInit
                })
                return newIndex
            })
            
            console.log("RUNNNNNN OTHER STUFFF")
            
        } else {
            if(!cryptoIndexes[indexNames[selectedIndex]].showOverview) { cleanChart() }
            console.log('setNewToken', tokenName)
            if(tokenName === 'btc') {
                setNewIndex(1)
            }
            setNewRoute(tokenName, selectedIndex)
            setToken(tokenName)
            setChart(selectedIndex, tokenName, selectedTool)
        //setChart();
        //loadPlot(tokenName)
        }
    }

    function setNewTool(toolIndex, headerName) {
        console.log("Set new tool", toolIndex, headerName)
        console.log("dict", {[headerName]: toolIndex})
        //console.log("Previous tool", )
        const newTool = {...selectedTool, [headerName]: toolIndex}
        //setTool(prevTool => ({...prevTool, [headerName]: toolIndex}))
        setTool(newTool)
        setNewRoute(selectedToken, selectedIndex, ({...selectedTool, [headerName]: toolIndex}))
        setChart(selectedIndex, selectedToken, newTool);
    }

    let chartDescription = cryptoIndexes[indexNames[selectedIndex]].description 


    console.log("AAAAAA")
    if (("Cohort type" in selectedTool) && (indexNames[selectedIndex]  ).includes("Realised Price") ) {
        console.log("EEEE Realised Price - Cohorts Dict")
        console.log("ResELT", selectedIndex, selectedVersion, indexNames, cryptoIndexes)
        let selectedCohortType = cryptoIndexes[indexNames[selectedIndex]]["tools"]["Cohort type"][selectedTool["Cohort type"]]
        if (selectedCohortType == "age") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["Realised Price - Age Distribution"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] =  chartName2Settings["Realised Price - Cohorts Distribution"]["options"]
        }
        else if (selectedCohortType == "balance") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["Realised Price - Balance Distribution"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["Realised Price - Cohorts Distribution"]["options"]
        }
        else if (selectedCohortType == "balance_dollar") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["Realised Price - Balance Dollar Distribution"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["Realised Price - Cohorts Distribution"]["options"]
        }
    }
    else if (("Cohort type" in selectedTool) && ((indexNames[selectedIndex]).includes('Cohorts Distribution') || (["Number of Addresses"]).includes(indexNames[selectedIndex]))) {
        console.log("SDSDSDS")
        let selectedCohortType = cryptoIndexes[indexNames[selectedIndex]]["tools"]["Cohort type"][selectedTool["Cohort type"]]

        if (selectedCohortType == "age") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["MPLAgeCohorts"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["MPLAgeCohorts"]["options"]
        }
        else if (selectedCohortType == "balance_dollar") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["MPLBalanceDollarCohorts"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["MPLBalanceDollarCohorts"]["options"]
        }
        else {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["MPLBalanceCohorts"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["MPLBalanceCohorts"]["options"]
        }
    }
    else if (("Cohort type" in selectedTool) && ((["Balance Cohort Waves", "Realised Cap Balance Cohort Waves"]).includes(indexNames[selectedIndex]))) {
        console.log("SDSDSDS")
        let selectedCohortType = cryptoIndexes[indexNames[selectedIndex]]["tools"]["Cohort type"][selectedTool["Cohort type"]]
        if (selectedCohortType == "balance") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["BalanceCohortWaves"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["BalanceCohortWaves"]["options"]
        }
        else if (selectedCohortType == "balance_dollar") {
            cryptoIndexes[indexNames[selectedIndex]]["typeData"] = chartName2Settings["BalanceDollarCohortWaves"]["data"]
            cryptoIndexes[indexNames[selectedIndex]]["typeOptions"] = chartName2Settings["BalanceDollarCohortWaves"]["options"]
        }
    }
    
    console.log(cryptoIndexes[indexNames[selectedIndex]]["typeData"])
    console.log(cryptoIndexes[indexNames[selectedIndex]]["typeOptions"])

    const getBackgroundSize = () => {
        console.log("BAACKGRPUND", (sliderValue * 100) / MAX)
        return {
            backgroundSize: `${(sliderValue * 100) / MAX}% 100%`,
            width: "100%",
        };
    };

    const [selectedInterval , setInterval] = useState(3)
    function toggleInterval(ind) {
        setInterval(ind)
        zoomChart(ind)
    }
    console.log('TOKEN PARAMS')
    console.log(tokens[selectedToken])

    return (
        <main className="main">
            {/* <div style={{color: 'white'}}>
                {subscriptionTier === 0 && <p>You are not logged in.</p>}
                {subscriptionTier === 1 && <p>You are logged in but don't have a valid subscription.</p>}
                {subscriptionTier === 2 && <p>You have a valid subscription. Enjoy premium features!</p>}
            </div> */}
            {isMobile && (toggleDropDown || toggleToolMenu) && <div className="blackout"></div>}
            {!isMobile && <Menu selectedToken={selectedToken} 
                  selectedIndex={selectedIndex} 
                  setNewIndex={setNewIndex}
                  setNewToken={setNewToken}
                  indexes={indexes}
                  showingIndexes={tokens[selectedToken].showingIndexes}
                  
                  tokens={tokenItems}
                  />}
            <div className="chart" style={isMobile? {flexGrow: 1, flexShrink: 1, flexBasis: 'auto'}: {}}> 
                <div className="chartHeader">
                    <div className="chartHeader-text" style={{marginLeft: isMobile? '25px': '44px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        {/* <img style={{marginLeft: "0px"}} className="tokenField-logo" src={ tokens[selectedToken].img } alt="text" /> */}
                        <img style={{marginLeft: "0px"}} className="tokenField-logo" src={ tokens[selectedToken].img == '' ? 'https://etherscan.io/images/main/empty-token.png' : tokens[selectedToken].img } alt="text" />
                        <p style={{marginLeft:"10px"}}>{ tokens[selectedToken].symbol.toUpperCase()}: { indexNames[selectedIndex] }</p>
                    </div>
                    {cryptoIndexes[indexNames[selectedIndex]].description && <div className="tooltip" style={{marginRight: 'auto', height: '100%', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {/* <p className="filter-tag" style={{backgroundColor:'transparent'}}>
                            {'ℹ️'}
                        </p> */}
                        <div style={{}}>
                            <svg class="info-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="#ffffff" stroke-width="2"/>
                                <text x="12" y="16" text-anchor="middle" font-size="12" font-family="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fill="#ffffff" font-weight="bold">i</text>
                            </svg>
                        </div>
                        {(
                            <span className="tooltiptext" style={{maxWidth: '400px', padding: '14px', flexDirection: 'column', display: 'flex'}}>
                                {/* {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description)}
                                {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0])} */}
                                {/* {<DescriptionBlock header={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0]} texts={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[1]}/>} */}
                                {'ℹ️ ' + cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[1]?.[0]}
                                <button className="full-guide-button" onClick={handleFullGuideClick}>
                                    Full Guide<span className="arrow-down">↓</span>
                                </button>
                            </span>
                        )}
                        
                    </div>}
                    <div style={{display: 'flex'}}>
                        {/* {isMobile && <img src={ toolButton } style={{marginRight:'18px', zIndex: 2}} alt="text" onClick={() => toggleTool()} /> }
                        {isMobile && <img src={ hideButton } style={{marginRight:'25px', zIndex: 2}} alt="text" onClick={() => toggleMenu()} /> } */}

                        {/* {console.log("TTOLSs", toolsInit, toolsInit.length)} */}
                        {isMobile && !toggleDropDown && !toggleToolMenu && (Object.keys(toolsInit).length !== 0) && <button class="menu-button-charts tool-menu-button" id="toolMenuButton" style={{marginRight:'8px'}}  onClick={
                                    (event) => {
                                        event.stopPropagation();
                                        toggleTool()
                                    }
                                    }>
                            <svg viewBox="0 0 24 24">
                                <path d="M19.14,12.94a1.55,1.55,0,0,0,.1-1.45l2.12-1.65a.5.5,0,0,0,.15-.54L19.9,6.36a.5.5,0,0,0-.51-.34l-2.49.2a6.78,6.78,0,0,0-1.4-1L15,2.72a.5.5,0,0,0-.49-.39H9.54a.5.5,0,0,0-.5.39l-.5,2.44a6.78,6.78,0,0,0-1.4,1L4.65,6a.5.5,0,0,0-.51.34L2.56,9.3a.5.5,0,0,0,.15.54l2.12,1.65a1.55,1.55,0,0,0,.1,1.45l-2.12,1.65a.5.5,0,0,0-.15.54L4.14,17.64a.5.5,0,0,0,.51.34l2.49-.2a6.78,6.78,0,0,0,1.4,1l.49,2.44a.5.5,0,0,0,.49.39h4.92a.5.5,0,0,0,.5-.39l.5-2.44a6.78,6.78,0,0,0,1.4-1l2.49.2a.5.5,0,0,0,.51-.34l1.22-2.92a.5.5,0,0,0-.15-.54ZM12,15.5A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/>
                            </svg>
                        </button>}

                        {isMobile && !toggleDropDown && !toggleToolMenu && <button class="menu-button-charts indexes-menu-button" id="indexesMenuButton" style={{marginRight:'25px'}} 
                                onClick={(event) => {
                                    console.log("EVENT PROP STOP")
                                    event.stopPropagation();
                                    if(!toggleDropDown) {
                                        console.log('try fry', toggleDropDown); 
                                        toggleMenu()

                                    }}}>
                            <svg viewBox="0 0 24 24">
                                <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"/>
                            </svg>
                        </button> }

                        {isMobile &&  (toggleDropDown || toggleToolMenu) && <button
                                        className="menu-button-charts"
                                        style={{marginRight:'25px'}}
                                        onClick={{}}
                                    >
                                <svg viewBox="0 0 24 24">
                                <path d="M18.36 6.64a1 1 0 0 0-1.41 0L12 11.59 7.05 6.64a1 1 0 1 0-1.41 1.41L10.59 13l-4.95 4.95a1 1 0 0 0 1.41 1.41L12 14.41l4.95 4.95a1 1 0 0 0 1.41-1.41L13.41 13l4.95-4.95a1 1 0 0 0 0-1.41z" />
                                </svg>
                            </button>}
                    </div>
                    {/* {!isMobile && 
                    <a href="https://cherry-pick.xyz">
                        <img src={ cherrypickLogo } style={{marginRight:'30px', height: '40px'}} alt="text" /> 
                    </a>
                    } */}
                </div>
                {isMobile && toggleDropDown &&

                <Menu selectedToken={selectedToken} 
                  selectedIndex={selectedIndex} 
                  setNewIndex={setNewIndex}
                  setNewToken={setNewToken} 
                  isMobile={isMobile}
                  indexes={indexes}
                  toggleMobile={toggleMenu}
                  showingIndexes={tokens[selectedToken].showingIndexes}
                  tokens={tokenItems}/>
                  
                }
                {/* {isMobile && toggleDropDown && <img src={ hideButton } style={{top:'85.5px' , zIndex: 102, right:'25px', position:"absolute"}} alt="text" onClick={() => toggleMenu()} /> } */}
                {isMobile && toggleToolMenu && cryptoIndexes[indexNames[selectedIndex]].showTools && 
                <div className="chartTool" style={{position:"absolute", backgroundColor: "#060111"}}>
                        <ToolMenu 
                    tools={cryptoIndexes[indexNames[selectedIndex]].tools}
                    selectedIndex={selectedTool} 
                    setNewIndex={setNewTool}
                    toggleTool={toggleTool}
                    isMobile={isMobile}
                    />
                    </div>
  
                }
                {/* {isMobile && toggleDropDown && cryptoIndexes[indexNames[selectedIndex]].showTools && 
                <div className="chartTool">
                        <ToolMenu 
                    tools={cryptoIndexes[indexNames[selectedIndex]].tools}
                    selectedIndex={selectedTool} 
                    setNewIndex={setNewTool} />
                    </div>} */}
                {cryptoIndexes[indexNames[selectedIndex]].showOverview && <Overview token={selectedToken} 
                                                                                    ismobile={isMobileOverview} 
                                                                                    ismobileBig={isMobileBig} 
                                                                                    withoutMenu={isMobile}
                                                                                    setNewIndex={setNewIndex}/>}
                {!cryptoIndexes[indexNames[selectedIndex]].showOverview && <div className="chartBody">
                    <div className="chartPlot">
                        { !isRotated && <div className="chartPlot-plot" style={isMobile? {marginTop: '0px', flexGrow: 0, flexShrink: 0, flexBasis: '60vh', width:'calc(100% - 40px)'}: {}}>
                            {(subscriptionTier !== 2) && (subscriptionTier !== 3) && (selectedToken !== 'lido-dao') && <Paywall />}
                            {<Chart indexName={indexNames[selectedIndex]} chartRef={chartRef} isMobile={isMobile} />}
                        </div> }
                    
                        {
                            isRotated && 
                            <div className="chartPlot-plot" style={isMobile? {marginTop: '0px', flexGrow: 0, flexShrink: 0, flexBasis: '260px', width:'calc(100% - 40px)'}: {}}>
                               
                                <Chart indexName={indexNames[selectedIndex]} chartRef={chartRef} isMobile={isMobile} />
                            </div>
                        }
                        {(cryptoIndexes[indexNames[selectedIndex]].interactiveChart) && <div className="slideWrapper">
                            <div className="slider" >
                                <input type="range" min="0" max={MAX} style={getBackgroundSize()} onChange={(e) => {
                                    setValue(e.target.value)
                                    rerender(e.target.value)
                                }} value={sliderValue} />
                                <div className="sliderText">
                                <p style={{marginRight: "0px"}}> { dates===null? convertDate(new Date()): convertDate(new Date(dates[sliderValue])) }</p>
                                </div>
                            </div>
                            <div className="sliderButtons-wrapper">
                                <div className="sliderButtons">
                                {["7D", "1M", "3M", "ALL"].map((value, index) => {return (<Button index={index} toggleF={toggleInterval} value={value} selected={selectedInterval}/>)})} 
                            </div>

                            </div>
                        </div>}
                        <div className="description" style={{ marginBottom: isMobile? "150px": "", marginTop: isMobile? "10px": ""}}>
                            {/* <div className="description-header">

                                Description
                            </div> */}
                            <div className="descriptionHeader"  ref={descriptionRef}>
                                <div className="descriptionHeader-text">
                                    Description
                                </div>
                                <a href="https://cherry-pick.xyz">
                                    {isMobile && <img src={ logoMin } style={{marginRight:'40px', height: '30px', marginTop: '7px'}} alt="text"  /> }

                                </a>
                            </div>
                            { console.log("NAME", selectedIndex, indexNames[selectedIndex])}
                            { cryptoIndexes[indexNames[selectedIndex]].description.map(([header, texts]) => {return (<DescriptionBlock header={header} texts={texts} />)})}
                            {/* { indexDescriptions[selectedIndex].map(([header, texts]) => {return (<DescriptionBlock header={header} texts={texts} />)})} */}
                            { (indexNames[selectedIndex] === "SOPR in Profit / Loss") && <div className="descriptionBlock">
                                <p className="descriptionBlock-text" style={{marginTop: "",
                                                            marginBottom: "16px"}}> {"The metric was suggested by "} <a style={{color: "#EE288A"}} href="https://twitter.com/SwellCycle">@SwellCycle</a>. </p>
                            </div>}
                            <DescriptionBlock header={true} texts='Congratulations! 🎉' />
                            <DescriptionBlock header={false} texts={['You have read the index description in full, so now you can improve your investment and trading decisions using it. 😎 🤑']} marginB={'100px'}/>
                            {/* <DescriptionBlock header={false} texts={['MPL is the total value of profits and losses in dollar terms for all coins traded at a given time. A coin is said to be trading at a profit if its purchase price is lower than the current market price. A coin is traded at a loss if its buy price is higher than the current market price.']} /> 
                            <DescriptionBlock header={true} texts={'How do I use MPL in trading ?'} />
                            <DescriptionBlock header={false} texts={['Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.', 
                                                                    'Identifying local/global price lows. A steady excess of losses over profits signals a bearish market sentiment. When losses reach their local/global peak, it means that fixation of losses has decreased, and the large amount of capital has moved to other players, waiting for the future growth. Consequently, we can expect a change in market sentiment and a future global/local market reversal. In this approach the best approach is the 14/30 day moving average, because it is less sensitive to random noise.',
                                                                    'Identify the big players sentiment. The big players in the market are addresses with a balance of $10M or more. Among investors of this scale, there are often hedge funds and institutions which have high expertise and are able to influence the market. By choosing a $10M+ cohort one can see the values of profits and losses and determine the strategy of this group: holding funds, fixing losses or profits.']} /> */}
                            {/* <div className="descriptionHeader">
                                <div className="descriptionHeader-text">
                                    Description
                                </div>
                            </div>
                            <div className="descriptionBlock">
                                <p className="descriptionBlock-text" style={{marginTop:"22px", marginBottom:"16px"}}>
                                    MPL is the total value of profits and losses in dollar terms for all coins traded at a given time. A coin is said to be trading at a profit if its purchase price is lower than the current market price. A coin is traded at a loss if its buy price is higher than the current market price.
                                </p>
                            </div>
                            <div className="descriptionBlock">
                            <div className="descriptionBlock-header">
                                <p style={{marginBottom: '10px'}}>How do I use MPL in trading ?</p>
                            </div>
                            </div>
                            <div className="descriptionBlock">
                                <p className="descriptionBlock-text" style={{marginTop:"22px"}}> 
                                    Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
                                </p>
                                <p className="descriptionBlock-text"> 
                                    Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
                                </p>
                                <p className="descriptionBlock-text" style={{marginBottom:"16px"}}> 
                                    Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
                                </p>
                            </div> */}
                            {/* <div className="description-text">
                                { indexDescriptions[selectedIndex] }
                            </div> */}
                        </div>
                    </div>
                    {/* {selectedIndex in [1, 2, 3] && <div className="chartTool"> */}
                    { !isMobile && cryptoIndexes[indexNames[selectedIndex]].showTools && <div className="chartTool">
                        <ToolMenu 
                    tools={cryptoIndexes[indexNames[selectedIndex]].tools}
                    selectedIndex={selectedTool} 
                    setNewIndex={setNewTool} />
                    </div>}
                </div>}
            </div>
        </main>
    )
}