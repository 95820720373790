import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {enUS as en} from 'date-fns/locale';
import {titleLabel, titleTooltip, scaleX, scalePrice} from './utils.js'

  
ChartJS.register(
    // removeEmptyStringsPlugin,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

const getNull = (ctx) => {
  if ((ctx.p0.raw.y === '') || (ctx.p1.raw.y === '')) {
    return [6, 6];
  } else {
    return []; 
  }
};

const getColor = (ctx) => {

  if ((ctx.p0.parsed.y > 0) && (ctx.p1.parsed.y > 0)) {
    return 'rgba(47, 235, 184, 0.9)'; 
  } else if ((ctx.p0.parsed.y <= 0) && (ctx.p1.parsed.y <= 0)) {
    return 'rgba(255, 68, 102, 0.9)';
  } else {
    let y0 = Math.abs( ctx.p0.parsed.y);
    let y1 = Math.abs( ctx.p1.parsed.y);
    if ((y0 > y1) && (ctx.p0.parsed.y > 0)) {
      return 'rgba(47, 235, 184, 0.9)'; 
    } else if ((y0 > y1) && (ctx.p0.parsed.y <= 0)) {
      return 'rgba(255, 68, 102, 0.9)';
    } else if ((y0 <= y1) && (ctx.p1.parsed.y > 0)) {
      return 'rgba(47, 235, 184, 0.9)'; 
    } else {
      return 'rgba(255, 68, 102, 0.9)';
    }
  }
  
};

const getPointColor = (ctx) => {
  // console.log('POINTPOINTPOINT')

  if ('raw' in ctx) {
    // console.log(ctx.raw)
    // console.log(ctx.raw.y)
    if (ctx.raw.y > 0) {
      return 'rgba(47, 235, 184, 0.9)'; 
    } else {
      return 'rgba(255, 68, 102, 0.9)';
    }
  }
  return 'rgba(255, 68, 102, 0.9)';
   
};


const setInitialMinScaleDataX = (ctx) => {
  const xValues = ctx.chart.data.datasets[1].data.map(point => point.x);
  if (xValues.length >= 1825) {
    // Берем 365-й элемент с конца
    const dataMin = xValues[1825];
    return dataMin;
  } else {
    // Если длина меньше 1825, возвращаем дефолтное значение
    return xValues[xValues.length - 1];
  }
};


export const data = {
    labels: [],
    datasets: [
        {
          label: 'Price',
          type: 'line',
          data: [],
          fill: false,
          borderColor: 'rgb(255, 255, 255)',
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: 'rgb(255, 255, 255)',
          yAxisID: 'y1',
          order: 1,
        },
        {
          label: 'Net Unrealised P/L',
          type: 'line',
          data: [],
          fill: false,
          borderColor:  'rgba(255, 255, 180, 0.9)',
          // borderColor: (ctx) => getPointColor(ctx),
          backgroundColor: 'rgba(255, 255, 180, 0.9)',
          // backgroundColor: (ctx) => getPointColor(ctx),
          segment: {
            // Применяем цвет для каждой части линии
            borderColor: (ctx) => getColor(ctx),
            borderDash: (ctx) => getNull(ctx),
          },
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          yAxisID: 'y',
          order: 2,
          spanGaps: true,
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        // custom: removeEmptyStringsPlugin, // Добавляем плагин в конфигурацию
        zoom: {
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.04,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
              overScaleMode: 'y',
            },
            pan: {
              enabled: true,
              overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              // y: {min: 0, max:5},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
            labels: {
              font: {
                family: 'Arial', // Шрифт
                size: 14,        // Размер шрифта
                // weight: 'simibold',  // Жирный шрифт
              },
              color: 'rgba(255, 255, 255, 1)', // Цвет текста
              boxWidth: 20, // Размер значков в легенде
              padding: 15, 
              generateLabels: (chart) => {
                // Создаем пользовательские метки для легенды
                return [
                  {
                    text: 'UP > UL, Profits Dominance 🤑',  // Описание для красного цвета
                    fillStyle: 'rgba(77, 255, 179, 0.9)', // Красный цвет
                    strokeStyle: 'rgba(77, 255, 179, 0.9)', // Красный цвет
                    lineWidth: 2,
                    borderRadius: 2, // Скругление углов
                  },
                {
                  text: 'UP < UL, Losses Dominance 🥵',  
                  fillStyle: 'rgba(255, 68, 102, 0.9)', 
                  strokeStyle: 'rgba(255, 68, 102, 0.9)',
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
                {
                  text: 'Price',  // Описание для зеленого цвета
                  fillStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  strokeStyle: 'rgba(255, 255, 255, 1)', // Зеленый цвет
                  lineWidth: 2,
                  borderRadius: 2, // Скругление углов
                },
              ];
              },
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: titleLabel,
              title: titleTooltip,
            },
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 0,
              yMax: 0,
              borderColor: 'rgba(255, 255, 180, 0.2)',
              borderWidth: 1,
              order: 1,
            }
          }
        }
    },
    scales: {
      x: {
          min: setInitialMinScaleDataX,
          type: 'time',
          time: {
            displayFormats: {
              'millisecond': 'yyyy-MM-dd HH:mm',
              'second': 'yyyy-MM-dd HH:mm',
              'minute': 'yyyy-MM-dd HH:mm',
              'hour': 'yyyy-MM-dd HH:mm',
              'day': 'yyyy-MM-dd',
              'week': 'yyyy-MM-dd',
              'month': 'yyyy-MM',
              'quarter': 'yyyy-MM',
              'year': 'yyyy',
            }
          },
          adapters: {
            date: {
                locale: en
            }
          },
          grid: {
              display: false
          },
          ticks: {
              font: {
                size: 12, 
                family: 'SFProLight',
              },
              color: '#9AA2AA',
              maxTicksLimit: 7,
              minTicksLimit: 3,
              maxRotation: 0,
              minRotation: 0,
          }
      },  
      y: {
      //     // min: 0,
      //     // max: 15,
          position: 'left',
          grid: {
              display: false,
          },
      //     ticks: {
      //       beginAtZero: true,
      //     },
          type: 'linear',
      //     stacked: true,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
    maintainAspectRatio: false,
  };