import { border } from "@mui/system";
import { tokens } from "../data";

import { useNavigate } from "react-router-dom";

import { parseValueForTooltip, addCommas } from '../ChartSettings/utils';

import { useTokens } from "../TokensContext";

import jdenticon from "jdenticon/standalone";

jdenticon.configure({
    hues: [210], // Approximation of your brand color in hue
    lightness: {
        color: [0.67, 0.83], // Lightness range closer to your brand's lightness
        grayscale: [0.30, 0.50] // Keeping grayscale subdued for simplicity
    },
    saturation: {
        color: 0.65, // A solid saturation to make the color pop a bit
        grayscale: 0.10
    },
    backColor: "#00000000"
});

export function AmountBalance(props) {
    const data = props.params.row

    let symbol = data.symbol

    if(symbol.length > 5) {
        symbol = symbol.slice(0, 5)
    }

    // if(logo === '') {
    //     const size = 32
    //     const svgImg = jdenticon.toSvg(data.token_address, size)
    //     const encodedSVG = encodeURIComponent(svgImg)
    //     logo = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
    // }

    return (
        <div 
            // className="tokenfield-field"
            // onClick={() => {navigate(`/${tokenInfo.slug.toLowerCase()}`)}}       
            >
            {/* <img style={{marginLeft: "0px", width: '32px', height: '32px'}} className="tokenField-logo" src={ logo } alt="text" /> */}
            <p style={{marginLeft:"0px", fontFamily: 'SFProLight'}}>{ parseValueForTooltip(data.balance)+ " " + symbol }</p>
        </div>
    )
}


export function TokenTableFieldHash(props) {
    const data = props.params.row

    let logo = data.logo

    if(logo === '') {
        const size = 32
        const svgImg = jdenticon.toSvg(data.token_address, size)
        const encodedSVG = encodeURIComponent(svgImg)
        logo = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
    }

    return (
        <div 
            className="tokenfield-field no-hover"
            // onClick={() => {navigate(`/${tokenInfo.slug.toLowerCase()}`)}}       
            >
            <img style={{marginLeft: "0px", width: '32px', height: '32px'}} className="tokenField-logo" src={ logo } alt="text" />
            <p style={{marginLeft:"10px", fontFamily: 'SFProReg'}}>{ data.name } </p>
        </div>
    )
}

export function NewTokenTableField(props) {
    const navigate = useNavigate()

    const { tokenAddressMapV2: tokens, loading, error } = useTokens();

    const tokenInfo = tokens[props.value]
    
    if(tokenInfo === undefined) {
        return (
            <div 
            className="tokenfield-field"
            onClick={() => {}}       
            >
                <img style={{marginLeft: "0px", width: '24px', height: '24px'}} className="tokenField-logo" src={'https://etherscan.io/images/main/empty-token.png'} alt="text" />
                <p style={{marginLeft:"10px"}}>{ 'Undefined' } </p>
            </div>
        )
    }

    return (
        <div 
            className="tokenfield-field"
            // onClick={() => {navigate(`/${tokenInfo.slug.toLowerCase()}`)}}    
            onClick={() => {window.open(`/${tokenInfo.slug.toLowerCase()}`, '_blank')}}   
            >
            <img style={{marginLeft: "0px", width: '24px', height: '24px'}} className="tokenField-logo" src={ tokenInfo.logo == '' ? 'https://etherscan.io/images/main/empty-token.png' : tokenInfo.logo } alt="text" />
            <p style={{marginLeft:"10px"}}>{ tokenInfo.name } </p>
        </div>
    )
}


export function TokenTableFieldV1(props) {
    const navigate = useNavigate()

    const { tokenAddressMapV1: tokens, loading, error } = useTokens();

    const tokenInfo = tokens[props.value]
    
    if(tokenInfo === undefined) {
        return (
            <div 
            className="tokenfield-field"
            onClick={() => {}}       
            >
                <img style={{marginLeft: "0px", width: '24px', height: '24px'}} className="tokenField-logo" src={'https://etherscan.io/images/main/empty-token.png'} alt="text" />
                <p style={{marginLeft:"10px"}}>{ 'Undefined' } </p>
            </div>
        )
    }

    return (
        <div 
            className="tokenfield-field"
            // onClick={() => {navigate(`/${tokenInfo.slug.toLowerCase()}`)}}    
            onClick={() => {window.open(`/${tokenInfo.slug.toLowerCase()}/overview`, '_blank')}}   
            >
            <img style={{marginLeft: "0px", width: '24px', height: '24px'}} className="tokenField-logo" src={ tokenInfo.logo } alt="text" />
            <p style={{marginLeft:"10px"}}>{ tokenInfo.name } </p>
        </div>
    )
}


export function ETHChainTableField() {

    return (
        <div 
            className="tokenfield-field-nohover"
            // style={{ textDecoration: "none", color: "inherit" }}
            >
            <img style={{marginLeft: "0px", width: '20px', height: '20px'}} className="tokenField-logo" src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" alt="text" />
            <p style={{marginLeft:"10px"}}>Ethereum</p>
        </div>
    )
}


export function ETHLogoTableField() {

    return (
        <div 
            className="tokenfield-field-nohover"
            // style={{ textDecoration: "none", color: "inherit" }}
            >
            <img style={{marginLeft: "0px", width: '18px', height: '18px'}} className="tokenField-logo" src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" alt="text" />
        </div>
    )
}

export function EtherscanAddress(props) {
    const styleText = {
        color: 'white',
        cursor: 'pointer',
    }
    const navigate = useNavigate()
    // function redirect() {

    // }
    var address = props.value.slice(0, 8) + '...'

    return (
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'row'}}>
            {/* <img style={{marginLeft: "0px", width: '20px', height: '20px'}} className="tokenField-logo" src="https://info.etherscan.com/explorer/etherscan/logo.svg" alt="text" /> */}
            {/* <u style={styleText} onClick={() => {navigate(`/portfolio/${props.value}/trades/${props.token}`)}} > {props.value.slice(0, 8)}...</u> */}
            <a style={styleText} href={`https://etherscan.io/token/${props.value}`} target="_blank" > {address}</a>
        </div>
    )
}

export function TokenTableField(props) {
    const navigate = useNavigate()

    return (
        <div 
            className="tokenfield-field"
            // onClick={() => {navigate(`/${props.value.toLowerCase()}`)}}    
            onClick={() => {window.open(`/${props.value.toLowerCase()}`, '_blank')}}      
            >
            <img style={{marginLeft: "0px", width: '24px', height: '24px'}} className="tokenField-logo" src={ tokens[props.value.toLowerCase()].img } alt="text" />
            <p style={{marginLeft:"10px"}}>{ tokens[props.value.toLowerCase()].name } </p>
        </div>
    )
}

export function ShowDollarFlow(props) {
    const positiveValue = (props.value >= 0)
    const styleText = {
        color: positiveValue ? '#28E2EE' : '#EE288A',
    }

    return (
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'row'}}>
            <p style={styleText}> {positiveValue? "": "-"}${ parseValueForTooltip(Math.abs(props.value.toFixed(2)))}</p>
        </div>
    )
}

export function ShowValue(props) {
    const positiveValue = (props.value >= 1.0)
    const styleText = {
        color: positiveValue ? '#28E2EE' : '#EE288A',
    }

    try {
    return (
        <div style={{display: 'flex', alignItems:'center', flexDirection: 'row'}}>
            <p style={styleText}> {positiveValue? "": ""}{ parseValueForTooltip(Math.abs(props.value.toFixed(2)))}</p>
        </div>
    ) 
    } catch {
        <div style={{display: 'flex', alignItems:'center', flexDirection: 'row'}}>
            <p style={styleText}> { props.value }</p>
        </div>
    }
}

export function ShowPercentChange(props) {
    const positiveValue = (props.value >= 0)
    const styleText = {
        color: positiveValue ? '#28E2EE' : '#EE288A',
        // color: positiveValue ? 'rgb(172, 209, 251)' : 'rgb(251, 172, 209)',
    }

    const triangleStyle = {
        width: 0,
        height: 0,
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        [positiveValue ? 'borderBottom' : 'borderTop']: `6px solid ${positiveValue ? '#28E2EE' : '#EE288A'}`,
        // [positiveValue ? 'borderBottom' : 'borderTop']: `6px solid ${positiveValue ? 'rgb(172, 209, 251)' : 'rgb(251, 172, 209)'}`,
        marginRight: '4px'
    }
    // const s = "▾▴"
    try {
        return (
            <div style={{display: 'flex', alignItems:'center', flexDirection: 'row'}}>
                <div style={triangleStyle}></div>
                <p style={styleText}> { Math.abs(props.value.toFixed(2))}%</p>
            </div>
        )
    } catch {
        return (
            <div style={{display: 'flex', alignItems:'center', flexDirection: 'row'}}>
                {props.value}
            </div>
        )
    }
}

export function ShowAbsChange(props) {
    const positiveValue = (props.value >= 0)
    const styleText = {
        color: positiveValue ? '#28E2EE' : '#EE288A',
        // color: positiveValue ? 'rgb(172, 209, 251)' : 'rgb(251, 172, 209)',
    }
    const sigh =  positiveValue ? '+' : '-'

    try {
        return (
            <div style={{display: 'flex', alignItems:'center', flexDirection: 'row'}}>
                <p style={styleText}> { sigh + addCommas(Math.abs(props.value.toFixed(2)))}</p>
            </div>
        )
    } catch {
        return (
            <div style={{display: 'flex', alignItems:'center', flexDirection: 'row'}}>
                {props.value}
            </div>
        )
    }
}


export function ShowAddress(props) {
    const styleText = {
        color: 'white',
        cursor: 'pointer',
    }
    const navigate = useNavigate()
    // function redirect() {

    // }
    var address = props.value.slice(0, 8) + '...'
    console.log("TAG")
    console.log(props)
    if ((props.tag !== '')) {
        address = props.tag
    }

    return (
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'row'}}>
            {/* <u style={styleText} onClick={() => {navigate(`/portfolio/${props.value}/trades/${props.token}`)}} > {props.value.slice(0, 8)}...</u> */}
            <a style={styleText} href={`http://app.cherry-pick.xyz/portfolio/${props.value}/trades/${props.token}`} target="_blank" > {address}</a>
        </div>
    )
}

export function ShowPercent(props) {
    const positiveValue = (props.value >= 50.0)
    const styleText = {
        color: positiveValue ? '#28E2EE' : '#EE288A',
        // color: positiveValue ? 'rgb(172, 209, 251)' : 'rgb(251, 172, 209)',
    }

    console.log("SHOW PERCENT", props.value)
    // const s = "▾▴"
    return (
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'row'}}>
            <p style={styleText}> { props.value === '-' ? props.value : Math.abs(props.value.toFixed(2))}%</p>
        </div>
    )
}

export function timeSince(dateStr) {
    const date = new Date(dateStr);

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }