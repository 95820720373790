import {React, useState } from "react";
//import myLogo from "../assets/cherrypick_logo.svg"
//import uniLogo from "../assets/uniswap.png"
// import cherryPickLogo from "../assets/logoMin.svg"
import cherrypickLogo from "../assets/cherrypick_logo.svg"
import { useNavigate } from "react-router-dom";
import { Auth } from "@supabase/auth-ui-react";

import { Link } from 'react-router-dom';

import signout from "../assets/signout.svg"
import { LeaderBoard } from "./TokenTracker";

import './Navigation.css';


export function ButtonLogin(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    const navigate = useNavigate()

    const styleLogin = {
        // background: isMouseEnter? '#FF54A9': '#EE288A'
        background: 'transparent',
        color:  isMouseEnter? '#EE288A' : 'white',
        // borderColor: '#EE288A',
        // borderWidth: '1px',   
    }

    const blackStyleLogin = {
        background: 'transparent',
        // color:  isMouseEnter? '#EE288A' : 'white',
        color: 'white',
        background: isMouseEnter? '#1D1A2D': '',
        border: '2px solid #FFFAFC',
        borderRadius: '8px',
        // padding: '0em 1em',
    }

    if (props.withoutMargin) {
        styleLogin['marginRight'] = '10px'
        blackStyleLogin['marginRight'] = '10px'
    }

    return (
        <button 
            data-attr={props.dataAttr ? props.dataAttr : ''}
            className={props.menuNav? "buttonSignIn" :"login"}
            style={props.blackTheme ? blackStyleLogin : styleLogin}
            onMouseEnter={ () => setMouseEnter(true) } 
            onMouseLeave={ () => setMouseEnter(false) }
            onClick={() => {props.link? window.open(props.link, '_blank') : props.path? navigate(props.path) : navigate("/login", {state:{type:'login'}})}}
        >
            <p style={props.blackTheme ? {fontSize: '16px', padding: '0em 2em', whiteSpace: 'nowrap',}:{}}> {props.name? props.name:'LOG IN'}</p>
        </button>
    )
}

export function ButtonSignUp(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    const navigate = useNavigate()

    const styleSignUp = {
        background: isMouseEnter? '#FF54A9': '#EE288A',
        marginRight: "8px"
    }

    const blackStyleSignUp = {
        background: isMouseEnter? '#13121B': '#1D1A2D',
        // color:  isMouseEnter? '#EE288A' : 'white',
        border: '2px solid #1D1A2D',
        borderRadius: '8px',
        color: 'white'
        // padding: '0em 1em',
    }

    if (props.withoutMargin) {
        styleSignUp['marginRight'] = '0px'
        blackStyleSignUp['marginRight'] = '0px'
    }

    return (
        <button 
            className={props.menuNav? "buttonSignUp" :"login"}
            style={props.blackTheme ? blackStyleSignUp : styleSignUp}
            onMouseEnter={ () => setMouseEnter(true) } 
            onMouseLeave={ () => setMouseEnter(false) }
            onClick={() => {navigate("/login", {state:{type:'signup'}})}}
        >
            <p style={props.blackTheme ? {fontSize: '16px', padding: '0em 2em', whiteSpace: 'nowrap',}:{}}>SIGN UP</p>
        </button>
    )
}

function HeaderMenuButton(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)
    const styleBut = {
        color:  isMouseEnter? '#EE288A' : 'white',
        fontFamily: 'SFProLight',
        fontSize: '14px',
        cursor: 'pointer',
    }

    const navigate = useNavigate()

    return (
        <p style={styleBut} 
            onClick={() => navigate(props.path)}
            onMouseEnter={ () => setMouseEnter(true) } 
            onMouseLeave={ () => setMouseEnter(false) }> {props.name} </p>
    )
}

function HeaderMenuLink(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)
    const styleBut = {
        color:  isMouseEnter? '#EE288A' : 'white',
        fontFamily: 'SFProLight',
        fontSize: '14px',
        cursor: 'pointer',
        alignSelf: 'center',
        textDecoration: 'inherit',
        marginTop: '1em',
        marginBottom: '1em',
    }

    const navigate = useNavigate()

    return ( 
        <a style={styleBut} 
            target="blank" 
            href={props.path} 
            onMouseEnter={ () => setMouseEnter(true) } 
            onMouseLeave={ () => setMouseEnter(false) }>
            {props.name}
        </a>
    )
}

function HeaderMenu(props) {
    return (
        <div
            style={{
                display: 'flex',
                gap: 40,
                marginLeft: 'auto',
                marginRight: props.isMobile ? (props.user? '0px':'100px'):'120px',
            }}
        >
            {!props.isMobile && <HeaderMenuButton path={'/explore'} name={'Wallet Explorer'}/>}
            {!props.isMobile && <HeaderMenuButton path={'/leaderboard'} name={'Leaderboard'}/> }
            {!props.isMobile && <HeaderMenuButton path={'/token-tracker'} name={'Token Tracker'}/>}
            {!props.isMobile && <HeaderMenuButton path={'/'} name={'On-chain Metrics'} />}
            {!props.isMobile && <HeaderMenuButton path={'/filter'} name={'Filter'} />}
            {!props.isMobile && <HeaderMenuLink path={'https://docs.cherry-pick.xyz/'} name={'API Docs'} />}
            {!props.isMobile && <HeaderMenuButton path={'/pricing'} name={'Pricing'} />}
            {props.isMobile && <HeaderMenuButton path={'/menu'} name={'Menu'} /> }
        </div>
    )
}

// function HeaderMenuDropDown(props) {
//     return (
//         <div></div>
//     )
// }

const HeaderMenuDropDown = () => {
    const [onchainDropdown, setOnchainDropdown] = useState(false);
    const [smartMoneyDropdown, setSmartMoneyDropdown] = useState(false);
  
    return (
            <nav className="navbar" style={{marginLeft: '80px'}}>
              <Link to="/home">Home</Link>
              <div className="dropdown">
                <span className="dropdown-button">On-chain Analysis</span>
                <div className="dropdown-content">
                  <Link to="/weth">Charts 🔥</Link>
                  <Link to="/token-tracker">Token Ranking</Link>
                  {/* <Link to="/pricing">API</Link> */}
                  <a href="https://docs.cherry-pick.xyz/" target="_blank">API</a>
                  <Link data-attr="pricing" to="/pricing">Pricing</Link>
                  <Link to="/ldo">Old Charts</Link>
                </div>
              </div>
              <div className="dropdown">
                <span className="dropdown-button">Follow Smart Money</span>
                <div className="dropdown-content">
                  <Link to="/filter">Wallet Search </Link>
                  <Link to="/leaderboard">Leaderdoards</Link>
                  <Link to="/explore">Wallet Explorer 🔥</Link>
                  
                  {/* <Link to="/alerts">Alerts</Link> */}
                </div>
              </div>
              <div className="dropdown">
                <span className="dropdown-button">Contact Us</span>
                <div className="dropdown-content">
                    <a href="https://twitter.com/cherrypick_xyz">Twitter</a>
                    <a href="https://t.me/+w7w4rlpdh-M3MmQ8">Telegram</a>
                    <a href="https://discord.gg/AWkjXPCkCY">Discord</a>
                    <a href="mailto:info@cherry-pick.xyz">Email</a>                 
                  {/* <Link to="/alerts">Alerts</Link> */}
                </div>
              </div>
            </nav>
    );
};

const HeaderMenuDropDownMobile = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [selectedSubMenu, setSelectedSubMenu] = useState('');
  
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const selectSubMenu = (subMenu) => {
      setSelectedSubMenu(subMenu === selectedSubMenu ? '' : subMenu);
    };
  
    return (
      <nav className="navbar" style={{marginLeft: 'auto'}}>
        <button onClick={toggleMenu} className="menu-button-mobile">Menu</button>
        {isMenuOpen && (
          <div className="mobile-menu">
            <Link to="/">Home</Link>
            <div className="mobile-submenu">
              <span style={{color: (selectedSubMenu === 'onchain')? '#ACD1FB': ''}} onClick={() => selectSubMenu('onchain')}>On-chain Analysis</span>
              {selectedSubMenu === 'onchain' && (
                <div className="mobile-dropdown-content">
                  <Link to="/lido-dao">Charts 🔥</Link>
                  <Link to="/token-tracker">Token Ranking</Link>
                  {/* <Link to="/pricing">API</Link> */}
                  <a href="https://docs.cherry-pick.xyz/" target="_blank">API</a>
                  <Link data-attr="pricing" to="/pricing">Pricing</Link>
                  <Link to="/ldo">Old Charts</Link>
                </div>
              )}
            </div>
            <div className="mobile-submenu">
              <span style={{color: (selectedSubMenu === 'smartmoney')? '#ACD1FB': ''}} onClick={() => selectSubMenu('smartmoney')}>Follow Smart Money</span>
              {selectedSubMenu === 'smartmoney' && (
                <div className="mobile-dropdown-content">
                  <Link to="/filter">Wallet Search </Link>
                  <Link to="/leaderboard">Leaderdoards</Link>
                  <Link to="/explore">Wallet Explorer 🔥</Link>
                </div>
              )}
            </div>
            <div className="mobile-submenu">
              <span style={{color: (selectedSubMenu === 'contactus')? '#ACD1FB': ''}} onClick={() => selectSubMenu('contactus')}>Contact Us</span>
              {selectedSubMenu === 'contactus' && (
                <div className="mobile-dropdown-content">
                    <a href="https://twitter.com/cherrypick_xyz">Twitter</a>
                    <a href="https://t.me/+w7w4rlpdh-M3MmQ8">Telegram</a>
                    <a href="https://discord.gg/AWkjXPCkCY">Discord</a>
                    <a href="mailto:info@cherry-pick.xyz">Email</a> 
                </div>
              )}
            </div>
          </div>
        )}
        {/* Add the existing desktop navbar here */}
      </nav>
    );
};

function UserInfo(props) {
    console.log("USER", props.user)
    let firstLetter = ""

    if (props.user.app_metadata.provider === 'email' || props.user.app_metadata.provider === 'google') {
        firstLetter = props.user.email[0].toUpperCase()
    }

    return (
        <div className="userInfo" style={{width: props.isMobile ? '40px':'200px', marginRight: props.isMobile? '25px':'44px', marginLeft: props.isMobile? '' :'auto' }}>
            <div className="userDropdown">
                <div className="userName" style={{ marginLeft: 'auto'}}> 
                    <span style={{ fontFamily: "SFProBold" }}>{firstLetter}</span>
                </div>
                <div className="userDropdown-content">
                    <a href="/account">My Account</a>
                    <a onClick={() => props.supabaseClient.auth.signOut()} style={{display: 'flex', alignItems:'center'}}>
                        <span>Log Out</span>
                        <img 
                            src={signout} 
                            alt='Sign out'
                            style={{ height: '14px', marginLeft: '8px' }}
                        />
                    </a>
                </div>
            </div>
            {/* <img 
                src={signout} 
                alt='Sign out' 
                style={{ height: '20px', cursor: 'pointer', marginLeft: '8px' }}
                onClick={() => props.supabaseClient.auth.signOut()}
            /> */}
            
            {/* <div className="userName" style={{marginLeft: 'auto', marginRight: '8px'}}> 
                <span style={{fontFamily: "SFProBold"}}>{firstLetter}</span> 
            </div>
            <img 
                            src={signout} 
                            alt='text' 
                            style={{height: '20px', cursor: 'pointer'}}
                            onClick={() => props.supabaseClient.auth.signOut()}
                        /> */}
        </div>
    )
}

export default function Header(props) {
    const { user } = Auth.useUser();
    console.log("ISMOBILE ? ", props.isMobile)
    return (
        <div className="header" style={props.blackTheme ? {borderBottom: '2px solid #1D1A2D'} : {}}>
            {!props.blackTheme && <a href="https://cherry-pick.xyz">
                <img src={ cherrypickLogo } style={{height: '45px', marginLeft: '28px', marginTop: '11px'}} alt="text" /> 
            </a>}
            {props.blackTheme && <a href="https://cherry-pick.xyz" style={{color: 'white', 
                                                    textDecoration: 'none',
                                                    fontFamily: 'SFProText',
                                                    fontSize: '18px',
                                                    marginLeft: '40px'}}> <span style={{color: '#DB3E88'}}>C</span>HERRYPICK</a>}
            {/* { !props.isMobile && props.menu && <HeaderMenu /> } */}
            { props.menu && !props.blackTheme && <HeaderMenu isMobile={props.isMobile} user={user}/> }
            { props.isMobile && props.blackTheme && <HeaderMenuDropDownMobile />}
            { !props.isMobile && props.blackTheme && <HeaderMenuDropDown />}
            { !props.isMobile && props.menu && (!user) && <div className="userInfo" style={{marginLeft: 'auto'}}>
                <ButtonLogin withoutMargin={true} blackTheme={props.blackTheme} />
                <ButtonSignUp blackTheme={props.blackTheme} />
            </div> }
            {/* { !props.isMobile && props.menu && (!user) && <ButtonSignUp /> }
            { !props.isMobile && props.menu && (!user) && <ButtonLogin /> } */}
            { user && <UserInfo isMobile={props.isMobile} user={user} supabaseClient={props.supabaseClient}/>}
            {/* { user && <img 
                            src={signout} 
                            alt='text' 
                            style={{height: '20px', marginRight: props.isMobile ? '24px' :'44px', cursor: 'pointer'}}
                            onClick={() => props.supabaseClient.auth.signOut()}
                        />} */}
            {/* { user && <p>Hi, trader {user.email}</p> } */}
            {/* <div className="login2">
                <p>Sign in</p>
            </div> */}
        </div>
    )
}

export function MenuNavigation(props) {
    const { user } = Auth.useUser();

    return (
        <div className="menuNav">
            <div className="menuNav-Menu">
                <HeaderMenuButton path={'/explore'} name={'Wallet Explorer'}/>
                <HeaderMenuButton path={'/leaderboard'} name={'Leaderboard'}/>
                <HeaderMenuButton path={'/token-tracker'} name={'Token Tracker'}/>
                <HeaderMenuButton path={'/'} name={'On-chain Metrics'} />
                <HeaderMenuButton path={'/filter'} name={'Filter'} />
                <HeaderMenuLink path={'https://docs.cherry-pick.xyz/'} name={'API Docs'} />
                <HeaderMenuButton path={'/pricing'} name={'Pricing'} />
            </div>
            {!user && <div className="menuButtons">
                <ButtonLogin menuNav={true}/>
                <ButtonSignUp withoutMargin={true} menuNav={true} />
            </div>}
        </div>
    )
}

// function selectItem(e) {
//     e.target.style.borderBottom ="1.5px solid #eb4396";
//     e.target.style.borderTop ="1.5px solid #eb4396";
// }

// export default function Navbar() {
//     return (
//         <div>
//             <Header />
//             <div className="navbar">
//                 <div className="navbar token">
//                     <img alt="uniswap" src={uniLogo} height="30px" width="30px"></img>
//                     <span className="btn">Uniswap</span>
//                     <div className="triangle"></div>
//                 </div>
//                 <div className="navbar properties">
//                     <div className="navbar properties items it1"> CeX  </div>
//                     <div className="navbar properties items it2"> DeX</div>
//                 </div>
//             </div>
//         </div>
//     )
// }