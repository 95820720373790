import React from "react";
import Header from "./components/Navigation";
import Main from "./components/Main"

import { MainNew } from "./components/Main";
//import Chart from "./components/Chart";

import { useState, useEffect } from 'react'
import Auth2 from './components/Auth2'
import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";

import { useNavigate, Navigate } from "react-router-dom";

import { TokensProvider } from './components/TokensContext';

import CookieBanner from "./components/CookieBanner";

import {
    BrowserRouter,
    Route,
    Routes,
    Redirect,
    useLocation 
  } from "react-router-dom";
import { TokenTracker } from "./components/TokenTracker";

import { LeaderBoard } from "./components/Leaderboard";
import { Portfolio } from "./components/Portfolio";
import { MenuNavigation } from "./components/Navigation";
import { Explore } from "./components/Explore";
import { Pricing } from "./components/Pricing";
import { Filter, FilterWrapper } from "./components/Filter";
import { Home } from "./components/Home"
import { Account } from "./components/Account";
import { PricingOnchain, PricingTest, PricingTestNoProd } from "./components/Pricing";

import posthog from 'posthog-js';

import { useSubscription, SubscriptionProvider } from './components/subscriptionContext';
import { getSubscriptionTier, isSubscriptionValid } from './components/supabaseClient'; // Import these functions
import UserSubscriptionHandler from './components/userSubscriptionHandler';
import { PopupProvider } from './components/popupContext'

import Popup from './components/Popup'
import { FilterProvider } from "./components/FilterProvider";

import { ProFeature } from "./components/ProFeature";
import soprheartbeat from "./assets/soprheartbeat.png";

import Toast from "./components/Toast";
import AuthComponent from "./components/Auth2";

import { useTokens } from "./components/TokensContext";

const supabase = createClient(
    'https://xjllubhirqqferhrmgbs.supabase.co', 
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhqbGx1YmhpcnFxZmVyaHJtZ2JzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc4NDc0NDQsImV4cCI6MTk5MzQyMzQ0NH0.fdfxmBEPbHKPBIcvyQX60RB85kSStpP_wP-t9HmelCE'
)


// export default function App() {

//   return (
//     <Auth.UserContextProvider supabaseClient={supabase}>
//         <Header />
//         <div style={{height: '100vh',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//             <div style={{width: "400px", height: "400px"}}>
//                 <Auth2 />
//             </div>
//         </div>
//     </Auth.UserContextProvider>
//   )
// }

function MainPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
    const [selectedVersion, selectVersion] = useState(null); // Initialize as null
    const { slugMap: tokens, tokens: tokenItems, tokensV1: tokenItemsV1, tokensV2: tokenItemsV2, loading } = useTokens();
    const location = useLocation(); // To track URL changes
    const [currentToken, setCurrentToken] = useState(null); // To track the currently selected token

    const handleResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
        setIsMobile(true);
        } else {
        setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!loading && tokens) {
            console.log('TOKENSINDEX change path')
        const pathSegments = location.pathname.split('/'); // Split path by "/"
        const tokenName = pathSegments[1]; // Extract token name

        if (tokens[tokenName]) {
            // Update current token and version
            setCurrentToken(tokenName);
            const version = tokens[tokenName].version === '1' ? 'v1' : 'v2';
            selectVersion(version);
        } else {
            console.warn(`Token "${tokenName}" not found in tokens list.`);
        }
        }
    }, [location.pathname, loading, tokens]); // Run effect when the path or tokens change

    const tokenItemsSelected = selectedVersion === 'v2' ? tokenItemsV2 : tokenItemsV1;
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    console.log("TOKENSINDEX mainpage", selectedVersion, tokenItemsSelected, tokens)
    if (loading || currentToken === null || selectedVersion === null || tokenItemsSelected.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            {!loading && currentToken !== null && selectedVersion === 'v1' && <Main tokens={tokens} tokenItems={tokenItemsSelected} />}
            {!loading && currentToken !== null && selectedVersion === 'v2' && <MainNew tokens={tokens} tokenItems={tokenItemsSelected} />}
        </body>
    )
}

// function LoginPage() {
//     const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
//     const [type, setType] = useState('sign_in')

//     const { user, session } = Auth.useUser();

//     const navigate = useNavigate()

//     const handleResize = () => {
//         // console.log("WIDTH - ", window.innerWidth)
//         let vh = window.innerHeight * 0.01;
//         document.documentElement.style.setProperty('--vh', `${vh}px`);
//         if (window.innerWidth < 950) {
//             setIsMobile(true)
//         } else {
//             setIsMobile(false)
//         }
//     }
      
//     useEffect(() => {
//         window.addEventListener("resize", handleResize)
//         // window.addEventListener("orientationchange", handleOrientation)
//         // window.addEventListener("popstate", (event) => console.log("return page"))
//     })

//     const {state} = useLocation()
//     // console.log("STATE", state)
//     useEffect(() => {
//         supabase.auth.onAuthStateChange((event) => {
//             console.log("EVENT LISTEN", event)
//             if (event === 'PASSWORD_RECOVERY') {
//                 navigate('/reset-password')
//             }
//         })
//         if(state) {
//             console.log("STATE", state)
//             setType(state.type)
//         }
//     }, []);


//     if(user) {
//         navigate('/leaderboard')
//     }
//     // const {type} = state

//     // console.log("TYPE", type)


//     return (
//         <body>
//             <Header  blackTheme={true} supabaseClient={supabase} menu={false} />
//             <div style={{height: '100vh',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                 <div style={{width: "450px", height: "400px", padding: isMobile? "20px" : "0px"}}>
//                     <Auth2 view={type}/>
//                     <p style={{color: 'white', fontFamily: 'SFProLight', textAlign:'center', fontSize: '14px'}}>By signing up you agree to our <a href="https://cherry-pick.xyz/terms-and-conditions" target="_blank" style={{color: 'white'}}>Terms & Conditions</a> and <a href="https://cherry-pick.xyz/privacy-policy" target="_blank" style={{color: 'white'}}>Privacy Policy</a></p>
//                 </div>
//             </div>
//         </body>
//     )
// }

function RefferalPage() {
    const { subscriptionTier } = useSubscription();
    const [wrongReferralCode, setRefCode] = useState(false);

    const navigate = useNavigate()

    const location = useLocation();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)

    const handleResize = () => {
        // console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        // window.addEventListener("orientationchange", handleOrientation)
        // window.addEventListener("popstate", (event) => console.log("return page"))
    })

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const referralCode = queryParams.get('referral_code');
        
        if (referralCode) {
            // Validate the referral code by checking with the backend
            // Apply the referral code if it's valid
            console.log('Applying referral code:', referralCode);
            navigate('/login', { state: {referralCode}})
        } else {
            setRefCode(true)
        }
    }, [location]);

    if(subscriptionTier !== 0) {
        navigate('/account')
    }

    return (
        <div>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', height: '80vh'}}>
            <h1 style={{color: 'white', textAlign: 'center', fontFamily:"SFPro"}}> {wrongReferralCode? "Unfortunatelly something wrong with your referral code! Please contact to our team.":"Join Our Referral Program"}</h1>
            {/* Additional signup or login logic */}
            </div>
        </div>
    );
}

function LoginPage() {
    return (
        <body style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh'}}>
            <AuthComponent />
        </body>
    )
}

function ResetPassword() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [type, setType] = useState('update_password')

    const navigate = useNavigate()

    const handleResize = () => {
        // console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        // window.addEventListener("orientationchange", handleOrientation)
        // window.addEventListener("popstate", (event) => console.log("return page"))
    })

    useEffect(() => {
        supabase.auth.onAuthStateChange((event) => {
            // console.log("EVENT LISTEN", event)
            if (event === 'USER_UPDATED') {
                navigate('/login')
            }
        })
    }, []);


    return (
        <body>
            <Header  blackTheme={true} supabaseClient={supabase} menu={false} />
            <div style={{height: '100vh',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: "450px", height: "400px", padding: isMobile? "20px" : "0px"}}>
                    <Auth2 view={type}/>
                </div>
            </div>
        </body>
    )
}

function TokenTrackerPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)

    const { loading } = useTokens();

    const handleResize = () => {
        // console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        // window.addEventListener("orientationchange", handleOrientation)
        // window.addEventListener("popstate", (event) => console.log("return page"))
    })

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            {!loading && <TokenTracker isMobile={isMobile}/>}
        </body>
    )
}

function LeaderBoardPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)

    const handleResize = () => {
        // console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        // window.addEventListener("orientationchange", handleOrientation)
        // window.addEventListener("popstate", (event) => console.log("return page"))
    })

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <LeaderBoard isMobile={isMobile}/>
        </body>
    )
}

function MenuPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)

    const handleResize = () => {
        // console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        // window.addEventListener("orientationchange", handleOrientation)
        // window.addEventListener("popstate", (event) => console.log("return page"))
    })
    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <MenuNavigation />
        </body>
    )
}

function ExplorePage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)

    const handleResize = () => {
        // console.log("WIDTH - ", window.innerWidth)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (window.innerWidth < 950) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        // window.addEventListener("orientationchange", handleOrientation)
        // window.addEventListener("popstate", (event) => console.log("return page"))
    })
    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <Explore isMobile={isMobile} />
        </body>
    )
}

function PortfolioPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <Routes>
                <Route path=":walletAddress/*" element={<Portfolio isMobile={isMobile} isPhone={isPhone} />} />
                <Route path="*" element={<Navigate replace to="/portfolio/0x820fb25352bb0c5e03e07afc1d86252ffd2f0a18" />} />
            </Routes>
            {/* <Portfolio isMobile={isMobile} isPhone={isPhone}/> */}
        </body>
    )
}

function PricingPage(props) {
    console.log("PRIC~i`N pga")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            {props.onchain && <PricingOnchain supabaseClient={supabase} />}
            {props.api && <Pricing supabaseClient={supabase} />}
        </body>
    )
}

function TestPaymentPage() {
    console.log("PRIC~i`N pga")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <PricingTest supabaseClient={supabase} />
        </body>
    )
}

function TestPaymentPageNoProd() {
    console.log("PRIC~i`N pga")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <body>
            <Header  blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <PricingTestNoProd supabaseClient={supabase} />
        </body>
    )
}

function FilterPage() {
    console.log("PRIC~i`N pga")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <div>
            <Header blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <FilterWrapper supabaseClient={supabase}/>
        </div>
    )
}

function ProFeaturePage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const {subscriptionTier} = useSubscription();

    return (
        <div>
            <Header blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            {(subscriptionTier === 2) && <ProFeature supabaseClient={supabase}/>}
            {(subscriptionTier !== 2) &&
                <div style={{display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: "flex", maxWidth: '800px', textAlign: 'center'}}>
                        <p style={{color: 'white', fontFamily:'SFPro', fontSize: '24px'}}> Regrettably, to access this page, you must be logged into the platform and possess a subscription plan of Standard level or above.</p>
                    </div>
                    <img src={soprheartbeat} style={{width: '70%', marginTop: '20px'}}/>
                </div>
            }
        </div>
    )
}

function HomePage() {
    console.log("PRIC~i`N pga")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <body>
            <Header blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <Home supabaseClient={supabase}/>
        </body>
    )
}

function AccountPage() {
    console.log("Account page")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950)
    const [isPhone, setIsPhone] = useState(window.innerWidth < 700)

    const handleResize = () => {
        console.log("WIDTH - ", window.innerWidth, isMobile, isPhone)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if ((window.innerWidth < 950) && (window.innerWidth > 700)) {
            setIsMobile(true)
            setIsPhone(false)
        } else if (window.innerWidth < 700) {
            setIsPhone(true)
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setIsPhone(false)
        }
    }
      
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <body>
            <Header blackTheme={true} isMobile={isMobile} supabaseClient={supabase} menu={true} />
            <Account supabaseClient={supabase}/>
        </body>
    )
}

export default function App() {
    // const { user } = Auth.useUser();
    const [subscriptionTier, setSubscriptionTier] = useState(0);

    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_IN') {
                // Retrieve user information
                const user = session.user;
                console.log("YOU WERE SIGNED IN");
                posthog.identify(user.id, {
                    email: user.email,
                });
            } else if (event === 'SIGNED_OUT') {
                posthog.reset();
            }
            // Handle other events like 'PASSWORD_RECOVERY' if needed
        });
        // No need to unsubscribe
    }, []);
    

    useEffect(async () => {
        const { data, error } = await supabase.auth.getSession();
        if (data && data.session) {
            const user = data.session.user;
            if (user) {
                posthog.identify(user.id, {
                    email: user.email,
                });
            }
        }
    }, []);
    

    return (
        <div>
            <SubscriptionProvider value={{ subscriptionTier, setSubscriptionTier, }}>
                <Auth.UserContextProvider supabaseClient={supabase}>
                    <PopupProvider>
                        <TokensProvider>
                            <UserSubscriptionHandler supabaseClient={supabase} />
                                <Routes>
                                    <Route path="/" element={<Navigate to="/home" replace />} />
                                    <Route path="/*" element={<MainPage />} />
                                    <Route path="/home" element={<HomePage />} />
                                    <Route path="/login" element={<LoginPage />} />
                                    <Route path="/token-tracker/*" element={<TokenTrackerPage />} />
                                    <Route path="/leaderboard/*" element={<LeaderBoardPage />} />
                                    <Route path="/portfolio/*" element={<PortfolioPage />} />
                                    <Route path="/menu" element={<MenuPage />} />
                                    <Route path="/explore" element={<ExplorePage />} />
                                    <Route path="/reset-password" element={<ResetPassword />} />
                                    <Route path="/pricing" element={<TestPaymentPageNoProd />} />
                                    <Route path="/pricing-api" element={<PricingPage api={true}/>} />
                                    <Route path="/filter" element={
                                            // <FilterProvider supabaseClient={supabase} >
                                            //     <FilterPage />
                                            // </FilterProvider>
                                            <FilterPage />
                                        } 
                                    />
                                    <Route path="/filter/:filterId" element={<FilterPage />} />
                                    <Route path='/sopr-heartbeat' element={<ProFeaturePage />} />
                                    {/* <Route path='/test-payment' element={<TestPaymentPage />} /> */}
                                    <Route path='/test-payment-noprod' element={<TestPaymentPageNoProd />} />
                                    <Route path='/account' element={<AccountPage />} />
                                    <Route path='/referral' element={<RefferalPage />} />
                                </Routes>
                                {posthog.has_opted_out_capturing() // new
                                ||posthog.has_opted_in_capturing() 
                                    ? null 
                                    : <CookieBanner /> 
                                }
                                {/* <CookieBanner />  */}
                            <Popup />
                        </TokensProvider>
                    </PopupProvider>
                </Auth.UserContextProvider>
            </SubscriptionProvider>
        </div>
    )
}